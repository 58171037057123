import { createSlice } from "@reduxjs/toolkit";
import { ICollecting, IKeyword, IStockKeyword } from "api/types/_keyword";
import { PaginationResponse, ResponseData } from "api/types/_public";
import { getCollecting, getKeywords, getKeywordsByCategory, getKeywordsMonitor, getRankKeywordSinger, getNewsSearchingKeyword, getRankKeywordSport, getStockKeywords, getRankKeywordCar, getRankKeywordGame, getKeywordTrends, getKeywordTrendNews } from "./thunk";

export interface IState {
  keywords: ResponseData<IKeyword[]> & PaginationResponse | null,
  isKeywordLoading: boolean,
  isKeywordSuccess: boolean,

  keywordsMonitor: ResponseData<IKeyword[]> & PaginationResponse | null,
  isKeywordMonitorLoading: boolean,
  isKeywordMonitorSuccess: boolean,

  rankKeywordSinger: ResponseData<IKeyword[]> & PaginationResponse | null,
  isRankKeywordSingerLoading: boolean,
  isRankKeywordSingerSuccess: boolean,

  rankKeywordSport: ResponseData<IKeyword[]> & PaginationResponse | null,
  isRankKeywordSportLoading: boolean,
  isRankKeywordSportSuccess: boolean,

  rankKeywordCar: ResponseData<IKeyword[]> & PaginationResponse | null,
  isRankKeywordCarLoading: boolean,
  isRankKeywordCarSuccess: boolean,

  rankKeywordGame: ResponseData<IKeyword[]> & PaginationResponse | null,
  isRankKeywordGameLoading: boolean,
  isRankKeywordGameSuccess: boolean,

  stockKeywords: ResponseData<IStockKeyword[]> & PaginationResponse | null,
  isStockKeywordLoading: boolean,
  isStockKeywordSuccess: boolean,

  collecting: ResponseData<ICollecting[]> & PaginationResponse | null,
  isCollectingLoading: boolean,
  isCollectingSuccess: boolean,

  searching: ResponseData<IKeyword[]> & PaginationResponse | null,
  isSearchingLoading: boolean,
  isSearchingSuccess: boolean,

  keywordTrends: ResponseData<IKeyword[]> & PaginationResponse | null,
  isKeywordTrendLoading: boolean,
  isKeywordTrendSuccess: boolean,

  keywordTrendNews: ResponseData<IKeyword[]> & PaginationResponse | null,
  isKeywordTrendNewsLoading: boolean,
  isKeywordTrendNewsSuccess: boolean,

  error: any,
};

export const initialState: IState = {
  keywords: null,
  isKeywordLoading: false,
  isKeywordSuccess: false,

  keywordsMonitor: null,
  isKeywordMonitorLoading: false,
  isKeywordMonitorSuccess: false,

  rankKeywordSinger: null,
  isRankKeywordSingerLoading: false,
  isRankKeywordSingerSuccess: false,

  rankKeywordSport: null,
  isRankKeywordSportLoading: false,
  isRankKeywordSportSuccess: false,

  rankKeywordCar: null,
  isRankKeywordCarLoading: false,
  isRankKeywordCarSuccess: false,

  rankKeywordGame: null,
  isRankKeywordGameLoading: false,
  isRankKeywordGameSuccess: false,

  stockKeywords: null,
  isStockKeywordLoading: false,
  isStockKeywordSuccess: false,

  collecting: null,
  isCollectingLoading: false,
  isCollectingSuccess: false,

  searching: null,
  isSearchingLoading: false,
  isSearchingSuccess: false,

  keywordTrends: null,
  isKeywordTrendLoading: false,
  isKeywordTrendSuccess: false,

  keywordTrendNews: null,
  isKeywordTrendNewsLoading: false,
  isKeywordTrendNewsSuccess: false,

  error: {},
};

const CategorySlice = createSlice({
  name: "Keyword",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //keywords
    builder.addCase(getKeywords.pending, (state: IState, action: any) => {
      state.isKeywordLoading = true
    });
    builder.addCase(getKeywords.fulfilled, (state: IState, action: any) => {
      state.keywords = action.payload.data;
      state.isKeywordSuccess = true;
      state.isKeywordLoading = false;
    });
    builder.addCase(getKeywords.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isKeywordSuccess = false;
      state.isKeywordLoading = false;
    });

    //keywords monitor
    builder.addCase(getKeywordsMonitor.pending, (state: IState, action: any) => {
      state.isKeywordMonitorLoading = true
    });
    builder.addCase(getKeywordsMonitor.fulfilled, (state: IState, action: any) => {
      state.keywordsMonitor = action.payload.data;
      state.isKeywordMonitorSuccess = true;
      state.isKeywordMonitorLoading = false;
    });
    builder.addCase(getKeywordsMonitor.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isKeywordMonitorSuccess = false;
      state.isKeywordMonitorLoading = false;
    });

    //keywords search news
    builder.addCase(getRankKeywordSinger.pending, (state: IState, action: any) => {
      state.isRankKeywordSingerLoading = true
    });
    builder.addCase(getRankKeywordSinger.fulfilled, (state: IState, action: any) => {
      state.rankKeywordSinger = action.payload.data;
      state.isRankKeywordSingerSuccess = true;
      state.isRankKeywordSingerLoading = false;
    });
    builder.addCase(getRankKeywordSinger.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isRankKeywordSingerSuccess = false;
      state.isRankKeywordSingerLoading = false;
    });


    //rank keywords sport
    builder.addCase(getRankKeywordSport.pending, (state: IState, action: any) => {
      state.isRankKeywordSportLoading = true
    });
    builder.addCase(getRankKeywordSport.fulfilled, (state: IState, action: any) => {
      state.rankKeywordSport = action.payload.data;
      state.isRankKeywordSportSuccess = true;
      state.isRankKeywordSportLoading = false;
    });
    builder.addCase(getRankKeywordSport.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isRankKeywordSportSuccess = false;
      state.isRankKeywordSportLoading = false;
    });


    //rank keywords car
    builder.addCase(getRankKeywordCar.pending, (state: IState, action: any) => {
      state.isRankKeywordCarLoading = true
    });
    builder.addCase(getRankKeywordCar.fulfilled, (state: IState, action: any) => {
      state.rankKeywordCar = action.payload.data;
      state.isRankKeywordCarSuccess = true;
      state.isRankKeywordCarLoading = false;
    });
    builder.addCase(getRankKeywordCar.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isRankKeywordCarSuccess = false;
      state.isRankKeywordCarLoading = false;
    });


    //rank keywords game
    builder.addCase(getRankKeywordGame.pending, (state: IState, action: any) => {
      state.isRankKeywordGameLoading = true
    });
    builder.addCase(getRankKeywordGame.fulfilled, (state: IState, action: any) => {
      state.rankKeywordGame = action.payload.data;
      state.isRankKeywordGameSuccess = true;
      state.isRankKeywordGameLoading = false;
    });
    builder.addCase(getRankKeywordGame.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isRankKeywordGameSuccess = false;
      state.isRankKeywordGameLoading = false;
    });

    //stock keywords
    builder.addCase(getStockKeywords.pending, (state: IState, action: any) => {
      state.isStockKeywordLoading = true
    });
    builder.addCase(getStockKeywords.fulfilled, (state: IState, action: any) => {
      state.stockKeywords = action.payload.data;
      state.isStockKeywordSuccess = true;
      state.isStockKeywordLoading = false;
    });
    builder.addCase(getStockKeywords.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isStockKeywordSuccess = false;
      state.isStockKeywordLoading = false;
    });

    //keywords by category
    builder.addCase(getKeywordsByCategory.pending, (state: IState, action: any) => {
      state.isKeywordLoading = true
    });
    builder.addCase(getKeywordsByCategory.fulfilled, (state: IState, action: any) => {
      state.keywords = action.payload.data;
      state.isKeywordSuccess = true;
      state.isKeywordLoading = false;
    });
    builder.addCase(getKeywordsByCategory.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isKeywordSuccess = false;
      state.isKeywordLoading = false;
    });

    //collecting
    builder.addCase(getCollecting.pending, (state: IState, action: any) => {
      state.isCollectingLoading = true
    });
    builder.addCase(getCollecting.fulfilled, (state: IState, action: any) => {
      state.collecting = action.payload.data;
      state.isCollectingSuccess = true;
      state.isCollectingLoading = false;
    });
    builder.addCase(getCollecting.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isCollectingSuccess = false;
      state.isCollectingLoading = false;
    });

    //searching
    builder.addCase(getNewsSearchingKeyword.pending, (state: IState, action: any) => {
      state.isSearchingLoading = true
    });
    builder.addCase(getNewsSearchingKeyword.fulfilled, (state: IState, action: any) => {
      state.searching = action.payload.data;
      state.isSearchingSuccess = true;
      state.isSearchingLoading = false;
    });
    builder.addCase(getNewsSearchingKeyword.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isSearchingSuccess = false;
      state.isSearchingLoading = false;
    });

    //keyword trends
    builder.addCase(getKeywordTrends.pending, (state: IState, action: any) => {
      state.isKeywordTrendLoading = true
    });
    builder.addCase(getKeywordTrends.fulfilled, (state: IState, action: any) => {
      state.keywordTrends = action.payload.data;
      state.isKeywordTrendSuccess = true;
      state.isKeywordTrendLoading = false;
    });
    builder.addCase(getKeywordTrends.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isKeywordTrendSuccess = false;
      state.isKeywordTrendLoading = false;
    });

    //keyword trends news
    builder.addCase(getKeywordTrendNews.pending, (state: IState, action: any) => {
      state.isKeywordTrendNewsLoading = true
    });
    builder.addCase(getKeywordTrendNews.fulfilled, (state: IState, action: any) => {
      state.keywordTrendNews = action.payload.data;
      state.isKeywordTrendNewsSuccess = true;
      state.isKeywordTrendNewsLoading = false;
    });
    builder.addCase(getKeywordTrendNews.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isKeywordTrendNewsSuccess = false;
      state.isKeywordTrendNewsLoading = false;
    });
  },
});

export default CategorySlice.reducer;