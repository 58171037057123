import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

//Import Icons
import { useRole } from "components/Hooks/UserHooks";
import config from "config";
import FeatherIcon from "feather-icons-react";
import { ROLES_FOR_APP, isHavePermissionRole } from "helpers/role";
import { useTranslation } from "react-i18next";
import { ROUTES } from "routes/allRoutes";

const Navdata = () => {
    const history = useNavigate();
    const { t } = useTranslation();
    const { userPermissions } = useRole();

    const [menuActive, setMenuActive] = useState<string>('DASHBOARD');

    // Multi Level
    const [isLevel1, setIsLevel1] = useState<boolean>(false);

    const [isCurrentState, setIsCurrentState] = useState('Dashboard');

    function updateIconSidebar(e: any) {
        if (e && e.target && e.target.getAttribute("sub-items")) {
            const ul: any = document.getElementById("two-column-menu");
            const iconItems = ul.querySelectorAll(".nav-icon.active");
            let activeIconItems = [...iconItems];
            activeIconItems.forEach((item) => {
                item.classList.remove("active");
                var id = item.getAttribute("sub-items");
                const getID = document.getElementById(id) as HTMLElement
                if (getID)
                    getID.classList.remove("show");
            });
        }
    }

    const redirectMessageHub = () => {
        const { message_hub_sso_redirect_url } = config
        const accessToken: any = localStorage.getItem("access_token");

        if (message_hub_sso_redirect_url && accessToken) {
            return `${message_hub_sso_redirect_url}?sso_token_newshub=${accessToken}`
        } else {
            return '/#'
        }
    }

    useEffect(() => {
        document.body.classList.remove('twocolumn-panel');
    }, [history]);

    const menuItems: any = [
        {
            id: "dashboard",
            label: t('Dashboards'),
            icon: <FeatherIcon icon="home" className="icon-dual" />,
            link: ROUTES.DASHBOARD,
            click: function (e: any) {
                setMenuActive((prev) => 'DASHBOARD');
                setIsCurrentState((prev) => 'Dashboard');
                updateIconSidebar(e);
            },
            stateVariables: (menuActive === 'DASHBOARD'),
            isShow: isHavePermissionRole(ROLES_FOR_APP.DASHBOARD, userPermissions),
            // subItems: []
        },
        {
            id: "multilevel12",
            label: t("Category Management"),
            icon: <FeatherIcon icon="grid" className="icon-dual" />,
            link: ROUTES.CATEGORY_MANAGEMENT,
            click: function (e: any) {
                e.preventDefault();
                setMenuActive((prev) => "CATEGORY_MANAGEMENT");
                setIsCurrentState((prev) => "Category Management");
                updateIconSidebar(e);
            },
            stateVariables: menuActive === "CATEGORY_MANAGEMENT",
            isShow: isHavePermissionRole([ROLES_FOR_APP.CATEGORY_MANAGEMENT, ROLES_FOR_APP.STOCK_CATEGORY_MANAGEMENT], userPermissions),
            subItems: [
                {
                    id: "trend-index-category-management",
                    label: t('Trend Index Category Management'),
                    link: ROUTES.TREND_INDEX_CATEGORY_MANAGEMENT,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.CATEGORY_MANAGEMENT, userPermissions),
                },
                {
                    id: "stock-category-management",
                    label: t('Stock Category Management'),
                    link: ROUTES.STOCK_CATEGORY_MANAGEMENT,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.STOCK_CATEGORY_MANAGEMENT, userPermissions),
                },
            ],
        },
        {
            id: "multilevel12",
            label: t("Keyword Management"),
            icon: <i className="ri-a-b" />,
            link: ROUTES.KEYWORD_MANAGEMENT,
            click: function (e: any) {
                e.preventDefault();
                setMenuActive((prev) => "KEYWORD_MANAGEMENT");
                setIsCurrentState((prev) => "Keyword Management");
                updateIconSidebar(e);
            },
            stateVariables: menuActive === "KEYWORD_MANAGEMENT",
            isShow: isHavePermissionRole([ROLES_FOR_APP.KEYWORD_MANAGEMENT, ROLES_FOR_APP.TREND_INDEX_KEYWORD_MANAGEMENT, ROLES_FOR_APP.STOCK_KEYWORD_MANAGEMENT], userPermissions),
            subItems: [
                {
                    id: "trend-index-keyword-management",
                    label: t('Trend Index Keyword Management'),
                    link: ROUTES.TREND_INDEX_KEYWORD_MANAGEMENT,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.TREND_INDEX_KEYWORD_MANAGEMENT, userPermissions),
                },
                {
                    id: "stock-keyword-management",
                    label: t('Stock Keyword Management'),
                    link: ROUTES.STOCK_KEYWORD_MANAGEMENT,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.STOCK_KEYWORD_MANAGEMENT, userPermissions),
                },
            ],
        },
        {
            id: "multilevel5",
            label: t("Monitoring"),
            icon: <i className="ri-bar-chart-horizontal-line" />,
            link: ROLES_FOR_APP.RANKING_MONITORING,
            click: function (e: any) {
                e.preventDefault();
                setMenuActive((prev) => "MONITORING");
                setIsCurrentState((prev) => "MONITORING");
                updateIconSidebar(e);
            },
            stateVariables: menuActive === "MONITORING",
            isShow: isHavePermissionRole(
                [
                    ROLES_FOR_APP.RANKING_MONITORING,
                    ROLES_FOR_APP.COLLECTING_MONITORING
                ],
                userPermissions
            ),
            subItems: [
                {
                    id: "ranking-monitoring",
                    label: t('Ranking Monitoring'),
                    link: ROUTES.RANKING_MONITORING,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.RANKING_MONITORING, userPermissions),
                },
                {
                    id: "collecting-monitoring",
                    label: t('Collecting Monitoring'),
                    link: ROUTES.COLLECTING_MONITORING,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.COLLECTING_MONITORING, userPermissions),
                },
            ],
        },
        {
            id: "multilevel5",
            label: t("Chart Ranking Management"),
            icon: <FeatherIcon icon="bar-chart" className="icon-dual" />,
            link: ROLES_FOR_APP.CHART_RANKING_SINGER,
            click: function (e: any) {
                e.preventDefault();
                setMenuActive((prev) => "CHART_RANKING");
                setIsCurrentState((prev) => "CHART_RANKING");
                updateIconSidebar(e);
            },
            stateVariables: menuActive === "CHART_RANKING",
            isShow: isHavePermissionRole(
                [
                    ROLES_FOR_APP.CHART_RANKING_SINGER,
                    ROLES_FOR_APP.CHART_RANKING_SPORT,
                    ROLES_FOR_APP.CHART_RANKING_CAR,
                    ROLES_FOR_APP.CHART_RANKING_GAME
                ],
                userPermissions
            ),
            subItems: [
                {
                    id: "chart-ranking-singer",
                    label: t('Chart Ranking Singer'),
                    link: ROUTES.CHART_RANKING_SINGER,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.CHART_RANKING_SINGER, userPermissions),
                },
                {
                    id: "chart-ranking-sport",
                    label: t('Chart Ranking Sport'),
                    link: ROUTES.CHART_RANKING_SPORT,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.CHART_RANKING_SPORT, userPermissions),
                },
                {
                    id: "chart-ranking-car",
                    label: t('Chart Ranking Car'),
                    link: ROUTES.CHART_RANKING_CAR,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.CHART_RANKING_CAR, userPermissions),
                },
                {
                    id: "chart-ranking-game",
                    label: t('Chart Ranking Game'),
                    link: ROUTES.CHART_RANKING_GAME,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.CHART_RANKING_GAME, userPermissions),
                },
            ],
        },
        {
            id: "multilevel12",
            label: t("Trend Index Management"),
            icon: <FeatherIcon icon="award" className="icon-dual" />,
            link: ROUTES.TREND_INDEX_MANAGEMENT,
            click: function (e: any) {
                e.preventDefault();
                setMenuActive((prev) => "TREND_INDEX_MANAGEMENT");
                setIsCurrentState((prev) => "Trend Index Management");
                updateIconSidebar(e);
            },
            stateVariables: menuActive === "TREND_INDEX_MANAGEMENT",
            isShow: isHavePermissionRole([
                ROLES_FOR_APP.SEARCH_INDEX_MANAGEMENT,
                ROLES_FOR_APP.VIRAL_INDEX_MANAGEMENT,
                ROLES_FOR_APP.NEWS_INDEX_MANAGEMENT,
                ROLES_FOR_APP.STOCK_INDEX_MANAGEMENT,
                ROLES_FOR_APP.PARTICIPATION_INDEX_MANAGEMENT
            ], userPermissions),
            subItems: [
                // {
                //     id: "trend-index-management",
                //     label: t('Trend Index Settings'),
                //     link: ROUTES.TREND_INDEX_SETTINGS_MANAGEMENT,
                //     isShow: isHavePermissionRole(ROLES_FOR_APP.TREND_INDEX_MANAGEMENT, userPermissions),
                // },
                {
                    id: "search-index-management",
                    label: t('Search Index Management'),
                    link: ROUTES.SEARCH_INDEX_MANAGEMENT,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.SEARCH_INDEX_MANAGEMENT, userPermissions),
                },
                // {
                //     id: "social-index-management",
                //     label: t('Social Index Management'),
                //     link: ROUTES.SOCIAL_INDEX_MANAGEMENT,
                //     isShow: isHavePermissionRole(ROLES_FOR_APP.SOCIAL_INDEX_MANAGEMENT, userPermissions),
                // },
                {
                    id: "viral-index-management",
                    label: t('Viral Index Management'),
                    link: ROUTES.VIRAL_INDEX_MANAGEMENT,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.VIRAL_INDEX_MANAGEMENT, userPermissions),
                },
                {
                    id: "news-index-management",
                    label: t('News Index Management'),
                    link: ROUTES.NEWS_INDEX_MANAGEMENT,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.NEWS_INDEX_MANAGEMENT, userPermissions),
                },
                {
                    id: "stock-index-management",
                    label: t('Stock Index Management'),
                    link: ROUTES.STOCK_INDEX_MANAGEMENT,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.STOCK_INDEX_MANAGEMENT, userPermissions),
                },
                {
                    id: "participation-index-management",
                    label: t('Participation Index Management'),
                    link: ROUTES.PARTICIPATION_INDEX_MANAGEMENT,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.PARTICIPATION_INDEX_MANAGEMENT, userPermissions),
                },
            ],
        },
        {
            id: "multilevel12",
            label: t("Participation Management"),
            icon: <FeatherIcon icon="inbox" className="icon-dual" />,
            link: ROUTES.PARTICIPATION_MANAGEMENT,
            click: function (e: any) {
                e.preventDefault();
                setMenuActive((prev) => "PARTICIPATION_MANAGEMENT");
                setIsCurrentState((prev) => "Participation Management");
                updateIconSidebar(e);
            },
            stateVariables: menuActive === "PARTICIPATION_MANAGEMENT",
            isShow: isHavePermissionRole([
                ROLES_FOR_APP.PARTICIPATION_TEMPLATE_MANAGEMENT,
                ROLES_FOR_APP.PARTICIPATION_CATEGORY_MANAGEMENT,
                ROLES_FOR_APP.PARTICIPATION_TICKET_MANAGEMENT
            ], userPermissions),
            subItems: [
                {
                    id: "participation-templates",
                    label: t('Management Participation Templates'),
                    link: ROUTES.PARTICIPATION_TEMPLATE_MANAGEMENT,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.PARTICIPATION_TEMPLATE_MANAGEMENT, userPermissions),
                },
                {
                    id: "participation-category",
                    label: t('Management Participation Categories'),
                    link: ROUTES.PARTICIPATION_CATEGORY_MANAGEMENT,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.PARTICIPATION_CATEGORY_MANAGEMENT, userPermissions),
                },
                {
                    id: "participation-ticket",
                    label: t('Management Participation Tickets'),
                    link: ROUTES.PARTICIPATION_TICKET_MANAGEMENT,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.PARTICIPATION_TICKET_MANAGEMENT, userPermissions),
                },
            ],
        },
        {
            id: "multilevel5",
            label: t("Official Media Management"),
            icon: <i className="ri-bubble-chart-line" />,
            link: ROLES_FOR_APP.OFFICIAL_MEDIA_MANAGEMENT,
            click: function (e: any) {
                e.preventDefault();
                setMenuActive((prev) => "OFFICIAL_MEDIA_MANAGEMENT");
                setIsCurrentState((prev) => "OFFICIAL_MEDIA_MANAGEMENT");
                updateIconSidebar(e);
            },
            stateVariables: menuActive === "OFFICIAL_MEDIA_MANAGEMENT",
            isShow: isHavePermissionRole(
                [
                    ROLES_FOR_APP.OFFICIAL_MEDIA_MANAGEMENT,
                    ROLES_FOR_APP.OFFICIAL_MEDIA_RANKING,
                    ROLES_FOR_APP.CATEGORY_QUALIFICATION_SETTINGS
                ],
                userPermissions
            ),
            subItems: [
                {
                    id: "official-media",
                    label: t('Manage Official Media'),
                    link: ROUTES.OFFICIAL_MEDIA_MANAGEMENT,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.OFFICIAL_MEDIA_MANAGEMENT, userPermissions),
                },
                {
                    id: "official-media-ranking",
                    label: t('Official Media Ranking'),
                    link: ROUTES.OFFICIAL_MEDIA_RANKING,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.OFFICIAL_MEDIA_RANKING, userPermissions),
                },
                {
                    id: "category-qualification-settings",
                    label: t('Category Qualification Settings'),
                    link: ROUTES.CATEGORY_QUALIFICATION_SETTINGS,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.CATEGORY_QUALIFICATION_SETTINGS, userPermissions),
                },
            ],
        },
        {
            id: "multilevel12",
            label: t("Statistics"),
            icon: <FeatherIcon icon="bar-chart-2" className="icon-dual" />,
            link: ROUTES.STATISTICS,
            click: function (e: any) {
                e.preventDefault();
                setMenuActive((prev) => "STATISTICS");
                setIsCurrentState((prev) => "Statistics");
                updateIconSidebar(e);
            },
            stateVariables: menuActive === "STATISTICS",
            isShow: isHavePermissionRole(
                [
                    ROLES_FOR_APP.VOTE_STATISTICS
                ],
                userPermissions
            ),
            subItems: [
                {
                    id: "vote-statistics",
                    label: t("Vote Statistics"),
                    link: ROUTES.VOTE_STATISTICS,
                    isShow: isHavePermissionRole(
                        ROLES_FOR_APP.VOTE_STATISTICS,
                        userPermissions
                    ),
                },
            ],
        },
        {
            id: "multilevel12",
            label: t("Payment Management"),
            icon: <i className="ri-wallet-2-line" />,
            link: ROUTES.PAYMENT_MANAGEMENT,
            click: function (e: any) {
                e.preventDefault();
                setMenuActive((prev) => "PAYMENT_MANAGEMENT");
                setIsCurrentState((prev) => "Payment Management");
                updateIconSidebar(e);
            },
            stateVariables: menuActive === "PAYMENT_MANAGEMENT",
            isShow: isHavePermissionRole([
                ROLES_FOR_APP.PAYMENT_HISTORY,
                ROLES_FOR_APP.SUBSCRIPTION_MANAGEMENT
            ], userPermissions),
            subItems: [
                {
                    id: "payment-history",
                    label: t('Payment History'),
                    link: ROUTES.PAYMENT_HISTORY,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.PAYMENT_HISTORY, userPermissions),
                },
                {
                    id: "subscription-management",
                    label: t('Subscription Management'),
                    link: ROUTES.SUBSCRIPTION_MANAGEMENT,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.SUBSCRIPTION_MANAGEMENT, userPermissions),
                },
            ],
        },
        {
            id: "multilevel12",
            label: t("Regular Member Management"),
            icon: <i className="ri-user-heart-line" />,
            link: ROUTES.REGULAR_MEMBER_MANAGEMENT,
            click: function (e: any) {
                setMenuActive((prev) => "REGULAR_MEMBER_MANAGEMENT");
                setIsCurrentState((prev) => "Regular Member Management");
                updateIconSidebar(e);
            },
            stateVariables: menuActive === "REGULAR_MEMBER_MANAGEMENT",
            isShow: isHavePermissionRole([ROLES_FOR_APP.REGULAR_MEMBER_MANAGEMENT], userPermissions),
        },
        {
            id: "multilevel12",
            label: t("Business Member Management"),
            icon: <i className="ri-user-star-line" />,
            link: ROUTES.BUSINESS_MEMBER_MANAGEMENT,
            click: function (e: any) {
                setMenuActive((prev) => "BUSINESS_MEMBER_MANAGEMENT");
                setIsCurrentState((prev) => "Business Member Management");
                updateIconSidebar(e);
            },
            stateVariables: menuActive === "BUSINESS_MEMBER_MANAGEMENT",
            isShow: isHavePermissionRole([ROLES_FOR_APP.BUSINESS_MEMBER_MANAGEMENT], userPermissions),
        },
        // {
        //     id: "multilevel12",
        //     label: t("Sub-account Management"),
        //     icon: <i className="ri-team-line" />,
        //     link: ROUTES.SUB_ACCOUNT_MANAGEMENT,
        //     click: function (e: any) {
        //         setMenuActive((prev) => "SUB_ACCOUNT_MANAGEMENT");
        //         setIsCurrentState((prev) => "Sub-account Management");
        //         updateIconSidebar(e);
        //     },
        //     stateVariables: menuActive === "SUB_ACCOUNT_MANAGEMENT",
        //     isShow: isHavePermissionRole([ROLES_FOR_APP.SUB_ACCOUNT_MANAGEMENT], userPermissions),
        // },
        {
            id: "multilevel12",
            label: t("Media Management"),
            icon: <i className="ri-building-2-line" />,
            link: ROUTES.MEDIA_MANAGEMENT,
            click: function (e: any) {
                setMenuActive((prev) => "MEDIA_MANAGEMENT");
                setIsCurrentState((prev) => "Media Management");
                updateIconSidebar(e);
            },
            stateVariables: menuActive === "MEDIA_MANAGEMENT",
            isShow: isHavePermissionRole([ROLES_FOR_APP.MEDIA_MANAGEMENT], userPermissions),
        },
        {
            id: "multilevel6",
            label: t("Operator Management"),
            icon: <FeatherIcon icon="users" className="icon-dual" />,
            link: "/#",
            click: function (e: any) {
                e.preventDefault();
                setMenuActive((prev) => "USER");
                setIsCurrentState((prev) => "User");
                updateIconSidebar(e);
            },
            stateVariables: menuActive === "USER",
            isShow: isHavePermissionRole([
                ROLES_FOR_APP.OPERATOR_LIST,
                ROLES_FOR_APP.ROLE_LIST,
                ROLES_FOR_APP.LOG_LIST
            ], userPermissions),
            subItems: [
                {
                    id: "operator",
                    label: t("Operator"),
                    link: ROUTES.OPERATOR_LIST,
                    isShow: isHavePermissionRole(
                        ROLES_FOR_APP.OPERATOR_LIST,
                        userPermissions
                    ),
                },
                {
                    id: "role-list",
                    label: t("Role"),
                    link: ROUTES.USER_ROLE,
                    isShow: isHavePermissionRole(
                        ROLES_FOR_APP.ROLE_LIST,
                        userPermissions
                    ),
                },
                {
                    id: "activity-logs",
                    label: t('Activity Logs'),
                    link: ROUTES.LOG_LIST,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.LOG_LIST, userPermissions),
                },
            ],
        },

        // {
        //     id: "multilevel5",
        //     label: t("Statistics"),
        //     icon: <FeatherIcon icon="bar-chart-2" className="icon-dual" />,
        //     link: ROLES_FOR_APP.INDEX_LOG,
        //     click: function (e: any) {
        //         e.preventDefault();
        //         setMenuActive((prev) => "STATISTICS");
        //         setIsCurrentState((prev) => "Statistics");
        //         updateIconSidebar(e);
        //     },
        //     stateVariables: menuActive === "STATISTICS",
        //     isShow: isHavePermissionRole(
        //         [
        //             ROLES_FOR_APP.INDEX_LOG,
        //         ],
        //         userPermissions
        //     ),
        //     subItems: [

        //     ],
        // },
        {
            id: "multilevel12",
            label: t("Reports & Analysis"),
            icon: <FeatherIcon icon="pie-chart" className="icon-dual" />,
            link: ROUTES.REPORT_ANALYSIS,
            click: function (e: any) {
                setMenuActive((prev) => "REPORT_ANALYSIS");
                setIsCurrentState((prev) => "Reports & Analysis");
                updateIconSidebar(e);
            },
            stateVariables: menuActive === "REPORT_ANALYSIS",
            isShow: isHavePermissionRole([ROLES_FOR_APP.REPORT_ANALYSIS], userPermissions),
        },
        {
            id: "multilevel12",
            label: t("Logs"),
            icon: <i className="ri-time-line" />,
            link: ROUTES.LOG,
            click: function (e: any) {
                e.preventDefault();
                setMenuActive((prev) => "LOG");
                setIsCurrentState((prev) => "Log");
                updateIconSidebar(e);
            },
            stateVariables: menuActive === "LOG",
            isShow: isHavePermissionRole([ROLES_FOR_APP.INDEX_LOG], userPermissions),
            subItems: [
                {
                    id: "index-log",
                    label: t('Index Log'),
                    link: ROUTES.INDEX_LOG,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.INDEX_LOG, userPermissions),
                },

            ],
        },
        {
            id: "multilevel12",
            label: t("System Settings"),
            icon: <FeatherIcon icon="settings" className="icon-dual" />,
            link: ROUTES.SYSTEM_SETTINGS,
            click: function (e: any) {
                e.preventDefault();
                setMenuActive((prev) => "SYSTEM_SETTINGS");
                setIsCurrentState((prev) => "System Settings");
                updateIconSidebar(e);
            },
            stateVariables: menuActive === "SYSTEM_SETTINGS",
            isShow: isHavePermissionRole([ROLES_FOR_APP.INDEX_SETTING, ROLES_FOR_APP.GENERAL_SETTING], userPermissions),
            subItems: [
                {
                    id: "config-list",
                    label: t('General Setting'),
                    link: ROUTES.GENERAL_SETTING,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.GENERAL_SETTING, userPermissions),
                },
                {
                    id: "config-list",
                    label: t('Index Setting'),
                    link: ROUTES.INDEX_SETTING,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.INDEX_SETTING, userPermissions),
                },
            ],
        },
        {
            id: "multilevel9",
            label: t('Lab'),
            icon: <i className="ri ri-flask-line fs-20" style={{ transform: 'translateX(-1px)' }} />,
            link: "/#",
            click: function (e: any) {
                e.preventDefault();
                setMenuActive((prev) => 'LAB');
                setIsCurrentState((prev) => "Lab");
                updateIconSidebar(e);
            },
            stateVariables: (menuActive === 'LAB'),
            isShow: isHavePermissionRole([
                ROLES_FOR_APP.KEYWORD_TREND,
            ], userPermissions),
            subItems: [
                {
                    id: "keyword-trend",
                    label: t('Keyword Trend'),
                    link: ROUTES.KEYWORD_TREND,
                    isShow: isHavePermissionRole(ROLES_FOR_APP.KEYWORD_TREND, userPermissions),
                },
            ],
        },
        // {
        //     id: "logout",
        //     label: "Logout",
        //     icon: <FeatherIcon icon="log-out" className="icon-log-out" />,
        //     link: "/logout",
        //     stateVariables: isDashboard,
        //     click: function (e: any) {
        //         e.preventDefault();
        //         setIsDashboard(!isDashboard);
        //         setIsCurrentState( (prev) =>  'Dashboard');
        //         updateIconSidebar(e);
        //     },
        // },
    ];
    return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
