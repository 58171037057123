import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient";
import { IDataSource, IFrequency, IIndex, IMenu } from "./types/_master";
import { ICategory } from "./types/_category";
import { IParticipationTemplate } from "./types/_participation";
const api = new APIClient();

const path = '/masters';
const masterApi = {
  indexes(params: any):Promise<AxiosResponse<IIndex[] , any>> {
    const url = `${path}/index-types`
    return api.get(url, params)
  },
  frequencies(params: any):Promise<AxiosResponse<IFrequency[] , any>> {
    const url = `${path}/frequency-types`
    return api.get(url, params)
  },
  allCategories(params: any):Promise<AxiosResponse<ICategory[] , any>> {
    const url = `${path}/categories`
    return api.get(url, params)
  },
  allRankCategories(params: any):Promise<AxiosResponse<ICategory[] , any>> {
    const url = `${path}/rank-categories`
    return api.get(url, params)
  },
  allDataSources(params: any):Promise<AxiosResponse<IDataSource[] , any>> {
    const url = `${path}/data-sources`
    return api.get(url, params)
  },
  allCategoriesSimple(params: any):Promise<AxiosResponse<ICategory[] , any>> {
    const url = `${path}/categories/simple`
    return api.get(url, params)
  },
  allMenus(params: any):Promise<AxiosResponse<IMenu[] , any>> {
    const url = `${path}/menus`
    return api.get(url, params)
  },
  allParticipationTemplates(params: any):Promise<AxiosResponse<IParticipationTemplate[] , any>> {
    const url = `${path}/survey-forms`
    return api.get(url, params)
  },
}
export default masterApi
