import LabelWrapper from "components/Common/LabelWrapper";
import TableContainer from "components/Common/TableContainer";
import { useRole } from "components/Hooks/UserHooks";
import { formatNumberWithCommas } from "helpers/format";
import { isHavePermissionRole, ROLES_FOR_APP } from "helpers/role";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CountUp from "react-countup";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Card, CardBody, CardHeader, Col, Container, Input, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import { getKeywordTrends as onGetKeywordTrends } from "../../../store/thunks";
import Gemma from "../Gemma";
import ModalNewsOfKeywordTrend from "../ModalNewsOfKeywordTrend";

const SORT_BY_DEFAULT = 'search_volume';
const ORDER_BY_DEFAULT = 'DESC';

const KeywordTrend = () => {
    const { t, i18n } = useTranslation();
    const { userPermissions } = useRole();
    const navigate = useNavigate();

    const [query, setQuery]: any = useQueryParams({
        time_request: withDefault(NumberParam, 0),
        page: withDefault(NumberParam, 1),
        limit: withDefault(NumberParam, 50),
        sort_by: withDefault(StringParam, SORT_BY_DEFAULT),
        order_by: withDefault(StringParam, ORDER_BY_DEFAULT),
        keyword: withDefault(StringParam, ''),
    });

    const dispatch: any = useDispatch();

    const selectLayoutState = (state: any) => state.Keyword;

    const KeywordProperties = createSelector(
        selectLayoutState,
        (state) => ({
            keywordTrends: state.keywordTrends,
            isKeywordTrendSuccess: state.isKeywordTrendSuccess,
            isKeywordTrendLoading: state.isKeywordTrendLoading,
            error: state.error,
        })
    );

    const { keywordTrends, isKeywordTrendLoading } = useSelector(KeywordProperties);

    const [keywordSearch, setKeywordSearch] = useState<string>(query?.keyword || "");

    const [keywordTrend, setKeywordTrend] = useState<{ keyword_id: number, } | null>(null);

    const [isOpenGemma, setIsOpenGemma] = useState<boolean>(false);

    useEffect(() => {
        dispatch(onGetKeywordTrends(query));
    }, [JSON.stringify(query)]);

    const searchData = () => {
        setQuery({
            ...query,
            page: 1,
            keyword: keywordSearch || '',
            time_request: + new Date()
        });
    };

    const resetData = () => {
        setQuery({
            keyword: '',
            sort_by: SORT_BY_DEFAULT,
            order_by: ORDER_BY_DEFAULT,
            time_request: + new Date(),
            page: 1,
        }, "push");
        setKeywordSearch("");
    };


    const onHideKeywordTrend = () => {
        setKeywordTrend((_prev) => null);
    };

    // Begin:: Chat Gemma
    function onCloseGemmaClick() {
        setIsOpenGemma((_prev) => false);
    }

    // End::Chat Gemma

    // Column
    const columns = useMemo(
        () => [
            {
                Header: t('ID'),
                accessor: "id",
                filterable: true,
                sortable: true,
                thWidth: 60,
                Cell: (cell: any) => (
                    <>
                        <div style={{ minWidth: '40px' }}>{cell?.value}</div>
                    </>
                ),
            },
            // {
            //     Header: t(''),
            //     accessor: "__",
            //     filterable: false,
            //     sortable: false,
            //     thClass: 'text-center',
            //     Cell: (cell: any) => {
            //         const item = cell?.row?.original;
            //         return (
            //             <>

            //             </>
            //         )
            //     },
            // },
            // {
            //     Header: t('Date'),
            //     accessor: "date",
            //     filterable: false,
            //     sortable: false,
            //     thWidth: 120,
            //     thClass: "align-middle",
            //     Cell: (cell: any) => (
            //         <div className="text-start" style={{ minWidth: '80px' }}>{!!cell?.value ? String(cell?.value)?.split(' ')?.[0] : '-'}</div>
            //     ),
            // },
            {
                Header: t(''),
                accessor: "___",
                filterable: false,
                sortable: false,
                thClass: 'text-center',
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>

                        </>
                    )
                },
            },
            {
                Header: t('Keyword'),
                accessor: "keyword",
                filterable: false,
                sortable: false,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="cursor-pointer d-flex align-items-center text-decoration-underline" style={{ minWidth: '130px' }}
                                onClick={() => setKeywordTrend((_prev) => ({ keyword_id: item?.id }))}
                            >
                                {cell?.value}
                            </div>
                        </>
                    )
                },
            },
            {
                Header: t('Search Volume'),
                accessor: "search_volume",
                filterable: false,
                sortable: true,
                thClass: 'text-center',
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-center" style={{ minWidth: '100px' }}>
                                {formatNumberWithCommas(cell?.value)}
                            </div>
                        </>
                    )
                },
            },
            {
                Header: t(''),
                accessor: "_",
                filterable: false,
                sortable: false,
                thClass: 'text-center',
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>

                        </>
                    )
                },
            },
            {
                Header: t('Created at'),
                accessor: "created_at",
                filterable: true,
                sortable: false,
                thWidth: 150,
                thClass: 'text-end',
                Cell: (cell: any) => {
                    const arrDate = String(cell?.value || '').split(' ');
                    return (
                        <div className="text-end" style={{ minWidth: '120px' }}>
                            <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
                        </div>
                    )
                },
            },
            {
                Header: t('Updated at'),
                accessor: "updated_at",
                filterable: true,
                sortable: false,
                thWidth: 150,
                thClass: 'text-end',
                Cell: (cell: any) => {
                    const arrDate = String(cell?.value || '').split(' ');
                    return (
                        <div className="text-end" style={{ minWidth: '120px' }}>
                            <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
                        </div>
                    )
                },
            },
        ],
        [i18n?.language, userPermissions, JSON.stringify(query)]
    );

    const handleChangePage = useCallback((page: any) => {
        setQuery({ page: page + 1 })
    }, []);

    const handleChangeSorting = useCallback((sortBy: any) => {
        setQuery((_prev: any) => {
            return ({ ..._prev, ...sortBy });
        });
    }, []);

    useEffect(() => {
        document.title = `${t("Keyword Trend")} - ${t("Lab")} | Rankify`;
        document.body.classList.remove("vertical-sidebar-enable");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n?.language]);


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={t('Keyword Trend')} pageTitle={t('Lab')} />
                    {isHavePermissionRole(ROLES_FOR_APP.KEYWORD_TREND, userPermissions) && (
                        <Row>
                            <Col sm={12} md={12}>
                                <Card id="customerList" style={{ boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)' }}>
                                    <CardHeader className="border-0">
                                        <Row className="g-4 align-items-center">
                                            <div className="col-6 col-sm">
                                                <div>
                                                    <h5 className="card-title mb-0">
                                                        <span className="me-2">{t('Total')}:</span>
                                                        <CountUp
                                                            start={0}
                                                            end={keywordTrends?.pagination?.total || 0}
                                                            duration={1}
                                                            className="text-primary"
                                                        />
                                                    </h5>
                                                </div>
                                            </div>
                                            <div className="col-6 col-sm text-end">
                                                <button
                                                    type="button"
                                                    className="btn btn-primary rounded-pill fs-14 ms-4"
                                                    onClick={() => setIsOpenGemma((prev) => true)}
                                                >
                                                    <i className="ri-question-answer-line align-bottom"></i>
                                                    <span className="fs-13 ms-1 fw-400">{t("Chat Gemma")}</span>
                                                </button>
                                            </div>
                                        </Row>
                                    </CardHeader>
                                    <div className=" border border-dashed border-end-0 border-start-0 card-body mt-0 pb-4">
                                        <Row className="g-4 align-items-center mt-0">
                                            <Col sm={6} md={4} xl={3} xxl={3} className='mt-3 mt-md-2'>
                                                <LabelWrapper label={t('Keyword')} isShow={!!keywordSearch}>
                                                    <Input
                                                        type="text"
                                                        className="form-control search"
                                                        placeholder={`${t('Enter Keyword to Search')}...`}
                                                        value={keywordSearch}
                                                        onChange={(e) => setKeywordSearch(e.target.value)}
                                                        onKeyDown={(e) => {
                                                            if (e.key === "Enter") {
                                                                searchData();
                                                            }
                                                        }}
                                                    />
                                                </LabelWrapper>
                                            </Col>
                                            <Col sm={6} md={8} xl={9} xxl={9} className="hstack gap-1 justify-content-center justify-content-sm-start mt-3 mt-md-2">
                                                <div>
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary me-2 "
                                                        onClick={searchData}
                                                        disabled={isKeywordTrendLoading}
                                                    >
                                                        <i className="ri-search-line align-bottom me-1"></i>{" "}
                                                        {t('Button Search')}
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-secondary fs-14"
                                                        onClick={resetData}
                                                    >
                                                        <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                                                        {t('Button Reset')}
                                                    </button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <CardBody className="pt-3">
                                        <TableContainer
                                            className="custom-header-css"
                                            divClass="table-responsive table-card"
                                            tableClass="align-middle table-bordered-dashed"
                                            theadClass="table-light text-muted"
                                            columns={columns}
                                            data={keywordTrends?.list?.length ? keywordTrends?.list : []}
                                            customPageSize={query.limit}
                                            customPageIndex={query.page - 1}
                                            totalRecords={keywordTrends?.pagination?.total}
                                            customPageCount={Math.ceil(Number(keywordTrends?.pagination?.total) / Number(keywordTrends?.pagination?.limit))}
                                            handleChangePage={handleChangePage}
                                            manualSorting={true}
                                            sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                                            handleChangeSorting={handleChangeSorting}
                                            isLoading={isKeywordTrendLoading}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    )}
                    <ToastContainer closeButton={false} limit={1} />
                </Container>

                <Modal
                    isOpen={!!keywordTrend}
                    centered={true}
                    toggle={onHideKeywordTrend}
                    size="xl"
                    className="modal-fullscreen"
                    scrollable={true}
                    keyboard={true}
                >
                    <ModalHeader toggle={onHideKeywordTrend}>
                        {t('News Searching Keyword')}
                    </ModalHeader>
                    <ModalBody className="pt-3" style={{ minHeight: "450px" }}>
                        <ModalNewsOfKeywordTrend keywordTrend={keywordTrend} />
                    </ModalBody>
                </Modal>

                <Modal
                    isOpen={!!isOpenGemma}
                    centered={true}
                    size="xl"
                    scrollable={false}
                >
                    <ModalHeader toggle={onCloseGemmaClick}>
                        {t("Chat Gemma")}
                    </ModalHeader>
                    <ModalBody className="mx-2">
                        <div style={{ maxHeight: "calc(100dvh - 200px)" }}>
                            <Gemma
                                title={""}
                                style={{ height: "calc(100dvh - 200px)" }}
                            />
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        </React.Fragment>
    );
};

export default KeywordTrend;