import React from "react";
import { useTranslation } from "react-i18next";
import NoImage from '../../../../assets/images/no-photo-available.png';
import InfoExtract from "./InfoExtract";
import LoadingSkeleton from "./LoadingSkeleton";

const BasketBallPlayer = ({ keyword_info = {}, triggerRefresh }: any) => {
    const { t, i18n } = useTranslation();

    return (
        <>
            <div className="d-flex flex-w flex-wrap mb-3">
                <div className="d-flex flex-column gap-3 w-100">
                    <>
                        <div style={{ width: '100%', height: 'auto', maxHeight: '300px' }}>
                            <img alt={keyword_info?.name || ''} loading="lazy" src={keyword_info?.image_url || keyword_info?.image || NoImage}
                                style={{
                                    width: '100%',
                                    height: '300px',
                                    objectFit: 'contain',
                                    objectPosition: 'center',
                                    // backgroundColor: '#fff'
                                }}
                            />
                        </div>
                        <div className="w-100">
                            <div>
                                <h3 className="mb-1">
                                    {!keyword_info?.name ? <LoadingSkeleton width="150px" /> : keyword_info?.name || ''}
                                    <span className="fs-16 ms-2">No.{keyword_info?.no || ''}</span>
                                </h3>
                            </div>
                            <table className="table table-bordered mb-0">
                                <tbody>
                                    <tr>
                                        <td className="text-start align-middle py-2 bg-secondary-100" width="152px">{t('Team')}</td>
                                        <td className="text-start align-middle py-2">{!keyword_info?.name ? <LoadingSkeleton /> : keyword_info?.team || '-'}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-start align-middle py-2 bg-secondary-100" width="152px">{t('Birthday')}</td>
                                        <td className="text-start align-middle py-2">{!keyword_info?.name ? <LoadingSkeleton /> : keyword_info?.birthday || '-'}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-start align-middle py-2 bg-secondary-100" width="152px">{t('Position')}</td>
                                        <td className="text-start align-middle py-2">{!keyword_info?.name ? <LoadingSkeleton /> : keyword_info?.position || '-'}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-start align-middle py-2 bg-secondary-100" width="152px">{t('School')}</td>
                                        <td className="text-start align-middle py-2">{!keyword_info?.name ? <LoadingSkeleton /> : keyword_info?.school || '-'}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-start align-middle py-2 bg-secondary-100" width="152px">{t('Nationality')}</td>
                                        <td className="text-start align-middle py-2">{!keyword_info?.name ? <LoadingSkeleton /> : keyword_info?.nationality || '-'}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-start align-middle py-2 bg-secondary-100" width="152px">{t('Height')}</td>
                                        <td className="text-start align-middle py-2">{!keyword_info?.name ? <LoadingSkeleton width="150px" /> : `${keyword_info?.height || '-'}cm`}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-start align-middle py-2 bg-secondary-100" width="152px">{t('Weight KG')}</td>
                                        <td className="text-start align-middle py-2">{!keyword_info?.name ? <LoadingSkeleton width="150px" /> : `${keyword_info?.weight || '-'}kg`}</td>
                                    </tr>
                                </tbody>
                            </table >
                        </div >
                    </>
                </div >
                <div className="w-100 mt-2">
                    <div className="w-100">
                        <table className="table table-bordered mb-0">
                            <tbody>
                                {/* Session 1 */}
                                <tr>
                                    <td className="text-center align-middle py-2 bg-secondary-100" rowSpan={9} width="152px">
                                        <h6 className="mb-0">{t('Info')}</h6>
                                    </td>
                                    <td className="text-center align-middle py-2">
                                        {t('Assists')}
                                    </td>
                                    <td className="text-center align-middle py-2" style={{ minWidth: '80px' }}>
                                        {!keyword_info?.name ? <LoadingSkeleton /> : keyword_info?.ast}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-center align-middle py-2">
                                        {t('Jersey Number')}
                                    </td>
                                    <td className="text-center align-middle py-2">
                                        {!keyword_info?.name ? <LoadingSkeleton /> : keyword_info?.back_no}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-center align-middle py-2">
                                        {t('Draft')}
                                    </td>
                                    <td className="text-center align-middle py-2">
                                        {!keyword_info?.name ? <LoadingSkeleton /> : keyword_info?.draft}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-center align-middle py-2">
                                        {t('Games Played')}
                                    </td>
                                    <td className="text-center align-middle py-2">
                                        {!keyword_info?.name ? <LoadingSkeleton /> : keyword_info?.gp}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-center align-middle py-2">
                                        {t('Points')}
                                    </td>
                                    <td className="text-center align-middle py-2">
                                        {!keyword_info?.name ? <LoadingSkeleton /> : keyword_info?.pts}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-center align-middle py-2">
                                        {t('Rebounds')}
                                    </td>
                                    <td className="text-center align-middle py-2">
                                        {!keyword_info?.name ? <LoadingSkeleton /> : keyword_info?.reb}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-center align-middle py-2">
                                        {t('Steals')}
                                    </td>
                                    <td className="text-center align-middle py-2">
                                        {!keyword_info?.name ? <LoadingSkeleton /> : keyword_info?.stl}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-center align-middle py-2">
                                        {t('Three-Point Percentage')}
                                    </td>
                                    <td className="text-center align-middle py-2">
                                        {!keyword_info?.name ? <LoadingSkeleton /> : keyword_info?.three_p_percentage}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-center align-middle py-2">
                                        {t('Total')}
                                    </td>
                                    <td className="text-center align-middle py-2">
                                        {!keyword_info?.name ? <LoadingSkeleton /> : keyword_info?.tot}
                                    </td>
                                </tr>
                                <InfoExtract
                                    triggerRefresh={triggerRefresh}
                                    keyword_id={keyword_info?.id}
                                    keyword_search_raw={keyword_info?.keyword_search_raw || ''}
                                    info_type_a={(keyword_info?.info_type_a || [])?.join("\n")}
                                    noun_type_b={(keyword_info?.noun_type_b || [])?.join("\n")}
                                    classTd1="text-center align-middle bg-secondary-100 py-2"
                                    classTd2="p-2 text-start"
                                    colSpanButton={3}
                                    colSpanTd2={2}
                                />
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default React.memo(BasketBallPlayer);