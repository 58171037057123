import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_NORMAL } from "common/toast";
import Keywords from "components/Common/Keywords";
import { useRole } from "components/Hooks/UserHooks";
import { useFormik } from "formik";
import { isHavePermissionRole, ROLES_FOR_APP } from "helpers/role";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Spinner } from "reactstrap";

import { putInfoSearchKeyword } from "store/thunks";
import * as Yup from "yup";

const InfoSearchKeyword = ({ keyword_id = '', search_keyword = '', triggerRefresh, classTd1 = "text-muted p-2 bg-secondary-100 align-middle", classTd2 = "p-2 text-start" }: any) => {
    const { t, i18n } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const { userPermissions } = useRole();

    const handleSubmit = async () => {
        try {
            setIsLoading((_prev) => true);
            const data = {
                search_keyword: (formik?.values?.search_keyword || [])?.map((kw) => (kw?.text || '')).join(','),
            };
            const response: any = await putInfoSearchKeyword(keyword_id, data);
            if (response?.code === 200) {
                setIsLoading((_prev) => false);
                toast(
                    `${t("The process has been completed.")}`,
                    CONFIG_OPTION_TOAST_NORMAL
                );
                triggerRefresh && triggerRefresh();
            } else {
                setIsLoading((_prev) => false);
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsLoading((_prev) => false);
            toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };

    const validationSchema = Yup.object({
        search_keyword: Yup.array().of(
            Yup.object().shape({
                id: Yup.string(),
                text: Yup.string(),
            })
        ).min(1, (`${t("Must have at least 1 keyword")}`)),
    });

    const formik = useFormik({
        initialValues: {
            search_keyword: String(search_keyword || '')?.split(',')?.filter((kw: string) => !!kw)?.map((kw: string) => ({ id: kw, text: kw })),
        },
        validationSchema,
        onSubmit: handleSubmit,
    });

    return (
        <>
            <tr>
                <td className={classTd1} width="130px">{t('Search Keywords')}</td>
                <td className={classTd2}>
                    <Keywords
                        name="keywords"
                        placeholder={`${t("Please enter and press Enter to add a new keyword")}...`}
                        suggestions={[]}
                        initialValue={formik.values.search_keyword || []}
                        onChangeTag={(event) => {
                            formik.setFieldValue("search_keyword", event);
                        }}
                    />
                    {formik?.errors?.search_keyword ? (
                        <div className="text-danger mt-1">
                            {(formik?.errors as any)?.search_keyword || ''}
                        </div>
                    ) : null}
                </td>
            </tr>
            {isHavePermissionRole(ROLES_FOR_APP.RANKING_MONITORING_UPDATE, userPermissions) && <tr>
                <td colSpan={2} className="text-end" >
                    <button type="button" onClick={handleSubmit} className="btn btn-primary ms-3" style={{ width: '120px' }}
                        disabled={formik.values.search_keyword?.length === 0}
                    >
                        {isLoading ? (
                            <Spinner size="sm" ></Spinner>
                        ) : t("Button Update")}
                    </button>
                </td>
            </tr >}
        </>
    )
}

export default React.memo(InfoSearchKeyword);