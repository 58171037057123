import moment from "moment";

export const formatNumber = (num: number) => {
    return new Intl.NumberFormat('en-IN').format(num);
}

export const formatNumberWithCommas = (number: number | string = 0) => {
    if (String(number) === 'NaN') {
        return 0;
    }
    return String(number).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export const formatNumberWithoutCommas = (number: number | string = 0) => {
    return parseFloat(String(number).replace(/,/g, ''));
};

export const formatDateStringTo2Row = (date: string = '') => {
    const arr = String(date).split(' ');
    return `${arr[0]} <br/> ${arr[1]}`;
}


export const formatQueryParams = (query: any = {}) => {
    const clone = structuredClone(query);
    delete clone.time_request;
    delete clone.time_request_out;
    delete clone.time_request_inner;
    return clone;
}

export const formatLinkHTTPS = (linkString: string = '') => {
    if ((String(linkString).includes('https://') || String(linkString).includes('http://'))) {
        return linkString;
    }
    return `https://${linkString}`;
}
export function isAfter4PMKoreanTime() {
    const currentTime = new Date();
    const currentHour = currentTime.getUTCHours(); // Get the current hour in UTC

    // Convert to Korean time zone (UTC+9)
    const koreanTimeHour = currentHour + 9; // UTC+9

    // Check if it's after 16:00 in Korean time
    if (koreanTimeHour >= 16) {
        return true;
    } else {
        return false;
    }
}

export const renderUrlSearchLookingUpByIP = (ip: string = '') => {
    return `https://whatismyipaddress.com/ip/${ip}`;
}

export const replaceHalfWithStar = (inputString: string) => {
    var length = inputString.length;
    var halfLength = Math.floor(length / 2);
    var starsCount = length - halfLength;
    var replacedString = inputString.substring(0, halfLength) + '*'.repeat(starsCount);
    return replacedString;
}

export function serialize(obj: any) {
    const queryString = Object.keys(formatQueryParams(obj))
        .filter(key => obj[key] !== '' && obj[key] !== null && obj[key] !== undefined)  // Only include keys with valid values
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)        // Map valid pairs to the query string
        .join('&');                                                                     // Join pairs with '&'
    return queryString;
}

export function extractLabel(str: string): string {
    // Split the string by ">"
    const parts: string[] = str.split('>');
    // Get the part after the last ">" and remove any extra spaces
    let label: string = parts[parts.length - 1].trim();

    // Remove the text within parentheses "(...)" and trim any extra spaces
    label = label.replace(/\(.*?\)/g, '').trim();

    return label;
}

export const getDateBasedOnTime = () => {
    // Set the time to Korea Standard Time (KST) using utcOffset (KST is UTC +9)
    const now = moment().utcOffset(9);

    // Set 9:00 AM in KST (UTC +9)
    const nineAM = moment().utcOffset(9).set({ hour: 9, minute: 0, second: 0, millisecond: 0 });

    // Compare the current time with 9:00 AM in KST
    if (now.isSameOrBefore(nineAM)) {
        // If the current time is 9:00 AM or earlier, return yesterday's date
        return now.subtract(1, 'day').format('Y-MM-DD');
    } else {
        // If the current time is after 9:00 AM, return today's date
        return now.format('Y-MM-DD');
    }
};