import ModalConfirm from "components/Common/ModalConfirm";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface AutoRefreshComponentProps {
    refresher: () => void; // Define the type of the refresher function prop
}

const AutoRefreshComponent: React.FC<AutoRefreshComponentProps> = ({ refresher }) => {
    const { t, i18n } = useTranslation();
    const [isSwitchOn, setIsSwitchOn] = useState<boolean>(false); // State to control the switch
    const [isOpen, setIsOpen] = useState<boolean>(false);
    // Load the initial state from localStorage
    useEffect(() => {
        const storedSwitchState = localStorage.getItem("autoRefreshSwitch");
        if (storedSwitchState !== null) {
            setIsSwitchOn(JSON.parse(storedSwitchState)); // Parse the stored value into a boolean
        }
    }, []);

    useEffect(() => {
        let intervalId: NodeJS.Timeout | undefined;

        // Set interval to call the refresher function every 5 minutes
        if (isSwitchOn) {
            intervalId = setInterval(() => {
                refresher(); // Call the refresher function passed as a prop
            }, 300000); // 5 minutes = 300,000 ms
        }

        // Cleanup function to clear the interval when the component unmounts or switch is turned off
        return () => {
            if (intervalId) clearInterval(intervalId);
        };
    }, [isSwitchOn, refresher]); // Re-run this effect whenever `isSwitchOn` or `refresher` changes

    const openConfirm = () => {
        setIsOpen((_prev) => true);
    }
    // Update localStorage whenever the switch state changes
    const handleSwitchChange = () => {
        const newState = !isSwitchOn;
        setIsSwitchOn(newState);
        localStorage.setItem("autoRefreshSwitch", JSON.stringify(newState)); // Store the updated state in localStorage
        setIsOpen((_prev) => false);
    };

    return (
        <>
            <div className="flex-shrink-0">
                <div className="form-check form-switch form-switch-right form-switch-md">
                    <label className="form-label text-muted form-label fs-10" htmlFor="customSwitchsizelgYU">{isSwitchOn ? "on" : "off"}</label>
                    <input type="checkbox" className="form-check-input code-switcher form-check-input" id="customSwitchsizelgYU"
                        checked={isSwitchOn}
                        onChange={openConfirm}
                    />
                </div>
            </div>
            <ModalConfirm
                header={t('Information')}
                textButtonConfirm={t('Confirm OK')}
                positionButtonConfirm='LEFT'
                classButtonConfirm={'btn-soft-info'}
                title={<div dangerouslySetInnerHTML={{ __html: `${t('Do you want to turn off the automatic page refresh function').replace('[OFF]', isSwitchOn ? '<strong class="text-danger">Off</strong>' : '<strong class="text-info">On</strong>')}?` }}></div>}
                content={<div dangerouslySetInnerHTML={{ __html: `${t('Please make sure to turn it back on after completing the management tasks.').replace('[ON]', '<strong class="text-info">On</strong>')}` }}></div>}
                isOpen={isOpen}
                classIconButtonConfirm=''
                isShowIcon={false}
                onClose={() => setIsOpen(false)}
                onConfirm={handleSwitchChange}

            />
        </>

    );
};

export default React.memo(AutoRefreshComponent);
