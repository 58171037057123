import React from "react";
import { useTranslation } from "react-i18next";
import NoImage from '../../../../assets/images/no-photo-available.png';
import InfoSearchKeyword from "./InfoSearchKeyword";
import LoadingSkeleton from "./LoadingSkeleton";

const BrandCar = ({ keyword_info = {}, triggerRefresh }: any) => {
    const { t, i18n } = useTranslation();
    return (
        <>
            <div className="d-flex flex-w flex-wrap mb-3">
                <div className="d-flex flex-column gap-3 w-100">
                    <>
                        <div className="text-center" style={{ width: '100%', height: 'auto', maxHeight: '150px', }}>
                            <img alt={keyword_info?.name || ''} loading="lazy" src={keyword_info?.image_url || keyword_info?.image || NoImage}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'contain',
                                    objectPosition: 'center',
                                    maxWidth: '100px'
                                    // backgroundColor: '#eaeaea'
                                }}
                            />
                        </div>
                        <div className="w-100">
                            <div>
                                <h3 className="mb-1">
                                    {!keyword_info?.name ? <LoadingSkeleton /> : keyword_info?.name || ''}
                                </h3>
                            </div>
                            <table className="table table table-bordered mb-0">
                                <tbody>
                                    <tr>
                                        <td className="text-muted p-2 bg-secondary-100" width="130px">{t('Classification')}</td>
                                        <td className="p-2 text-start">{!keyword_info?.name ? <LoadingSkeleton /> : String(t(`Classification_${keyword_info?.classification}`) || '-')}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-muted p-2 bg-secondary-100" width="130px">{t('Location')}</td>
                                        <td className="p-2 text-start">{!keyword_info?.name ? <LoadingSkeleton /> : String(keyword_info?.location || '-')}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-muted p-2 bg-secondary-100" width="130px">{t('Parent Company')}</td>
                                        <td className="p-2 text-start">{!keyword_info?.name ? <LoadingSkeleton /> : keyword_info?.parent_company || '-'}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-muted p-2 bg-secondary-100" width="130px">{t('Establishment Date')}</td>
                                        <td className="p-2 text-start">{!keyword_info?.name ? <LoadingSkeleton /> : keyword_info?.establishment_date || '-'}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-muted p-2 bg-secondary-100" width="130px">{t('Founder')}</td>
                                        <td className="p-2 text-start">{!keyword_info?.name ? <LoadingSkeleton /> : keyword_info?.founder || '-'}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-muted p-2 bg-secondary-100" width="130px">{t('Slogan')}</td>
                                        <td className="p-2 text-start">{!keyword_info?.name ? <LoadingSkeleton /> : keyword_info?.slogan || '-'}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-muted p-2 bg-secondary-100" width="130px">{t('Primary Industry')}</td>
                                        <td className="p-2 text-start">{!keyword_info?.name ? <LoadingSkeleton /> : keyword_info?.primary_industry || '-'}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-muted p-2 bg-secondary-100 align-middle" width="130px">{t('Descriptions')}</td>
                                        <td className="p-2 text-start">
                                            <div style={{ maxHeight: '300px', overflow: 'auto' }}>
                                                {!keyword_info?.name ? <LoadingSkeleton /> : (keyword_info?.descriptions || [])?.map((description: any) => (<><div dangerouslySetInnerHTML={{ __html: description }} /> <br /></>))}
                                            </div>
                                        </td>
                                    </tr>
                                    <InfoSearchKeyword
                                        triggerRefresh={triggerRefresh}
                                        keyword_id={keyword_info?.id}
                                        search_keyword={keyword_info?.search_keyword || ''}
                                        classTd1="text-muted p-2 bg-secondary-100 align-middle"
                                        classTd2="p-2 text-start"
                                    />
                                </tbody>
                            </table>
                        </div>
                    </>
                </div>
            </div>
        </>
    )
}

export default React.memo(BrandCar);