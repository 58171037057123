import React from "react";
import { useTranslation } from "react-i18next";
import NoImage from '../../../../assets/images/no-photo-available.png';
import InfoExtract from "./InfoExtract";
import LoadingSkeleton from "./LoadingSkeleton";

const VolleyBallPlayer = ({ keyword_info = {}, triggerRefresh }: any) => {
    const { t, i18n } = useTranslation();

    return (
        <>
            <div className="d-flex flex-w flex-wrap mb-3">
                <div className="d-flex flex-column gap-3 w-100">
                    <>
                        <div style={{ width: '100%', height: 'auto', maxHeight: '300px' }}>
                            <img alt={keyword_info?.name || ''} loading="lazy" src={keyword_info?.image_url || keyword_info?.image || NoImage}
                                style={{
                                    width: '100%',
                                    height: '300px',
                                    objectFit: 'contain',
                                    objectPosition: 'center',
                                    // backgroundColor: '#fff'
                                }}
                            />
                        </div>
                        <div className="w-100">
                            <div>
                                <h3 className="mb-1">
                                    {!keyword_info?.name ? <LoadingSkeleton width="150px" /> : keyword_info?.name || ''}
                                    <span className="fs-16 ms-2">No.{keyword_info?.no || ''}</span>
                                </h3>
                            </div>
                            <table className="table table-bordered mb-0">
                                <tbody>
                                    <tr>
                                        <td className="text-start align-middle py-2 bg-secondary-100" width="152px">{t('Team')}</td>
                                        <td className="text-start align-middle py-2">{!keyword_info?.name ? <LoadingSkeleton /> : keyword_info?.team || '-'}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-start align-middle py-2 bg-secondary-100" width="152px">{t('Birthday')}</td>
                                        <td className="text-start align-middle py-2">{!keyword_info?.name ? <LoadingSkeleton /> : keyword_info?.birthday || '-'}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-start align-middle py-2 bg-secondary-100" width="152px">{t('Position')}</td>
                                        <td className="text-start align-middle py-2">{!keyword_info?.name ? <LoadingSkeleton /> : keyword_info?.position || '-'}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-start align-middle py-2 bg-secondary-100" width="152px">{t('School')}</td>
                                        <td className="text-start align-middle py-2">{!keyword_info?.name ? <LoadingSkeleton /> : keyword_info?.school || '-'}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-start align-middle py-2 bg-secondary-100" width="152px">{t('Nationality')}</td>
                                        <td className="text-start align-middle py-2">{!keyword_info?.name ? <LoadingSkeleton /> : keyword_info?.nationality || '-'}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-start align-middle py-2 bg-secondary-100" width="152px">{t('Height')}</td>
                                        <td className="text-start align-middle py-2">{!keyword_info?.name ? <LoadingSkeleton width="150px" /> : `${keyword_info?.height || '-'}cm`}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-start align-middle py-2 bg-secondary-100" width="152px">{t('Weight KG')}</td>
                                        <td className="text-start align-middle py-2">{!keyword_info?.name ? <LoadingSkeleton width="150px" /> : `${keyword_info?.weight || '-'}kg`}</td>
                                    </tr>
                                </tbody>
                            </table >
                        </div >
                    </>
                </div >
                <div className="w-100 mt-2">
                    <div className="w-100">
                        <table className="table table-bordered mb-0">
                            <tbody>
                                {/* Session 1 */}
                                <tr>
                                    <td className="text-center align-middle py-2 bg-secondary-100" rowSpan={22} width="152px">
                                        <h6 className="mb-0">{t('Info')}</h6>
                                    </td>
                                    <td className="text-center align-middle py-2">
                                        {t('Attack Block')}
                                    </td>
                                    <td className="text-center align-middle py-2" style={{ minWidth: '80px' }}>
                                        {!keyword_info?.name ? <LoadingSkeleton /> : keyword_info?.attack_block}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-center align-middle py-2">
                                        {t('Attack Error')}
                                    </td>
                                    <td className="text-center align-middle py-2">
                                        {!keyword_info?.name ? <LoadingSkeleton /> : keyword_info?.attack_error}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-center align-middle py-2">
                                        {t('Attack Game Count')}
                                    </td>
                                    <td className="text-center align-middle py-2">
                                        {!keyword_info?.name ? <LoadingSkeleton /> : keyword_info?.attack_game_count}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-center align-middle py-2">
                                        {t('Attack Set Count')}
                                    </td>
                                    <td className="text-center align-middle py-2">
                                        {!keyword_info?.name ? <LoadingSkeleton /> : keyword_info?.attack_set_count}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-center align-middle py-2">
                                        {t('Attack Success')}
                                    </td>
                                    <td className="text-center align-middle py-2">
                                        {!keyword_info?.name ? <LoadingSkeleton /> : keyword_info?.attack_success}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-center align-middle py-2">
                                        {t('Attack Success Rate')}
                                    </td>
                                    <td className="text-center align-middle py-2">
                                        {!keyword_info?.name ? <LoadingSkeleton /> : keyword_info?.attack_success_rate}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-center align-middle py-2">
                                        {t('Attack Try')}
                                    </td>
                                    <td className="text-center align-middle py-2">
                                        {!keyword_info?.name ? <LoadingSkeleton /> : keyword_info?.attack_try}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-center align-middle py-2">
                                        {t('Jersey Number')}
                                    </td>
                                    <td className="text-center align-middle py-2">
                                        {!keyword_info?.name ? <LoadingSkeleton /> : keyword_info?.back_no}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-center align-middle py-2">
                                        {t('Military Service')}
                                    </td>
                                    <td className="text-center align-middle py-2">
                                        {!keyword_info?.name ? <LoadingSkeleton /> : keyword_info?.military_service}
                                    </td>
                                </tr>

                                <tr>
                                    <td className="text-center align-middle py-2">
                                        {t('Receive Accurate')}
                                    </td>
                                    <td className="text-center align-middle py-2">
                                        {!keyword_info?.name ? <LoadingSkeleton /> : keyword_info?.receive_accurate}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-center align-middle py-2">
                                        {t('Receive Average')}
                                    </td>
                                    <td className="text-center align-middle py-2">
                                        {!keyword_info?.name ? <LoadingSkeleton /> : keyword_info?.receive_avg}
                                    </td>
                                </tr><tr>
                                    <td className="text-center align-middle py-2">
                                        {t('Receive Efficiency')}
                                    </td>
                                    <td className="text-center align-middle py-2">
                                        {!keyword_info?.name ? <LoadingSkeleton /> : keyword_info?.receive_efficiency}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-center align-middle py-2">
                                        {t('Receive Fail')}
                                    </td>
                                    <td className="text-center align-middle py-2">
                                        {!keyword_info?.name ? <LoadingSkeleton /> : keyword_info?.receive_fail}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-center align-middle py-2">
                                        {t('Receive Game Count')}
                                    </td>
                                    <td className="text-center align-middle py-2">
                                        {!keyword_info?.name ? <LoadingSkeleton /> : keyword_info?.receive_game_count}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-center align-middle py-2">
                                        {t('Receive Set Count')}
                                    </td>
                                    <td className="text-center align-middle py-2">
                                        {!keyword_info?.name ? <LoadingSkeleton /> : keyword_info?.receive_set_count}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-center align-middle py-2">
                                        {t('Receive Try')}
                                    </td>
                                    <td className="text-center align-middle py-2">
                                        {!keyword_info?.name ? <LoadingSkeleton /> : keyword_info?.receive_try}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-center align-middle py-2">
                                        {t('Set Average')}
                                    </td>
                                    <td className="text-center align-middle py-2">
                                        {!keyword_info?.name ? <LoadingSkeleton /> : keyword_info?.set_avg}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-center align-middle py-2">
                                        {t('Set Game Count')}
                                    </td>
                                    <td className="text-center align-middle py-2">
                                        {!keyword_info?.name ? <LoadingSkeleton /> : keyword_info?.set_game_count}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-center align-middle py-2">
                                        {t('Set Set Count')}
                                    </td>
                                    <td className="text-center align-middle py-2">
                                        {!keyword_info?.name ? <LoadingSkeleton /> : keyword_info?.set_set_count}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-center align-middle py-2">
                                        {t('Set Success')}
                                    </td>
                                    <td className="text-center align-middle py-2">
                                        {!keyword_info?.name ? <LoadingSkeleton /> : keyword_info?.set_success}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-center align-middle py-2">
                                        {t('Set Try')}
                                    </td>
                                    <td className="text-center align-middle py-2">
                                        {!keyword_info?.name ? <LoadingSkeleton /> : keyword_info?.set_try}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-center align-middle py-2">
                                        {t('Player History')}
                                    </td>
                                    <td className="text-center align-middle py-2">
                                        {!keyword_info?.name ? <LoadingSkeleton /> : <div className="text-start">
                                            {
                                                (Array.isArray(keyword_info?.player_history)) ? keyword_info?.player_history?.map((history: any) => <p>
                                                    {`${history?.history || ''}`}
                                                </p>) : <></>
                                            }
                                        </div>}
                                    </td>
                                </tr>
                                <InfoExtract
                                    triggerRefresh={triggerRefresh}
                                    keyword_id={keyword_info?.id}
                                    keyword_search_raw={keyword_info?.keyword_search_raw || ''}
                                    info_type_a={(keyword_info?.info_type_a || [])?.join("\n")}
                                    noun_type_b={(keyword_info?.noun_type_b || [])?.join("\n")}
                                    classTd1="text-center align-middle bg-secondary-100 py-2"
                                    classTd2="p-2 text-start"
                                    colSpanButton={3}
                                    colSpanTd2={2}
                                />
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default React.memo(VolleyBallPlayer);