import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_NORMAL } from "common/toast";
import { useRole } from "components/Hooks/UserHooks";
import { useFormik } from "formik";
import { isHavePermissionRole, ROLES_FOR_APP } from "helpers/role";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Spinner } from "reactstrap";

import { putInfoExtractKeyword } from "store/thunks";
import * as Yup from "yup";

const InfoExtract = ({ colSpanButton = 2, colSpanTd2 = 1, keyword_id = '', info_type_a = '', noun_type_b = '', keyword_search_raw = '', triggerRefresh, classTd1 = "text-muted p-2 bg-secondary-100 align-middle", classTd2 = "p-2 text-start" }: any) => {
    const { t, i18n } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const { userPermissions } = useRole();

    const handleSubmit = async () => {
        try {
            setIsLoading((_prev) => true);
            const data = {
                keyword_search_raw: formik?.values?.keyword_search_raw || '',
                info_type_a: String(formik?.values?.info_type_a || '').split('\n')?.filter((k: any) => !!k),
                noun_type_b: String(formik?.values?.noun_type_b || '').split('\n')?.filter((k: any) => !!k),
            };
            const response: any = await putInfoExtractKeyword(keyword_id, data);
            if (response?.code === 200) {
                setIsLoading((_prev) => false);
                toast(
                    `${t("The process has been completed.")}`,
                    CONFIG_OPTION_TOAST_NORMAL
                );
                triggerRefresh && triggerRefresh();
            } else {
                setIsLoading((_prev) => false);
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsLoading((_prev) => false);
            toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };

    const validationSchema = Yup.object({
        keyword_search_raw: Yup.string().nullable(),
        noun_type_a: Yup.string().nullable(),
        noun_type_b: Yup.string().nullable(),
    });

    const formik = useFormik({
        initialValues: {
            keyword_search_raw: keyword_search_raw,
            info_type_a: info_type_a,
            noun_type_b: noun_type_b,
        },
        validationSchema,
        onSubmit: handleSubmit,
    });

    return (
        <>
            <tr>
                <td className={classTd1} width="130px">{t('News Search Keyword')}</td>
                <td className={classTd2} colSpan={colSpanTd2}>
                    <input style={{ maxHeight: '80px', overflow: 'auto' }} className="form-control"
                        value={formik.values?.keyword_search_raw}
                        onChange={(event: any) =>
                            formik.setFieldValue(
                                "keyword_search_raw",
                                event?.target?.value || ""
                            )
                        }
                    />
                </td>
            </tr>
            <tr>
                <td className={classTd1} width="130px">{t('Store Type A keywords as main keywords')}</td>
                <td className={classTd2} colSpan={colSpanTd2}>
                    <textarea style={{ maxHeight: '300px', overflow: 'auto' }} rows={6} className="form-control"
                        value={formik.values?.info_type_a}
                        onChange={(event: any) =>
                            formik.setFieldValue(
                                "info_type_a",
                                event?.target?.value || ""
                            )
                        }
                    />
                </td>
            </tr>
            <tr >
                <td className={classTd1} width="130px" >{t('Store Type B keywords as sub keywords')}</td>
                <td className={classTd2} colSpan={colSpanTd2}>
                    <textarea style={{ maxHeight: '300px', overflow: 'auto' }} rows={6} className="form-control"
                        value={formik.values?.noun_type_b}
                        onChange={(event: any) =>
                            formik.setFieldValue(
                                "noun_type_b",
                                event?.target?.value || ""
                            )
                        }
                    />
                </td>
            </tr>
            {isHavePermissionRole(ROLES_FOR_APP.RANKING_MONITORING_UPDATE, userPermissions) && <tr>
                <td colSpan={colSpanButton} className="text-end" >
                    <button type="button" onClick={handleSubmit} className="btn btn-primary ms-3" style={{ width: '120px' }}>
                        {isLoading ? (
                            <Spinner size="sm" ></Spinner>
                        ) : t("Button Update")}
                    </button>
                </td>
            </tr >}
        </>
    )
}

export default React.memo(InfoExtract);