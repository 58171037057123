import TableContainer from "components/Common/TableContainer";
import { useRole } from "components/Hooks/UserHooks";
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import { isHavePermissionRole, ROLES_FOR_APP } from "helpers/role";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CountUp from "react-countup";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { createSelector } from "reselect";
import { getNewsSearchingKeyword as onGetNewsSearchingKeyword } from "../../../store/thunks";
import LabelWrapper from "components/Common/LabelWrapper";
import DropdownStatus from "components/Common/DropdownStatus";
import { COLOR_TYPE_SENTIMENT } from "helpers/constans";


registerLocale("en", en);
registerLocale("ko", ko);


const ModalNewsSearchingKeyword = ({ keywordAnalysisNews }: any) => {
    const { t, i18n } = useTranslation();
    const { userPermissions } = useRole();
    const navigate = useNavigate();

    const STATUS_KEYWORD_ANALYSIS_OPTIONS_LANG = [{ value: '1', label: 'Assigned' }, { value: '0', label: 'Unassigned' }]?.map((item: any) => ({
        value: item?.value,
        label: t(item?.label),
    }));

    const [query, setQuery]: any = useState({
        // page: 1,
        // limit: 20,
        keyword_id: keywordAnalysisNews?.keyword_id,
        date: keywordAnalysisNews?.date,
        // sort_by: SORT_BY_DEFAULT,
        // order_by: ORDER_BY_DEFAULT,
    });

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [listKeyword, setListKeyword] = useState<any>([]);

    const [statusSearch, setStatusSearch] = useState<any | null>(STATUS_KEYWORD_ANALYSIS_OPTIONS_LANG[0]);

    const dispatch: any = useDispatch();
    const selectLayoutState = (state: any) => state.Keyword;

    const KeywordProperties = createSelector(selectLayoutState, (state) => ({
        searching: state.searching,
        isSearchingSuccess: state.isSearchingSuccess,
        isSearchingLoading: state.isSearchingLoading,
        error: state.error,
    }));

    // Inside your component
    const { searching, error, isSearchingLoading } = useSelector(KeywordProperties);


    useEffect(() => {
        if (!isSearchingLoading) {
            dispatch(onGetNewsSearchingKeyword(query));
        }
    }, [dispatch, query]);

    // Column
    const columns = useMemo(
        () => [
            {
                Header: t(''),
                accessor: "stt",
                filterable: false,
                sortable: false,
                thWidth: 70,
                thClass: 'align-middle text-start',
                Cell: (cell: any) => {
                    return (<>
                        <div className="text-start" style={{ minWidth: '50px' }}>{(cell?.cell?.row?.index + 1)}</div>
                    </>)
                },
            },
            {
                Header: t("Keyword"),
                accessor: "keyword",
                filterable: false,
                sortable: false,
                thWidth: 100,
                thClass: "align-middle",
                Cell: (cell: any) => {
                    const item: any = cell?.row?.original;
                    return (
                        <div style={{ minWidth: "100px" }}>
                            {item?.keyword || ''}
                        </div>
                    );
                },
            },
            {
                Header: t("Date"),
                accessor: "date",
                filterable: false,
                sortable: false,
                thWidth: 90,
                thClass: "align-middle",
                Cell: (cell: any) => {
                    const item: any = cell?.row?.original;
                    return (
                        <div style={{ minWidth: "90px" }}>
                            {item?.date || ''}
                        </div>
                    );
                },
            },
            {
                Header: t('Article Title'),
                accessor: "article_title",
                filterable: false,
                sortable: false,
                thWidth: 200,
                thClass: 'text-start align-middle',
                Cell: (cell: any) => {
                    const item: any = cell?.row?.original;
                    return (
                        <div className="text-start" style={{ minWidth: '200px', maxHeight: '200px', overflowY: 'auto' }}>
                            <Link target="_blank" className="text-primary text-decoration-underline" to={item?.url || '-'}>
                                {item?.url}
                            </Link>
                            <br />
                            <div className="mt-2">
                                <Link target="_blank" className="text-dark" to={item?.url || '-'}>
                                    {item?.article_title}
                                </Link>
                            </div>
                        </div>
                    )
                },
            },
            {
                Header: t("Article Content"),
                accessor: "article_content",
                filterable: false,
                sortable: false,
                thClass: "text-start align-middle",
                Cell: (cell: any) => (
                    <div className="text-start" style={{ minWidth: '200px', maxWidth: '1200px', maxHeight: '200px', overflowY: 'auto' }}>
                        {cell?.value}
                    </div>
                ),
            },
            {
                Header: t("Sentiment"),
                thClass: "text-end align-top",
                filterable: false,
                sortable: false,
                thWidth: 400,
                thComponent: () => (
                    <table>
                        <thead className="none-sticky">
                            <tr>
                                <th className="text-center pb-1 none-sticky" colSpan={5}>
                                    {t("Sentiment")}
                                </th>
                            </tr>
                            <tr>
                                <th
                                    className="text-center px-0 py-1 cursor-pointer none-sticky"
                                    style={{ width: "100px" }}
                                >
                                    {t("Sentiment")}
                                </th>
                                <th
                                    className="text-center px-0 py-1 cursor-pointer none-sticky"
                                    style={{ width: "100px" }}
                                >
                                    {t("Positive")}
                                </th>
                                <th
                                    className="text-center px-0 py-1 cursor-pointer none-sticky"
                                    style={{ width: "100px" }}
                                >
                                    {t("Negative")}
                                </th>
                                <th
                                    className="text-center px-0 py-1 cursor-pointer none-sticky"
                                    style={{ width: "100px" }}
                                >
                                    {t("Neutral")}
                                </th>
                            </tr>
                        </thead>
                    </table>
                ),
                Cell: (cell: any) => {
                    const item: any = cell?.row?.original;
                    return (
                        <div className="d-flex text-center">
                            <div style={{ width: "100px" }} className={`text-${COLOR_TYPE_SENTIMENT?.[`${item?.sentiment_value || '-'}`] || 'muted'}`}>
                                {t(`${item?.sentiment_value || '-'}`)}
                            </div>
                            <div style={{ width: "100px", borderLeft: "1px solid #eaeaea" }}>
                                {item?.ratio_positive}%
                            </div>
                            <div style={{ width: "100px", borderLeft: "1px solid #eaeaea" }}>
                                {item?.ratio_negative}%
                            </div>
                            <div
                                style={{
                                    width: "100px",
                                    borderLeft: "1px solid #eaeaea",
                                }}
                            >
                                {item?.ratio_neutral}%
                            </div>
                        </div>
                    );
                },
            },
            {
                Header: t("Assignment Status"),
                accessor: "assignment_status",
                filterable: false,
                sortable: false,
                thWidth: 160,
                thClass: "text-center align-middle",
                Cell: (cell: any) => {
                    return (
                        <>
                            <div
                                className="text-center"
                                style={{ minWidth: "160px" }}
                            >
                                {!!Boolean(cell?.value) ? <span className="rounded-pill badge bg-success">{t('Assigned')}</span> : <span className="rounded-pill badge bg-danger">{t('Unassigned')}</span>}
                            </div>
                        </>
                    );
                },
            },
            {
                Header: t("Assigned Keywords"),
                accessor: "assigned_keywords",
                filterable: false,
                sortable: false,
                thWidth: 150,
                thClass: "text-start align-middle",
                Cell: (cell: any) => {
                    const item: any = cell?.row?.original;
                    return (
                        <>
                            <div
                                style={{ minWidth: "150px" }}
                                className="text-start"
                            >
                                {(cell?.value || [])?.map((keyword: string) => (
                                    <div>
                                        {keyword || ''} <br />
                                    </div>
                                ))}
                            </div>
                        </>
                    );
                },
            },
            {
                Header: t("The number of assigned keywords"),
                accessor: "total_matches",
                filterable: false,
                sortable: false,
                thWidth: 130,
                thClass: "text-center align-middle",
                Cell: (cell: any) => {
                    const item: any = cell?.row?.original;
                    return (
                        <>
                            <div
                                style={{ minWidth: "100px" }}
                                className="cursor-pointer text-center"
                            >
                                {cell?.value}
                            </div>
                        </>
                    );
                },
            },
        ],
        [
            i18n?.language,
            userPermissions,
            JSON.stringify(query),
        ]
    );

    const handleChangePage = useCallback((page: any) => {
        setQuery((_prev: any) => {
            return { ..._prev, page: page + 1 };
        });
    }, []);

    const handleChangeSorting = useCallback((sortBy: any) => {
        setQuery((_prev: any) => {
            return { ..._prev, ...sortBy };
        });
    }, []);

    useEffect(() => {
        setListKeyword((_prev: any) => (searching?.list || [])?.filter((item: any) => (`${Number(item?.assignment_status)}` === `${statusSearch?.value}` || !statusSearch?.value)));
    }, [statusSearch, searching?.list]);

    return (
        <React.Fragment>
            <div>
                <div>
                    {isHavePermissionRole(
                        ROLES_FOR_APP.RANKING_MONITORING,
                        userPermissions
                    ) && (
                            <Row>
                                <Col sm={12}>
                                    <Card
                                        id="customerList"
                                        style={{ boxShadow: "0px 3px 4px 0px rgba(0, 0, 0, 0.03)" }}
                                    >
                                        <CardHeader className="border-0">
                                            <Row className="g-4 align-items-center">
                                                <div className="col-sm">
                                                    <div>
                                                        <h5 className="card-title mb-0">
                                                            <span className="me-2">{t("Total")}:</span>
                                                            <CountUp
                                                                start={0}
                                                                end={isSearchingLoading ? 0 : (listKeyword?.length || 0)}
                                                                duration={1}
                                                                className="text-primary"
                                                            />
                                                        </h5>
                                                    </div>
                                                </div>
                                            </Row>
                                        </CardHeader>
                                        <div className=" border border-dashed border-end-0 border-start-0 card-body mt-0 pb-4">
                                            <Row className="g-4 align-items-center mt-0">
                                                <Col sm={12} md={6} xl={3} xxl={2} className='mt-3 mt-md-3 mt-xl-2'>
                                                    <LabelWrapper label={t('Assigned Status')} isShow={true}>
                                                        <DropdownStatus
                                                            name="status"
                                                            dataList={STATUS_KEYWORD_ANALYSIS_OPTIONS_LANG || []}
                                                            placeholder={`${t("Assigned Status")}...`}
                                                            className="dropdown-status-rounded"
                                                            classNamePrefix="name-prefix"
                                                            initialValue={statusSearch || null}
                                                            onChangeSelect={(e: any) => setStatusSearch(e)}
                                                            isHasOptionAll={true}
                                                            optionAll={{ label: t('All Assigned Status'), value: '' }}
                                                            colors={['danger', 'success']}
                                                        />
                                                    </LabelWrapper>
                                                </Col>
                                            </Row>
                                        </div>
                                        <CardBody className="pt-3">
                                            <TableContainer
                                                className="custom-header-css"
                                                divClass="table-responsive table-card"
                                                tableClass="align-middle table-bordered-dashed"
                                                theadClass="table-light text-muted"
                                                columns={columns}
                                                data={isSearchingLoading ? [] : listKeyword}
                                                customPageSize={query.limit}
                                                customPageIndex={query.page - 1}
                                                totalRecords={searching?.pagination?.total}
                                                customPageCount={Math.ceil(
                                                    Number(searching?.pagination?.total) /
                                                    Number(searching?.pagination?.limit)
                                                )}
                                                handleChangePage={handleChangePage}
                                                manualSorting={true}
                                                sorting={{
                                                    sort_by: query.sort_by,
                                                    order_by: query.order_by,
                                                }}
                                                handleChangeSorting={handleChangeSorting}
                                                isLoading={isSearchingLoading}
                                                isShowPagination={false}
                                            />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        )}
                    <ToastContainer closeButton={false} limit={1} />
                </div>
            </div>
        </React.Fragment>
    );
};

export default ModalNewsSearchingKeyword;
