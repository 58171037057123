import { ICategory } from "api/types/_category";
import { IMedia, IOfficialMedia } from "api/types/_media";
import { Option } from "api/types/_public";
import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_NORMAL } from "common/toast";
import DropdownOption from "components/Common/DropdownOption";
import DropdownStatus from "components/Common/DropdownStatus";
import LabelWrapper from "components/Common/LabelWrapper";
import ModalConfirm from "components/Common/ModalConfirm";
import TableContainer from "components/Common/TableContainer";
import TooltipCustom from "components/Common/TooltipCustom";
import { useRole } from "components/Hooks/UserHooks";
import { STATUS_AUTO_SELECTED_OPTIONS, STATUS_MEDIA_OPTIONS, STATUS_OFFICIAL_MEDIA_OPTIONS } from "helpers/constans";
import { isHavePermissionRole, ROLES_FOR_APP } from "helpers/role";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CountUp from "react-countup";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Card, CardBody, CardHeader, Col, Container, Modal, ModalBody, ModalHeader, Offcanvas, OffcanvasBody, OffcanvasHeader, Row } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import { deleteOfficialMedia, getAllCategoriesSimple, getAllMedias, getOfficialMedias as onGetOfficialMedias } from "../../../store/thunks";
import FormOfficialMedia from "./FormOfficialMedia";
import { formatNumberWithCommas } from "helpers/format";

const SORT_BY_DEFAULT = 'id';
const ORDER_BY_DEFAULT = 'DESC';
const MAX_WIDTH_SCREEN = 1800;
const ManageOfficialMedia = () => {
    const { t, i18n } = useTranslation();
    const { userPermissions } = useRole();
    const navigate = useNavigate();

    const STATUS_OFFICIAL_MEDIA_OPTIONS_LANG = STATUS_OFFICIAL_MEDIA_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));
    const STATUS_AUTO_SELECTED_OPTIONS_LANG = STATUS_AUTO_SELECTED_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

    const [query, setQuery]: any = useQueryParams({
        time_request: withDefault(NumberParam, 0),
        page: withDefault(NumberParam, 1),
        limit: withDefault(NumberParam, 50),
        sort_by: withDefault(StringParam, SORT_BY_DEFAULT),
        order_by: withDefault(StringParam, ORDER_BY_DEFAULT),
        name: withDefault(StringParam, ''),
        category_id: withDefault(StringParam, ''),
        media_id: withDefault(StringParam, ''),
        is_active: withDefault(StringParam, ''),
        is_auto_selected: withDefault(StringParam, ''),
    });

    const dispatch: any = useDispatch();

    const selectLayoutState = (state: any) => state.Media;

    const MediaProperties = createSelector(
        selectLayoutState,
        (state) => ({
            officialmedias: state.officialmedias,
            isOfficialMediaSuccess: state.isOfficialMediaSuccess,
            isOfficialMediaLoading: state.isOfficialMediaLoading,
            error: state.error,
        })
    );

    const { officialmedias, isOfficialMediaLoading } = useSelector(MediaProperties);


    const [nameSearch, setNameSearch] = useState<string>(query?.name || "");

    const [statusSearch, setStatusSearch] = useState<Option | null>(STATUS_OFFICIAL_MEDIA_OPTIONS_LANG?.filter((item) => String(item?.value) === String(query?.is_active))[0]);
    const [autoSelectedSearch, setAutoSelectedSearch] = useState<Option | null>(STATUS_AUTO_SELECTED_OPTIONS_LANG?.filter((item) => String(item?.value) === String(query?.is_auto_selected))[0]);

    const [mediaSearch, setMediaSearch] = useState<Option | null>(null);
    const [categorySearch, setCategorySearch] = useState<Option | null>(null);

    const [mediaDetail, setMediaDetail] = useState<IOfficialMedia | null>(null);

    const [listMedia, setListMedia] = useState<IMedia[]>([]);
    const [listCategory, setListCategory] = useState<ICategory[]>([]);


    const [idDelete, setIdDelete] = useState<number | null>(null);
    const [isConfirmLoading, setIsConfirmLoading] = useState<boolean>(false);

    const [windowSize, setWindowSize] = useState<{ width: number, height: number }>({ width: 0, height: 0 });

    useEffect(() => {
        dispatch(onGetOfficialMedias(query));
    }, [JSON.stringify(query)]);

    const searchData = () => {
        setQuery({
            ...query,
            page: 1,
            name: nameSearch || '',
            category_id: categorySearch?.value || '',
            media_id: mediaSearch?.value || '',
            is_active: statusSearch?.value || '',
            is_auto_selected: autoSelectedSearch?.value || '',
            time_request: + new Date()
        });
    };

    const resetData = () => {
        setQuery({
            name: '',
            category_id: '',
            media_id: '',
            is_active: '',
            is_auto_selected: '',
            sort_by: SORT_BY_DEFAULT,
            order_by: ORDER_BY_DEFAULT,
            time_request: + new Date(),
            page: 1,
        }, "push");
        setNameSearch("");
        setMediaSearch(null);
        setCategorySearch(null);
        setStatusSearch(null);
        setAutoSelectedSearch(null);
    };


    const handleRefresh = (isClose: boolean = true) => {
        if (isClose) { setMediaDetail((_prev) => null); }
        resetData();
    }

    // Column
    const columns = useMemo(
        () => [
            {
                Header: t('ID'),
                accessor: "id",
                filterable: true,
                sortable: true,
                thClass: 'align-middle',
                Cell: (cell: any) => (
                    <>
                        <div style={{ minWidth: '40px' }}>{cell?.value}</div>
                    </>
                ),
            },
            {
                Header: t('Ranking'),
                accessor: "ranking",
                filterable: false,
                sortable: false,
                description: t('Number of articles published on Naver'),
                thClass: 'align-middle',
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="d-flex align-items-center" style={{ minWidth: '100px' }}>
                                {cell?.value}
                            </div>
                        </>
                    )
                },
            },
            {
                Header: t('Categories (Level 1)'),
                accessor: "category",
                filterable: false,
                sortable: false,
                thClass: 'align-middle',
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="d-flex align-items-center" style={{ minWidth: '140px' }}>
                                {item?.categories?.map((cate: any) => (<>{cate?.name || ''} <br /></>))}
                            </div>
                        </>
                    )
                },
            },
            {
                Header: t('Name'),
                accessor: "name",
                filterable: false,
                sortable: false,
                thClass: 'align-middle',
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="d-flex align-items-center" style={{ minWidth: '100px' }}>
                                {cell?.value}
                            </div>
                        </>
                    )
                },
            },
            // {
            //     Header: t('Media Name'),
            //     accessor: "media",
            //     filterable: false,
            //     sortable: false,
            //     Cell: (cell: any) => {
            //         const item = cell?.row?.original;
            //         return (
            //             <>
            //                 <div className="d-flex align-items-center" style={{ minWidth: '100px' }}>
            //                     {item?.media?.name || ''}
            //                 </div>
            //             </>
            //         )
            //     },
            // },
            {
                Header: t("Request"),
                thClass: "text-end align-middle",
                filterable: false,
                sortable: false,
                // description: t('Description PV field each platform'),
                thWidth: 200,
                thComponent: () => (
                    <table>
                        <thead>
                            <tr>
                                <th className="text-center pb-1" colSpan={2}>
                                    {t("Request")}
                                </th>
                            </tr>
                            <tr>
                                <th
                                    className="text-center px-0 py-1 cursor-pointer"
                                    style={{ width: "100px" }}
                                >
                                    {t("Current")}
                                    <br />
                                    <span style={{ marginLeft: "2px" }}>({t("Daily")})</span>
                                </th>
                                <th
                                    className="text-center px-0 py-1 cursor-pointer"
                                    style={{ width: "100px" }}
                                >
                                    {t("Limit")}
                                    <br />
                                    <span style={{ marginLeft: "2px" }}>({t("Daily")})</span>
                                </th>
                            </tr>
                        </thead>
                    </table>
                ),
                Cell: (cell: any) => {
                    const item: any = cell?.row?.original;
                    return (
                        <div className="d-flex text-center">
                            <div style={{ width: "100px" }}>
                                {formatNumberWithCommas(item.number_current_request)}
                            </div>
                            <div style={{ width: "100px", borderLeft: "1px solid #eaeaea" }}>
                                {formatNumberWithCommas(item.request_limit)}
                            </div>
                        </div>
                    );
                },
            },
            {
                Header: t('Number of articles published'),
                accessor: "media_number",
                filterable: false,
                sortable: false,
                thClass: 'text-end align-middle',
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-end" style={{ minWidth: '100px' }}>
                                {formatNumberWithCommas(cell?.value)}
                            </div>
                        </>
                    )
                },
            },

            {
                Header: t('Auto-selection Status'),
                accessor: "is_auto_selected",
                filterable: false,
                sortable: false,
                thWidth: 150,
                thClass: 'text-center align-middle',
                Cell: (cell: any) => {
                    return (
                        <div className="text-center" style={{ minWidth: '150px' }}>
                            <span className={`rounded-pill badge bg-${['danger', 'success'][cell?.value] || 'secondary'}`}>{STATUS_AUTO_SELECTED_OPTIONS_LANG?.find((item: any) => String(item?.value) === String(cell?.value))?.label}</span>
                        </div>
                    )
                },
            },
            {
                Header: t('Status'),
                accessor: "is_active",
                filterable: false,
                sortable: false,
                thWidth: 100,
                thClass: 'text-center align-middle',
                Cell: (cell: any) => {
                    return (
                        <div className="text-center" style={{ minWidth: '70px' }}>
                            <span className={`rounded-pill badge bg-${['danger', 'success'][cell?.value] || 'secondary'}`}>{STATUS_OFFICIAL_MEDIA_OPTIONS_LANG?.find((item: any) => String(item?.value) === String(cell?.value))?.label}</span>
                        </div>
                    )
                },
            },

            {
                Header: t('Created at'),
                accessor: "created_at",
                filterable: true,
                sortable: false,
                thWidth: 150,
                thClass: 'text-end align-middle',
                Cell: (cell: any) => {
                    const arrDate = String(cell?.value || '').split(' ');
                    return (
                        <div className="text-end" style={{ minWidth: '120px' }}>
                            <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
                        </div>
                    )
                },
            },
            {
                Header: t('Updated at'),
                accessor: "updated_at",
                filterable: true,
                sortable: false,
                thWidth: 150,
                thClass: 'text-end align-middle',
                Cell: (cell: any) => {
                    const arrDate = String(cell?.value || '').split(' ');
                    return (
                        <div className="text-end" style={{ minWidth: '120px' }}>
                            <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
                        </div>
                    )
                },
            },
            {
                Header: t('Action'),
                thClass: 'text-center align-middle',
                thWidth: 150,
                Cell: (cell: any) => {
                    const item: any = cell?.row?.original;
                    return (
                        <div className="text-center" style={{ minWidth: '120px' }}>
                            <ul className="list-inline justify-content-center hstack gap-2 mb-0">
                                <TooltipCustom
                                    title={t('Button Detail Official Media')}
                                    id={`detail-cp-${item?.id}`}
                                >
                                    <li className="list-inline-item edit " title="Edit">
                                        <Link className="btn btn-sm btn-soft-secondary d-inline-block edit-item-btn" to="#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setMediaDetail((_prev) => ({ ...item, is_preview: true }));
                                            }}>
                                            <i className="ri-eye-fill"></i>
                                        </Link>
                                    </li>
                                </TooltipCustom>
                                {isHavePermissionRole(ROLES_FOR_APP.OFFICIAL_MEDIA_UPDATE, userPermissions) && (<TooltipCustom
                                    title={t('Button Update Official Media')}
                                    id={`update-cp-${item?.id}`}
                                >
                                    <li className="list-inline-item edit " title="Edit">
                                        <Link className="btn btn-sm btn-soft-primary d-inline-block edit-item-btn" to="#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setMediaDetail((_prev) => item);
                                            }}>
                                            <i className="ri-pencil-fill"></i>
                                        </Link>
                                    </li>
                                </TooltipCustom>)}
                                {isHavePermissionRole(ROLES_FOR_APP.OFFICIAL_MEDIA_DELETE, userPermissions) && (<TooltipCustom
                                    title={t('Button Delete Media')}
                                    id={`detail-cp-${item?.id}`}
                                >
                                    <li className="list-inline-item" title="Remove">
                                        <Link className="btn btn-sm btn-soft-danger d-inline-block edit-item-btn" to="#"
                                            onClick={(e) => { e.preventDefault(); handleConfirmDelete(item) }}
                                        >
                                            <i className="ri-delete-bin-5-fill"></i>
                                        </Link>
                                    </li>
                                </TooltipCustom>)}
                            </ul>
                        </div>
                    );
                },
            },
        ],
        [i18n?.language, userPermissions, JSON.stringify(query), STATUS_OFFICIAL_MEDIA_OPTIONS_LANG, STATUS_AUTO_SELECTED_OPTIONS_LANG]
    );

    const handleChangePage = useCallback((page: any) => {
        setQuery({ page: page + 1 })
    }, []);

    const handleChangeSorting = useCallback((sortBy: any) => {
        setQuery((_prev: any) => {
            return ({ ..._prev, ...sortBy });
        });
    }, []);

    // Begin::Delete
    const handleConfirmDelete = (item: IMedia) => {
        setIdDelete((_prev) => item?.id);
    };

    const handleActionDelete = async () => {
        if (!idDelete) { return; };
        try {
            setIsConfirmLoading((_prev) => true);
            const response: any = await deleteOfficialMedia(idDelete);
            if (response?.code === 200) {
                dispatch(onGetOfficialMedias(query));
                setIsConfirmLoading((_prev) => false);
                setIdDelete((_prev) => null);
                toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
            } else {
                setIsConfirmLoading((_prev) => false);
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsConfirmLoading((_prev) => false);
            toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };

    const onCloseConfirmClick = () => {
        setIdDelete((_prev) => null);
    }
    // End::Delete

    const handleActionRefresh = () => {
        dispatch(onGetOfficialMedias(query));
    }

    useEffect(() => {
        const handleCallAllOption = async () => {
            try {
                const [res1]: any = await Promise.all([getAllCategoriesSimple({ depth: 1 })]);
                // setListMedia((_prev) => res1?.data || []);
                setListCategory((_prev: any) => res1?.data || []);
                // if (query?.media) {
                //     setMediaSearch((_prev) => {
                //         const item = res1?.data?.find((item: any) => (String(query?.media || '') === String(item?.value || '')));
                //         return ({
                //             label: item?.name,
                //             value: `${item?.id || ''}`,
                //         });
                //     });
                // }
                if (query?.category_id) {
                    setCategorySearch((_prev) => {
                        const item = res1?.data?.find((item: any) => (String(query?.category_id || '') === String(item?.id || '')));
                        return ({
                            label: item?.name,
                            value: `${item?.id || ''}`,
                        });
                    });
                }
            } catch (error: any) {
                return error;
            }
        };
        handleCallAllOption();
    }, []);


    useEffect(() => {
        document.title = `${t("Manage Official Media")} - ${t("Official Media Management")} | Rankify`;
        document.body.classList.remove("vertical-sidebar-enable");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n?.language]);

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={t('Manage Official Media')} pageTitle={t('Official Media Management')} />
                    {isHavePermissionRole(ROLES_FOR_APP.OFFICIAL_MEDIA_MANAGEMENT, userPermissions) && (
                        <Row>
                            <Col sm={12} md={12}>
                                <Card id="customerList" style={{ boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)' }}>
                                    <CardHeader className="border-0">
                                        <Row className="g-4 align-items-center">
                                            <div className="col-4 col-sm">
                                                <div>
                                                    <h5 className="card-title mb-0">
                                                        <span className="me-2">{t('Total')}:</span>
                                                        <CountUp
                                                            start={0}
                                                            end={officialmedias?.pagination?.total || 0}
                                                            duration={1}
                                                            className="text-primary"
                                                        />
                                                    </h5>
                                                </div>
                                            </div>
                                            {isHavePermissionRole(ROLES_FOR_APP.OFFICIAL_MEDIA_CREATE, userPermissions) && (<div className="col-8 text-end col-sm-auto">
                                                <div>
                                                    <button
                                                        type="button"
                                                        className="btn btn-success add-btn rounded-pill"
                                                        id="create-btn"
                                                        onClick={() => setMediaDetail((_prev) => ({} as IOfficialMedia))}
                                                    >
                                                        <i className="ri-add-line align-bottom me-1"></i>
                                                        {t('Button Create Official Media')}
                                                    </button>
                                                </div>
                                            </div>)}
                                        </Row>
                                    </CardHeader>
                                    <div className=" border border-dashed border-end-0 border-start-0 card-body mt-0 pb-4">
                                        <Row className="g-4 align-items-center mt-0">
                                            {/* <Col sm={6} md={4} xl={3} xxl={3} className='mt-3 mt-md-2'>
                                                <LabelWrapper label={t('Name')} isShow={!!nameSearch}>
                                                    <Input
                                                        type="text"
                                                        className="form-control search"
                                                        placeholder={`${t('Name')}...`}
                                                        value={nameSearch}
                                                        onChange={(e) => setNameSearch(e.target.value)}
                                                        onKeyDown={(e) => {
                                                            if (e.key === "Enter") {
                                                                searchData();
                                                            }
                                                        }}
                                                    />
                                                </LabelWrapper>
                                            </Col> */}

                                            <Col sm={12} md={4} xl={3} xxl={2} className='mt-3 mt-md-2'>
                                                <LabelWrapper label={t('Category (Level 1)')} isShow={true}>
                                                    <DropdownOption
                                                        name="category"
                                                        dataList={listCategory?.map((item: any) => ({ value: String(item?.id), label: item?.name }))}
                                                        placeholder={`${t("Category")}...`}
                                                        className="search-filter-category-type dropdown-status-rounded "
                                                        classNamePrefix="name-prefix"
                                                        initialValue={categorySearch || null}
                                                        onChangeSelect={(e: any) => setCategorySearch(e)}
                                                        isHasOptionAll={true}
                                                        optionAll={{ label: t('All Category'), value: '' }}
                                                    />
                                                </LabelWrapper>
                                            </Col>
                                            {/* <Col sm={12} md={4} xl={3} xxl={2} className='mt-3 mt-md-2'>
                                                <LabelWrapper label={t('Media')} isShow={!!mediaSearch?.value}>
                                                    <DropdownOption
                                                        name="category"
                                                        dataList={listMedia?.map((item: any) => ({ value: String(item?.id), label: item?.name }))}
                                                        placeholder={`${t("Media")}...`}
                                                        className="search-filter-category-type dropdown-status-rounded "
                                                        classNamePrefix="name-prefix"
                                                        initialValue={mediaSearch || null}
                                                        onChangeSelect={(e: any) => setMediaSearch(e)}
                                                        isHasOptionAll={true}
                                                        optionAll={{ label: t('All Media'), value: '' }}
                                                    />
                                                </LabelWrapper>
                                            </Col> */}
                                            <Col sm={12} md={4} xl={2} xxl={2} className='mt-3 mt-md-2'>
                                                <LabelWrapper label={t('Auto-selection Status')} isShow={true}>
                                                    <DropdownStatus
                                                        name="status"
                                                        dataList={STATUS_AUTO_SELECTED_OPTIONS_LANG || []}
                                                        placeholder={`${t("Auto-selection Status")}...`}
                                                        className="dropdown-status-rounded"
                                                        classNamePrefix="name-prefix"
                                                        initialValue={autoSelectedSearch || null}
                                                        onChangeSelect={(e: any) => setAutoSelectedSearch(e)}
                                                        isHasOptionAll={true}
                                                        optionAll={{ label: t('All Status'), value: '' }}
                                                        colors={['danger', 'success']}
                                                    />
                                                </LabelWrapper>
                                            </Col>
                                            <Col sm={12} md={4} xl={2} xxl={2} className='mt-3 mt-md-2'>
                                                <LabelWrapper label={t('Status')} isShow={true}>
                                                    <DropdownStatus
                                                        name="status"
                                                        dataList={STATUS_OFFICIAL_MEDIA_OPTIONS_LANG || []}
                                                        placeholder={`${t("Status")}...`}
                                                        className="dropdown-status-rounded"
                                                        classNamePrefix="name-prefix"
                                                        initialValue={statusSearch || null}
                                                        onChangeSelect={(e: any) => setStatusSearch(e)}
                                                        isHasOptionAll={true}
                                                        optionAll={{ label: t('All Status'), value: '' }}
                                                        colors={['danger', 'success']}
                                                    />
                                                </LabelWrapper>
                                            </Col>
                                            <Col sm={12} md={12} xl={4} xxl={4} className="hstack gap-1 justify-content-center justify-content-sm-between justify-content-md-between mt-3 mt-md-2">
                                                <div>
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary me-2 "
                                                        onClick={searchData}
                                                        disabled={isOfficialMediaLoading}
                                                    >
                                                        <i className="ri-search-line align-bottom me-1"></i>{" "}
                                                        {t('Button Search')}
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-secondary fs-14"
                                                        onClick={resetData}
                                                    >
                                                        <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                                                        {t('Button Reset')}
                                                    </button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <CardBody className="pt-3">
                                        <TableContainer
                                            className="custom-header-css"
                                            divClass="table-responsive table-card"
                                            tableClass="align-middle table-bordered-dashed"
                                            theadClass="table-light text-muted"
                                            columns={columns}
                                            data={officialmedias?.list?.length ? officialmedias?.list : []}
                                            customPageSize={query.limit}
                                            customPageIndex={query.page - 1}
                                            totalRecords={officialmedias?.pagination?.total}
                                            customPageCount={Math.ceil(Number(officialmedias?.pagination?.total) / Number(officialmedias?.pagination?.limit))}
                                            handleChangePage={handleChangePage}
                                            manualSorting={true}
                                            sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                                            handleChangeSorting={handleChangeSorting}
                                            isLoading={isOfficialMediaLoading}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                            {((windowSize?.width >= MAX_WIDTH_SCREEN)) && (
                                <Offcanvas
                                    fade
                                    scrollable={false}
                                    direction="end"
                                    isOpen={(mediaDetail !== null)}
                                    id="offcanvasExample"
                                    className='off-canvas-lg off-canvas-lg-500'
                                >
                                    <OffcanvasHeader className="bg-light" toggle={() => setMediaDetail((_prev) => null)}>
                                        <h5 className="fs-16 mb-1">{!!mediaDetail?.id ? (!!mediaDetail?.is_preview ? t('Button Detail Official Media') : t('Button Update Official Media')) : t('Button Create Official Media')}</h5>
                                    </OffcanvasHeader>
                                    <OffcanvasBody className="color-scrollbar-os">
                                        <FormOfficialMedia detail={mediaDetail} medias={listMedia || []} categories={listCategory || []} triggerRefresh={handleRefresh} handleClose={() => setMediaDetail((_prev) => null)} />
                                    </OffcanvasBody>
                                </Offcanvas>
                            )}
                            {(windowSize?.width < MAX_WIDTH_SCREEN) && (<Modal
                                isOpen={mediaDetail !== null}
                                centered={true}
                                size="md"
                                toggle={() => setMediaDetail((_prev) => null)}
                                keyboard={true}
                            >
                                <ModalHeader toggle={() => setMediaDetail((_prev) => null)}>
                                    {!!mediaDetail?.id ? (!!mediaDetail?.is_preview ? t('Button Detail Official Media') : t('Button Update Official Media')) : t('Button Create Official Media')}
                                </ModalHeader>
                                <ModalBody className="">
                                    <FormOfficialMedia detail={mediaDetail} medias={listMedia || []} categories={listCategory || []} triggerRefresh={handleRefresh} handleClose={() => setMediaDetail((_prev) => null)} />
                                </ModalBody>
                            </Modal>)}
                        </Row>
                    )}
                    <ToastContainer closeButton={false} limit={1} />
                </Container>
            </div>
            <ModalConfirm
                header={t('Button Delete Media')}
                isOpen={idDelete !== null}
                isLoading={isConfirmLoading}
                onClose={onCloseConfirmClick}
                onConfirm={handleActionDelete}
            />
        </React.Fragment>
    );
};

export default ManageOfficialMedia;