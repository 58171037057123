import { IFrequency, IIndex } from "api/types/_master";
import { Option } from "api/types/_public";
import BreadCrumb from "components/Common/BreadCrumb";
import CopyWrapper from "components/Common/CopyWrapper";
import LabelWrapper from "components/Common/LabelWrapper";
import TableContainer from "components/Common/TableContainer";
import { useRole } from "components/Hooks/UserHooks";
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import { formatNumberWithCommas } from "helpers/format";
import { isHavePermissionRole, ROLES_FOR_APP } from "helpers/role";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CountUp from "react-countup";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Row,
} from "reactstrap";
import { createSelector } from "reselect";
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import {
  getIndexes,
  getIndexLogs as onGetIndexLogs,
} from "../../../store/thunks";
import InputsRange from "components/Common/InputsRange";
import calculatePercentages from "utils";

const SORT_BY_DEFAULT = "search_value";
const ORDER_BY_DEFAULT = "DESC";

registerLocale("en", en);
registerLocale("ko", ko);

const IndexLog = () => {
  const { t, i18n } = useTranslation();
  const { userPermissions } = useRole();
  const navigate = useNavigate();

  const YESTERDAY_DATE_DEFAULT = `${moment(
    new Date(new Date().setDate(new Date().getDate() - 1))
  ).format("Y-MM-DD")}`;

  const [query, setQuery]: any = useQueryParams({
    time_request: withDefault(NumberParam, 0),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 30),
    sort_by: withDefault(StringParam, SORT_BY_DEFAULT),
    order_by: withDefault(StringParam, ORDER_BY_DEFAULT),
    keyword: withDefault(StringParam, ""),
    date: withDefault(StringParam, YESTERDAY_DATE_DEFAULT),

    search_daily_from: withDefault(StringParam, ""),
    search_daily_to: withDefault(StringParam, ""),

    cafe_count_from: withDefault(StringParam, ""),
    cafe_count_to: withDefault(StringParam, ""),
    cafe_title_count_from: withDefault(StringParam, ""),
    cafe_title_count_to: withDefault(StringParam, ""),
    cafe_body_count_from: withDefault(StringParam, ""),
    cafe_body_count_to: withDefault(StringParam, ""),

    blog_count_from: withDefault(StringParam, ""),
    blog_count_to: withDefault(StringParam, ""),
    blog_title_count_from: withDefault(StringParam, ""),
    blog_title_count_to: withDefault(StringParam, ""),
    blog_body_count_from: withDefault(StringParam, ""),
    blog_body_count_to: withDefault(StringParam, ""),

    news_count_from: withDefault(StringParam, ""),
    news_count_to: withDefault(StringParam, ""),
    news_title_count_from: withDefault(StringParam, ""),
    news_title_count_to: withDefault(StringParam, ""),
    news_body_count_from: withDefault(StringParam, ""),
    news_body_count_to: withDefault(StringParam, ""),
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const dispatch: any = useDispatch();
  const selectLayoutState = (state: any) => state.Statistic;

  const StatisticProperties = createSelector(selectLayoutState, (state) => ({
    indexLogs: state.indexLogs,
    isIndexLogSuccess: state.isIndexLogSuccess,
    isIndexLogLoading: state.isIndexLogLoading,
    error: state.error,
  }));

  // Inside your component
  const { indexLogs, error, isIndexLogLoading } =
    useSelector(StatisticProperties);

  const [listIndex, setListIndex] = useState<IIndex[]>([]);
  const [listFrequency, setListFrequency] = useState<IFrequency[]>([]);

  const [keywordSearch, setKeywordSearch] = useState<string>(query?.name || "");

  const [indexSearch, setIndexSearch] = useState<Option | null>(null);

  const [dateSearch, setDateSearch] = useState<string>(
    query?.date || YESTERDAY_DATE_DEFAULT
  );

  // search daily from to range
  const [searchDailyFrom, setSearchDailyFromSearch] = useState<string>(
    query?.search_daily_from || ""
  );
  const [searchDailyTo, setSearchDailyToSearch] = useState<string>(
    query?.search_daily_to || ""
  );

  // cafe from to range
  const [cafeCountFrom, setCafeCountFromSearch] = useState<string>(
    query?.cafe_count_from || ""
  );
  const [cafeCountTo, setCafeCountToSearch] = useState<string>(
    query?.cafe_count_to || ""
  );
  const [cafeTitleCountFrom, setCafeTitleCountFromSearch] = useState<string>(
    query?.cafe_title_count_from || ""
  );
  const [cafeTitleCountTo, setCafeTitleCountToSearch] = useState<string>(
    query?.cafe_title_count_to || ""
  );
  const [cafeBodyCountFrom, setCafeBodyCountFromSearch] = useState<string>(
    query?.cafe_body_count_from || ""
  );
  const [cafeBodyCountTo, setCafeBodyCountToSearch] = useState<string>(
    query?.cafe_body_count_to || ""
  );

  // blog from to range
  const [blogCountFrom, setBlogCountFromSearch] = useState<string>(
    query?.blog_count_from || ""
  );
  const [blogCountTo, setBlogCountToSearch] = useState<string>(
    query?.blog_count_to || ""
  );
  const [blogTitleCountFrom, setBlogTitleCountFromSearch] = useState<string>(
    query?.blog_title_count_from || ""
  );
  const [blogTitleCountTo, setBlogTitleCountToSearch] = useState<string>(
    query?.blog_title_count_to || ""
  );
  const [blogBodyCountFrom, setBlogBodyCountFromSearch] = useState<string>(
    query?.blog_body_count_from || ""
  );
  const [blogBodyCountTo, setBlogBodyCountToSearch] = useState<string>(
    query?.blog_body_count_to || ""
  );

  // news from to range
  const [newsCountFrom, setNewsCountFromSearch] = useState<string>(
    query?.news_count_from || ""
  );
  const [newsCountTo, setNewsCountToSearch] = useState<string>(
    query?.news_count_to || ""
  );
  const [newsTitleCountFrom, setNewsTitleCountFromSearch] = useState<string>(
    query?.news_title_count_from || ""
  );
  const [newsTitleCountTo, setNewsTitleCountToSearch] = useState<string>(
    query?.news_title_count_to || ""
  );
  const [newsBodyCountFrom, setNewsBodyCountFromSearch] = useState<string>(
    query?.news_body_count_from || ""
  );
  const [newsBodyCountTo, setNewsBodyCountToSearch] = useState<string>(
    query?.blog_body_count_to || ""
  );

  useEffect(() => {
    dispatch(onGetIndexLogs(query));
  }, [dispatch, query]);

  const searchData = () => {
    setQuery({
      ...query,
      page: 1,
      keyword: keywordSearch || undefined,
      index_type: indexSearch?.value || "",
      date: dateSearch,
      search_daily_from: searchDailyFrom || "",
      search_daily_to: searchDailyTo || "",

      cafe_count_from: cafeCountFrom || "",
      cafe_count_to: cafeCountTo || "",
      cafe_title_count_from: cafeTitleCountFrom || "",
      cafe_title_count_to: cafeTitleCountTo || "",
      cafe_body_count_from: cafeBodyCountFrom || "",
      cafe_body_count_to: cafeBodyCountTo || "",

      blog_count_from: blogCountFrom || "",
      blog_count_to: blogCountTo || "",
      blog_title_count_from: blogTitleCountFrom || "",
      blog_title_count_to: blogTitleCountTo || "",
      blog_body_count_from: blogBodyCountFrom || "",
      blog_body_count_to: blogBodyCountTo || "",

      news_count_from: newsCountFrom || "",
      news_count_to: newsCountTo || "",
      news_title_count_from: newsTitleCountFrom || "",
      news_title_count_to: newsTitleCountTo || "",
      news_body_count_from: newsBodyCountFrom || "",
      news_body_count_to: newsBodyCountTo || "",

      time_request: +new Date(),
    });
  };

  const resetData = () => {
    setQuery(
      {
        keyword: "",
        index_type: "",
        frequency_types: "",
        date: YESTERDAY_DATE_DEFAULT,
        search_daily_from: "",
        search_daily_to: "",

        cafe_count_from: "",
        cafe_count_to: "",
        cafe_title_count_from: "",
        cafe_title_count_to: "",
        cafe_body_count_from: "",
        cafe_body_count_to: "",

        blog_count_from: "",
        blog_count_to: "",
        blog_title_count_from: "",
        blog_title_count_to: "",
        blog_body_count_from: "",
        blog_body_count_to: "",

        news_count_from: "",
        news_count_to: "",
        news_title_count_from: "",
        news_title_count_to: "",
        news_body_count_from: "",
        news_body_count_to: "",

        sort_by: SORT_BY_DEFAULT,
        order_by: ORDER_BY_DEFAULT,
        time_request: +new Date(),
        page: 1,
      },
      "push"
    );
    setKeywordSearch("");
    setIndexSearch(null);
    setDateSearch(YESTERDAY_DATE_DEFAULT);
    setSearchDailyFromSearch("");
    setSearchDailyToSearch("");

    setCafeCountFromSearch("");
    setCafeCountToSearch("");
    setCafeTitleCountFromSearch("");
    setCafeTitleCountToSearch("");
    setCafeBodyCountFromSearch("");
    setCafeBodyCountToSearch("");

    setBlogCountFromSearch("");
    setBlogCountToSearch("");
    setBlogTitleCountFromSearch("");
    setBlogTitleCountToSearch("");
    setBlogBodyCountFromSearch("");
    setBlogBodyCountToSearch("");

    setNewsCountFromSearch("");
    setNewsCountToSearch("");
    setNewsTitleCountFromSearch("");
    setNewsTitleCountToSearch("");
    setNewsBodyCountFromSearch("");
    setNewsBodyCountToSearch("");
  };

  function classMinOrMax(num: number, arr: number[]) {
    // if (num === Math.min(...arr)) {
    //   return 'text-primary fs-13';
    // }
    // if (num === Math.max(...arr)) {
    //   return 'text-danger fs-13';
    // }
    return "text-muted fs-13";
  }

  // Column
  const columns = useMemo(
    () => [
      // {
      //   Header: t('ID'),
      //   accessor: "id",
      //   filterable: false,
      //   sortable: true,
      //   thWidth: 80,
      //   Cell: (cell: any) => (
      //     <div style={{ width: '80px' }}>
      //       <CopyWrapper contentCopy={cell?.value} >
      //         {cell?.value}
      //       </CopyWrapper>
      //     </div>
      //   ),
      // },
      {
        Header: t("Date"),
        accessor: "date",
        filterable: false,
        sortable: false,
        thWidth: 150,
        thClass: "align-middle",
        Cell: (cell: any) => (
          <div style={{ minWidth: "130px" }}>
            <CopyWrapper contentCopy={cell?.value}>{cell?.value}</CopyWrapper>
          </div>
        ),
      },
      {
        Header: t("Keyword"),
        accessor: "keyword",
        filterable: false,
        sortable: false,
        thWidth: 150,
        thClass: "align-middle",
        Cell: (cell: any) => (
          <div style={{ minWidth: "130px" }}>
            <CopyWrapper contentCopy={cell?.value}>{cell?.value}</CopyWrapper>
          </div>
        ),
      },
      // {
      //   Header: t('Click Monthly'),
      //   accessor: "click_monthly",
      //   filterable: false,
      //   sortable: true,
      //   thWidth: i18n?.language === 'en' ? 150 : 100,
      //   thClass: 'text-end',
      //   Cell: (cell: any) => (
      //     <div className="text-end pe-3" style={{ minWidth: `${i18n?.language === 'en' ? '150px' : '100px'}` }}>
      //       {formatNumberWithCommas(cell?.value)}
      //     </div>
      //   ),
      // },
      // {
      //   Header: t('Click Monthly Mobile'),
      //   accessor: "click_monthly_mobile",
      //   filterable: false,
      //   sortable: true,
      //   thWidth: i18n?.language === 'en' ? 150 : 100,
      //   thClass: 'text-end',
      //   Cell: (cell: any) => (
      //     <div className="text-end pe-3" style={{ minWidth: `${i18n?.language === 'en' ? '150px' : '100px'}` }}>
      //       {formatNumberWithCommas(cell?.value)}
      //     </div>
      //   ),
      // },
      // {
      //   Header: t('Click Monthly PC'),
      //   accessor: "click_monthly_pc",
      //   filterable: false,
      //   sortable: true,
      //   thWidth: i18n?.language === 'en' ? 150 : 100,
      //   thClass: 'text-end',
      //   Cell: (cell: any) => (
      //     <div className="text-end pe-3" style={{ minWidth: `${i18n?.language === 'en' ? '150px' : '100px'}` }}>
      //       {formatNumberWithCommas(cell?.value)}
      //     </div>
      //   ),
      // },
      {
        Header: t("Search Daily"),
        accessor: "search_value",
        filterable: false,
        sortable: true,
        thWidth: i18n?.language === "en" ? 150 : 100,
        thClass: "text-end align-middle",
        Cell: (cell: any) => (
          <div
            className="text-end pe-3"
            style={{
              minWidth: `${i18n?.language === "en" ? "150px" : "100px"}`,
            }}
          >
            {formatNumberWithCommas(cell?.value)}
          </div>
        ),
      },
      // {
      //   Header: t('Search Monthly Mobile'),
      //   accessor: "search_monthly_mobile",
      //   filterable: false,
      //   sortable: true,
      //   thWidth: i18n?.language === 'en' ? 150 : 100,
      //   thClass: 'text-end',
      //   Cell: (cell: any) => (
      //     <div className="text-end pe-3" style={{ minWidth: `${i18n?.language === 'en' ? '150px' : '100px'}` }}>
      //       {formatNumberWithCommas(cell?.value)}
      //     </div>
      //   ),
      // },
      // {
      //   Header: t('Search Monthly PC'),
      //   accessor: "Search_monthly_pc",
      //   filterable: false,
      //   sortable: true,
      //   thWidth: i18n?.language === 'en' ? 150 : 100,
      //   thClass: 'text-end',
      //   Cell: (cell: any) => (
      //     <div className="text-end pe-3" style={{ minWidth: `${i18n?.language === 'en' ? '150px' : '100px'}` }}>
      //       {formatNumberWithCommas(cell?.value)}
      //     </div>
      //   ),
      // },
      {
        Header: "",
        accessor: "1",
        filterable: false,
        sortable: false,
        thWidth: 10,
        thClass: "text-end",
        Cell: (cell: any) => <div style={{ minWidth: "10px" }}></div>,
      },
      {
        Header: t("Naver Cafe"),
        thClass: "text-end",
        filterable: false,
        sortable: false,
        // description: t('Description PV field each platform'),
        thWidth: 300,
        thComponent: () => (
          <table>
            <thead>
              <tr>
                <th className="text-center pb-1 none-sticky" colSpan={3}>
                  {t("Naver Cafe")}
                </th>
              </tr>
              <tr>
                <th
                  className="text-center px-0 py-1 cursor-pointer"
                  style={{ width: "100px" }}
                  onClick={() => {
                    handleChangeSorting({
                      sort_by: "cafe_count",
                      order_by:
                        query?.order_by === "DESC" &&
                          query?.sort_by === "cafe_count"
                          ? "ASC"
                          : "DESC",
                    });
                  }}
                >
                  {t("Count Index")}
                  {query?.sort_by === "cafe_count" && (
                    <span
                      style={{
                        display: "inline-flex",
                        justifyContent: "center",
                        width: "12px",
                        marginLeft: "2px",
                        verticalAlign: "middle",
                      }}
                    >
                      {query?.order_by === "ASC" ? (
                        <i className={`ri-arrow-up-line fs-13 text-primary`} />
                      ) : (
                        <i
                          className={`ri-arrow-down-line fs-13 text-primary`}
                        />
                      )}
                    </span>
                  )}
                </th>
                <th
                  className="text-center px-0 py-1 cursor-pointer none-sticky"
                  style={{ width: "100px" }}
                  onClick={() => {
                    handleChangeSorting({
                      sort_by: "cafe_title_count",
                      order_by:
                        query?.order_by === "DESC" &&
                          query?.sort_by === "cafe_title_count"
                          ? "ASC"
                          : "DESC",
                    });
                  }}
                >
                  {t("Title Index")}
                  {query?.sort_by === "cafe_title_count" && (
                    <span
                      style={{
                        display: "inline-flex",
                        justifyContent: "center",
                        width: "12px",
                        marginLeft: "2px",
                        verticalAlign: "middle",
                      }}
                    >
                      {query?.order_by === "ASC" ? (
                        <i className={`ri-arrow-up-line fs-13 text-primary`} />
                      ) : (
                        <i
                          className={`ri-arrow-down-line fs-13 text-primary`}
                        />
                      )}
                    </span>
                  )}
                </th>
                <th
                  className="text-center px-0 py-1 cursor-pointer none-sticky"
                  style={{ width: "100px" }}
                  onClick={() => {
                    handleChangeSorting({
                      sort_by: "cafe_body_count",
                      order_by:
                        query?.order_by === "DESC" &&
                          query?.sort_by === "cafe_body_count"
                          ? "ASC"
                          : "DESC",
                    });
                  }}
                >
                  {t("Body Index")}
                  {query?.sort_by === "cafe_body_count" && (
                    <span
                      style={{
                        display: "inline-flex",
                        justifyContent: "center",
                        width: "12px",
                        marginLeft: "2px",
                        verticalAlign: "middle",
                      }}
                    >
                      {query?.order_by === "ASC" ? (
                        <i className={`ri-arrow-up-line fs-13 text-primary`} />
                      ) : (
                        <i
                          className={`ri-arrow-down-line fs-13 text-primary`}
                        />
                      )}
                    </span>
                  )}
                </th>
              </tr>
            </thead>
          </table>
        ),
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <div className="d-flex text-center">
              <div style={{ width: "100px" }}>
                {formatNumberWithCommas(item.cafe_count)}
              </div>
              <div
                style={{
                  width: "100px",
                  borderLeft: "1px solid #eaeaea",
                  borderRight: "1px solid #eaeaea",
                }}
              >
                {formatNumberWithCommas(item.cafe_title_count)}
              </div>
              <div style={{ width: "100px" }}>
                {formatNumberWithCommas(item.cafe_body_count)}
              </div>
            </div>
          );
        },
      },
      {
        Header: "",
        accessor: "2",
        filterable: false,
        sortable: false,
        thWidth: 10,
        thClass: "text-end",
        Cell: (cell: any) => <div style={{ minWidth: "10px" }}></div>,
      },
      {
        Header: t("Naver Blog"),
        thClass: "text-end",
        filterable: false,
        sortable: false,
        // description: t('Description PV field each platform'),
        thWidth: 300,
        thComponent: () => (
          <table>
            <thead>
              <tr>
                <th className="text-center pb-1 none-sticky" colSpan={3}>
                  {t("Naver Blog")}
                </th>
              </tr>
              <tr>
                <th
                  className="text-center px-0 py-1 cursor-pointer none-sticky"
                  style={{ width: "100px" }}
                  onClick={() => {
                    handleChangeSorting({
                      sort_by: "blog_count",
                      order_by:
                        query?.order_by === "DESC" &&
                          query?.sort_by === "blog_count"
                          ? "ASC"
                          : "DESC",
                    });
                  }}
                >
                  {t("Count Index")}
                  {query?.sort_by === "blog_count" && (
                    <span
                      style={{
                        display: "inline-flex",
                        justifyContent: "center",
                        width: "12px",
                        marginLeft: "2px",
                        verticalAlign: "middle",
                      }}
                    >
                      {query?.order_by === "ASC" ? (
                        <i className={`ri-arrow-up-line fs-13 text-primary`} />
                      ) : (
                        <i
                          className={`ri-arrow-down-line fs-13 text-primary`}
                        />
                      )}
                    </span>
                  )}
                </th>
                <th
                  className="text-center px-0 py-1 cursor-pointer none-sticky"
                  style={{ width: "100px" }}
                  onClick={() => {
                    handleChangeSorting({
                      sort_by: "blog_title_count",
                      order_by:
                        query?.order_by === "DESC" &&
                          query?.sort_by === "blog_title_count"
                          ? "ASC"
                          : "DESC",
                    });
                  }}
                >
                  {t("Title Index")}
                  {query?.sort_by === "blog_title_count" && (
                    <span
                      style={{
                        display: "inline-flex",
                        justifyContent: "center",
                        width: "12px",
                        marginLeft: "2px",
                        verticalAlign: "middle",
                      }}
                    >
                      {query?.order_by === "ASC" ? (
                        <i className={`ri-arrow-up-line fs-13 text-primary`} />
                      ) : (
                        <i
                          className={`ri-arrow-down-line fs-13 text-primary`}
                        />
                      )}
                    </span>
                  )}
                </th>
                <th
                  className="text-center px-0 py-1 cursor-pointer none-sticky"
                  style={{ width: "100px" }}
                  onClick={() => {
                    handleChangeSorting({
                      sort_by: "blog_body_count",
                      order_by:
                        query?.order_by === "DESC" &&
                          query?.sort_by === "blog_body_count"
                          ? "ASC"
                          : "DESC",
                    });
                  }}
                >
                  {t("Body Index")}
                  {query?.sort_by === "blog_body_count" && (
                    <span
                      style={{
                        display: "inline-flex",
                        justifyContent: "center",
                        width: "12px",
                        marginLeft: "2px",
                        verticalAlign: "middle",
                      }}
                    >
                      {query?.order_by === "ASC" ? (
                        <i className={`ri-arrow-up-line fs-13 text-primary`} />
                      ) : (
                        <i
                          className={`ri-arrow-down-line fs-13 text-primary`}
                        />
                      )}
                    </span>
                  )}
                </th>
              </tr>
            </thead>
          </table>
        ),
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <div className="d-flex text-center">
              <div style={{ width: "100px" }}>
                {formatNumberWithCommas(item.blog_count)}
              </div>
              <div
                style={{
                  width: "100px",
                  borderLeft: "1px solid #eaeaea",
                  borderRight: "1px solid #eaeaea",
                }}
              >
                {formatNumberWithCommas(item.blog_title_count)}
              </div>
              <div style={{ width: "100px" }}>
                {formatNumberWithCommas(item.blog_body_count)}
              </div>
            </div>
          );
        },
      },

      {
        Header: "",
        accessor: "3",
        filterable: false,
        sortable: false,
        thWidth: 10,
        thClass: "text-end",
        Cell: (cell: any) => <div style={{ minWidth: "10px" }}></div>,
      },
      {
        Header: t("Naver News"),
        thClass: "text-end",
        filterable: false,
        sortable: false,
        // description: t('Description PV field each platform'),
        thWidth: 300,
        thComponent: () => (
          <table>
            <thead>
              <tr>
                <th className="text-center pb-1 none-sticky" colSpan={3}>
                  {t("Naver News")}
                </th>
              </tr>
              <tr>
                <th
                  className="text-center px-0 py-1 cursor-pointer none-sticky"
                  style={{ width: "100px" }}
                  onClick={() => {
                    handleChangeSorting({
                      sort_by: "news_count",
                      order_by:
                        query?.order_by === "DESC" &&
                          query?.sort_by === "news_count"
                          ? "ASC"
                          : "DESC",
                    });
                  }}
                >
                  {t("Count Index")}
                  {query?.sort_by === "news_count" && (
                    <span
                      style={{
                        display: "inline-flex",
                        justifyContent: "center",
                        width: "12px",
                        marginLeft: "2px",
                        verticalAlign: "middle",
                      }}
                    >
                      {query?.order_by === "ASC" ? (
                        <i className={`ri-arrow-up-line fs-13 text-primary`} />
                      ) : (
                        <i
                          className={`ri-arrow-down-line fs-13 text-primary`}
                        />
                      )}
                    </span>
                  )}
                </th>
                <th
                  className="text-center px-0 py-1 cursor-pointer none-sticky"
                  style={{ width: "100px" }}
                  onClick={() => {
                    handleChangeSorting({
                      sort_by: "news_title_count",
                      order_by:
                        query?.order_by === "DESC" &&
                          query?.sort_by === "news_title_count"
                          ? "ASC"
                          : "DESC",
                    });
                  }}
                >
                  {t("Title Index")}
                  {query?.sort_by === "news_title_count" && (
                    <span
                      style={{
                        display: "inline-flex",
                        justifyContent: "center",
                        width: "12px",
                        marginLeft: "2px",
                        verticalAlign: "middle",
                      }}
                    >
                      {query?.order_by === "ASC" ? (
                        <i className={`ri-arrow-up-line fs-13 text-primary`} />
                      ) : (
                        <i
                          className={`ri-arrow-down-line fs-13 text-primary`}
                        />
                      )}
                    </span>
                  )}
                </th>
                <th
                  className="text-center px-0 py-1 cursor-pointer none-sticky"
                  style={{ width: "100px" }}
                  onClick={() => {
                    handleChangeSorting({
                      sort_by: "news_body_count",
                      order_by:
                        query?.order_by === "DESC" &&
                          query?.sort_by === "news_body_count"
                          ? "ASC"
                          : "DESC",
                    });
                  }}
                >
                  {t("Body Index")}
                  {query?.sort_by === "news_body_count" && (
                    <span
                      style={{
                        display: "inline-flex",
                        justifyContent: "center",
                        width: "12px",
                        marginLeft: "2px",
                        verticalAlign: "middle",
                      }}
                    >
                      {query?.order_by === "ASC" ? (
                        <i className={`ri-arrow-up-line fs-13 text-primary`} />
                      ) : (
                        <i
                          className={`ri-arrow-down-line fs-13 text-primary`}
                        />
                      )}
                    </span>
                  )}
                </th>
              </tr>
            </thead>
          </table>
        ),
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <div className="d-flex text-center">
              <div style={{ width: "100px" }}>
                {formatNumberWithCommas(item.news_count)}
              </div>
              <div
                style={{
                  width: "100px",
                  borderLeft: "1px solid #eaeaea",
                  borderRight: "1px solid #eaeaea",
                }}
              >
                {formatNumberWithCommas(item.news_title_count)}
              </div>
              <div style={{ width: "100px" }}>
                {formatNumberWithCommas(item.news_body_count)}
              </div>
            </div>
          );
        },
      },
      {
        Header: "",
        accessor: "5",
        filterable: false,
        sortable: false,
        thWidth: 10,
        thClass: "text-end",
        Cell: (cell: any) => <div style={{ minWidth: "10px" }}></div>,
      },
      {
        Header: t("Gender"),
        thClass: "text-end",
        filterable: false,
        sortable: false,
        // description: t('Description PV field each platform'),
        thWidth: 200,
        thComponent: () => (
          <table>
            <thead>
              <tr>
                <th className="text-center pb-1 none-sticky" colSpan={2}>
                  {t("Gender")}
                </th>
              </tr>
              <tr>
                <th
                  className="text-center px-0 py-1 cursor-pointer none-sticky"
                  style={{ width: "100px" }}
                  onClick={() => {
                    handleChangeSorting({
                      sort_by: "search_male_value",
                      order_by:
                        query?.order_by === "DESC" &&
                          query?.sort_by === "search_male_value"
                          ? "ASC"
                          : "DESC",
                    });
                  }}
                >
                  {t("Male")}
                  {query?.sort_by === "search_male_value" && (
                    <span
                      style={{
                        display: "inline-flex",
                        justifyContent: "center",
                        width: "12px",
                        marginLeft: "2px",
                        verticalAlign: "middle",
                      }}
                    >
                      {query?.order_by === "ASC" ? (
                        <i className={`ri-arrow-up-line fs-13 text-primary`} />
                      ) : (
                        <i
                          className={`ri-arrow-down-line fs-13 text-primary`}
                        />
                      )}
                    </span>
                  )}
                  <br />
                  <span style={{ marginLeft: "2px" }}>({t("Ratio")})</span>
                </th>
                <th
                  className="text-center px-0 py-1 cursor-pointer none-sticky"
                  style={{ width: "100px" }}
                  onClick={() => {
                    handleChangeSorting({
                      sort_by: "search_female_value",
                      order_by:
                        query?.order_by === "DESC" &&
                          query?.sort_by === "search_female_value"
                          ? "ASC"
                          : "DESC",
                    });
                  }}
                >
                  {t("Female")}
                  {query?.sort_by === "search_female_value" && (
                    <span
                      style={{
                        display: "inline-flex",
                        justifyContent: "center",
                        width: "12px",
                        marginLeft: "2px",
                        verticalAlign: "middle",
                      }}
                    >
                      {query?.order_by === "ASC" ? (
                        <i className={`ri-arrow-up-line fs-13 text-primary`} />
                      ) : (
                        <i
                          className={`ri-arrow-down-line fs-13 text-primary`}
                        />
                      )}
                    </span>
                  )}
                  <br />
                  <span style={{ marginLeft: "2px" }}>({t("Ratio")})</span>
                </th>
              </tr>
            </thead>
          </table>
        ),
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          const arr = [
            item.search_male_value || 0,
            item.search_female_value || 0,
          ];
          const arrPercent = calculatePercentages(arr);
          return (
            <div className="d-flex text-center">
              <div style={{ width: "100px" }}>
                {formatNumberWithCommas(item.search_male_value)}
                <br />
                <span className={classMinOrMax(item.search_male_value, arr)}>
                  ({arrPercent[0]} %)
                </span>
              </div>
              <div style={{ width: "100px", borderLeft: "1px solid #eaeaea" }}>
                {formatNumberWithCommas(item.search_female_value)}
                <br />
                <span className={classMinOrMax(item.search_female_value, arr)}>
                  ({arrPercent[1]} %)
                </span>
              </div>
            </div>
          );
        },
      },
      {
        Header: "",
        accessor: "6",
        filterable: false,
        sortable: false,
        thWidth: 10,
        thClass: "text-end",
        Cell: (cell: any) => <div style={{ minWidth: "10px" }}></div>,
      },
      {
        Header: t("Age"),
        thClass: "text-end",
        filterable: false,
        sortable: false,
        // description: t('Description PV field each platform'),
        thWidth: 500,
        thComponent: () => (
          <table>
            <thead>
              <tr>
                <th className="text-center pb-1 none-sticky" colSpan={5}>
                  {t("Age")}
                </th>
              </tr>
              <tr>
                <th
                  className="text-center px-0 py-1 cursor-pointer none-sticky"
                  style={{ width: "100px" }}
                  onClick={() => {
                    handleChangeSorting({
                      sort_by: "search_age_10_value",
                      order_by:
                        query?.order_by === "DESC" &&
                          query?.sort_by === "search_age_10_value"
                          ? "ASC"
                          : "DESC",
                    });
                  }}
                >
                  {t("10")}
                  {query?.sort_by === "search_age_10_value" && (
                    <span
                      style={{
                        display: "inline-flex",
                        justifyContent: "center",
                        width: "12px",
                        marginLeft: "2px",
                        verticalAlign: "middle",
                      }}
                    >
                      {query?.order_by === "ASC" ? (
                        <i className={`ri-arrow-up-line fs-13 text-primary`} />
                      ) : (
                        <i
                          className={`ri-arrow-down-line fs-13 text-primary`}
                        />
                      )}
                    </span>
                  )}
                  <br />
                  <span style={{ marginLeft: "2px" }}>({t("Ratio")})</span>
                </th>
                <th
                  className="text-center px-0 py-1 cursor-pointer none-sticky"
                  style={{ width: "100px" }}
                  onClick={() => {
                    handleChangeSorting({
                      sort_by: "search_age_20_value",
                      order_by:
                        query?.order_by === "DESC" &&
                          query?.sort_by === "search_age_20_value"
                          ? "ASC"
                          : "DESC",
                    });
                  }}
                >
                  {t("20")}
                  {query?.sort_by === "search_age_20_value" && (
                    <span
                      style={{
                        display: "inline-flex",
                        justifyContent: "center",
                        width: "12px",
                        marginLeft: "2px",
                        verticalAlign: "middle",
                      }}
                    >
                      {query?.order_by === "ASC" ? (
                        <i className={`ri-arrow-up-line fs-13 text-primary`} />
                      ) : (
                        <i
                          className={`ri-arrow-down-line fs-13 text-primary`}
                        />
                      )}
                    </span>
                  )}
                  <br />
                  <span style={{ marginLeft: "2px" }}>({t("Ratio")})</span>
                </th>
                <th
                  className="text-center px-0 py-1 cursor-pointer none-sticky"
                  style={{ width: "100px" }}
                  onClick={() => {
                    handleChangeSorting({
                      sort_by: "search_age_30_value",
                      order_by:
                        query?.order_by === "DESC" &&
                          query?.sort_by === "search_age_30_value"
                          ? "ASC"
                          : "DESC",
                    });
                  }}
                >
                  {t("30")}
                  {query?.sort_by === "search_age_30_value" && (
                    <span
                      style={{
                        display: "inline-flex",
                        justifyContent: "center",
                        width: "12px",
                        marginLeft: "2px",
                        verticalAlign: "middle",
                      }}
                    >
                      {query?.order_by === "ASC" ? (
                        <i className={`ri-arrow-up-line fs-13 text-primary`} />
                      ) : (
                        <i
                          className={`ri-arrow-down-line fs-13 text-primary`}
                        />
                      )}
                    </span>
                  )}
                  <br />
                  <span style={{ marginLeft: "2px" }}>({t("Ratio")})</span>
                </th>
                <th
                  className="text-center px-0 py-1 cursor-pointer none-sticky"
                  style={{ width: "100px" }}
                  onClick={() => {
                    handleChangeSorting({
                      sort_by: "search_age_40_value",
                      order_by:
                        query?.order_by === "DESC" &&
                          query?.sort_by === "search_age_40_value"
                          ? "ASC"
                          : "DESC",
                    });
                  }}
                >
                  {t("40")}
                  {query?.sort_by === "search_age_40_value" && (
                    <span
                      style={{
                        display: "inline-flex",
                        justifyContent: "center",
                        width: "12px",
                        marginLeft: "2px",
                        verticalAlign: "middle",
                      }}
                    >
                      {query?.order_by === "ASC" ? (
                        <i className={`ri-arrow-up-line fs-13 text-primary`} />
                      ) : (
                        <i
                          className={`ri-arrow-down-line fs-13 text-primary`}
                        />
                      )}
                    </span>
                  )}
                  <br />
                  <span style={{ marginLeft: "2px" }}>({t("Ratio")})</span>
                </th>
                <th
                  className="text-center px-0 py-1 cursor-pointer none-sticky"
                  style={{ width: "100px" }}
                  onClick={() => {
                    handleChangeSorting({
                      sort_by: "search_age_50_value",
                      order_by:
                        query?.order_by === "DESC" &&
                          query?.sort_by === "search_age_50_value"
                          ? "ASC"
                          : "DESC",
                    });
                  }}
                >
                  {t("50")}
                  {query?.sort_by === "search_age_50_value" && (
                    <span
                      style={{
                        display: "inline-flex",
                        justifyContent: "center",
                        width: "12px",
                        marginLeft: "2px",
                        verticalAlign: "middle",
                      }}
                    >
                      {query?.order_by === "ASC" ? (
                        <i className={`ri-arrow-up-line fs-13 text-primary`} />
                      ) : (
                        <i
                          className={`ri-arrow-down-line fs-13 text-primary`}
                        />
                      )}
                    </span>
                  )}
                  <br />
                  <span style={{ marginLeft: "2px" }}>({t("Ratio")})</span>
                </th>
              </tr>
            </thead>
          </table>
        ),
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          const arr = [
            item.search_age_10_value || 0,
            item.search_age_20_value || 0,
            item.search_age_30_value || 0,
            item.search_age_40_value || 0,
            item.search_age_50_value || 0,
          ];
          const arrPercent = calculatePercentages(arr);
          return (
            <div className="d-flex text-center">
              <div style={{ width: "100px" }}>
                {formatNumberWithCommas(item.search_age_10_value)}
                <br />
                <span className={classMinOrMax(item.search_age_10_value, arr)}>
                  ({arrPercent[0]} %)
                </span>
              </div>
              <div style={{ width: "100px", borderLeft: "1px solid #eaeaea" }}>
                {formatNumberWithCommas(item.search_age_20_value)}
                <br />
                <span className={classMinOrMax(item.search_age_20_value, arr)}>
                  ({arrPercent[1]} %)
                </span>
              </div>
              <div style={{ width: "100px", borderLeft: "1px solid #eaeaea" }}>
                {formatNumberWithCommas(item.search_age_30_value)}
                <br />
                <span className={classMinOrMax(item.search_age_30_value, arr)}>
                  ({arrPercent[2]} %)
                </span>
              </div>
              <div
                style={{
                  width: "100px",
                  borderLeft: "1px solid #eaeaea",
                  borderRight: "1px solid #eaeaea",
                }}
              >
                {formatNumberWithCommas(item.search_age_40_value)}
                <br />
                <span className={classMinOrMax(item.search_age_40_value, arr)}>
                  ({arrPercent[3]} %)
                </span>
              </div>
              <div style={{ width: "100px" }}>
                {formatNumberWithCommas(item.search_age_50_value)}
                <br />
                <span className={classMinOrMax(item.search_age_50_value, arr)}>
                  ({arrPercent[4]} %)
                </span>
              </div>
            </div>
          );
        },
      },
      // {
      //   Header: t('Instagram Mention Count'),
      //   accessor: "instagram_mention_count",
      //   filterable: false,
      //   sortable: false,
      //   thWidth: i18n?.language === 'en' ? 200 : 130,
      //   thClass: 'text-end align-middle',
      //   Cell: (cell: any) => (
      //     <div className="text-end" style={{ minWidth: `${i18n?.language === 'en' ? '200px' : '130px'}` }}>
      //       {formatNumberWithCommas(cell?.value)}
      //     </div>
      //   ),
      // },
      // {
      //   Header: t('Tiktok Mention Count'),
      //   accessor: "tiktok_mention_count",
      //   filterable: false,
      //   sortable: false,
      //   thWidth: i18n?.language === 'en' ? 180 : 100,
      //   thClass: 'text-end align-middle',
      //   Cell: (cell: any) => (
      //     <div className="text-end" style={{ minWidth: `${i18n?.language === 'en' ? '180px' : '100px'}` }}>
      //       {formatNumberWithCommas(cell?.value)}
      //     </div>
      //   ),
      // },

      // {
      //   Header: t('Index'),
      //   accessor: "index_type",
      //   filterable: false,
      //   sortable: false,
      //   thClass: 'text-center',
      //   Cell: (cell: any) => {
      //     const ind = listIndex?.find((ii) => (String(ii?.value) === String(cell?.value)));
      //     return (
      //       <>
      //         <div style={{ minWidth: '140px' }} className="link text-center">
      //           {i18n?.language === 'en' ? ind?.name : ind?.name_korea}
      //         </div>
      //       </>
      //     )
      //   },
      // },
      // {
      //   Header: t('Created at'),
      //   accessor: "created_at",
      //   filterable: true,
      //   sortable: false,
      //   thWidth: 120,
      //   thClass: 'text-end',
      //   Cell: (cell: any) => {
      //     const arrDate = String(cell?.value || '').split(' ');
      //     return (
      //       <div className="text-end" style={{ minWidth: '100px' }}>
      //         <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
      //       </div>
      //     )
      //   },
      // },
      // {
      //   Header: t('Updated at'),
      //   accessor: "updated_at",
      //   filterable: true,
      //   sortable: false,
      //   thWidth: 120,
      //   thClass: 'text-end',
      //   Cell: (cell: any) => {
      //     const arrDate = String(cell?.value || '').split(' ');
      //     return (
      //       <div className="text-end" style={{ minWidth: '100px' }}>
      //         <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
      //       </div>
      //     )
      //   },
      // },
    ],
    [
      i18n?.language,
      userPermissions,
      JSON.stringify(listIndex),
      JSON.stringify(query),
    ]
  );

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 });
  }, []);

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return { ..._prev, ...sortBy };
    });
  }, []);

  const handleChangePicker = (value: Date | null) => {
    setDateSearch((_prev) =>
      value ? moment(new Date(value)).format("Y-MM-DD") : ""
    );
  };

  useEffect(() => {
    const handleCallAllOption = async () => {
      try {
        const [res1, res2]: any = await Promise.all([getIndexes()]);
        setListIndex((_prev) => res1?.data || []);
        if (query?.index_types) {
          setIndexSearch((_prev) => {
            const item = res1?.data?.find(
              (item: any) =>
                String(query?.index_type || "") === String(item?.value || "")
            );
            return {
              label: i18n?.language === "en" ? item?.name : item?.name_kr,
              value: `${item?.value || ""}`,
            };
          });
        }
      } catch (error: any) {
        return error;
      }
    };
    handleCallAllOption();
  }, []);

  useEffect(() => {
    if (indexSearch) {
      const valStr = indexSearch?.value;
      setIndexSearch((_prev) => {
        const item: any = listIndex?.filter(
          (item: any) => valStr === String(item?.value || "")
        );
        return {
          label: i18n?.language === "en" ? item?.name : item?.name_kr,
          value: `${item?.value || ""}`,
        };
      });
    }

    document.title = `${t("Index Log")} - ${t("Logs")} | Rankify`;
    document.body.classList.remove("vertical-sidebar-enable");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("Index Log")} pageTitle={t("Logs")} />
          {isHavePermissionRole(
            ROLES_FOR_APP.INDEX_LOG,
            userPermissions
          ) && (
              <Row>
                <Col sm={12}>
                  <Card
                    id="customerList"
                    style={{ boxShadow: "0px 3px 4px 0px rgba(0, 0, 0, 0.03)" }}
                  >
                    <CardHeader className="border-0">
                      <Row className="g-4 align-items-center">
                        <div className="col-sm">
                          <div>
                            <h5 className="card-title mb-0">
                              <span className="me-2">{t("Total")}:</span>
                              <CountUp
                                start={0}
                                end={indexLogs?.pagination?.total || 0}
                                duration={1}
                                className="text-primary"
                              />
                            </h5>
                          </div>
                        </div>
                      </Row>
                    </CardHeader>
                    <div className=" border border-dashed border-end-0 border-start-0 card-body mt-0 pb-4">
                      <Row className="g-4 align-items-center mt-0">
                        <Col
                          sm={12}
                          md={6}
                          xl={3}
                          xxl={3}
                          className="date-picker-wrapper-custom mt-3 mt-md-2 mb-0 mb-md-2 order-1 order-md-1 order-xl-0"
                        >
                          <LabelWrapper label={t("Date")} isShow={!!dateSearch}>
                            <DatePicker
                              className="form-control search"
                              placeholderText={`${t("Date")}`}
                              value={dateSearch || undefined}
                              selected={!!dateSearch ? new Date(dateSearch) : undefined}
                              dateFormat="yyyy-MM-dd"
                              isClearable={false}
                              maxDate={new Date()}
                              locale={i18n?.language === "ko" ? "ko" : "en"}
                              onChange={handleChangePicker}
                            />
                          </LabelWrapper>
                        </Col>

                        <Col
                          sm={12}
                          md={6}
                          xl={3}
                          xxl={3}
                          className="mt-3 mt-md-2 mb-0 mb-md-2 order-4 order-md-4 order-xl-0"
                        >
                          <InputsRange
                            nameFrom="from"
                            nameTo="to"
                            valueFrom={cafeCountFrom}
                            valueTo={cafeCountTo}
                            placeholderFrom={`${t("Cafe Count (Start)")}...`}
                            placeholderTo={`${t("Cafe Count (End)")}...`}
                            labelFrom={`${t("Cafe Count (Start)")}`}
                            labelTo={`${t("Cafe Count (End)")}`}
                            isTypeNumber={true}
                            onChangeFrom={(val) => setCafeCountFromSearch(val)}
                            onChangeTo={(val) => setCafeCountToSearch(val)}
                            onKeyDownFrom={(e) => {
                              if (e.key === "Enter") {
                                searchData();
                              }
                            }}
                            onKeyDownTo={(e) => {
                              if (e.key === "Enter") {
                                searchData();
                              }
                            }}
                          />
                        </Col>
                        <Col
                          sm={12}
                          md={6}
                          xl={3}
                          xxl={3}
                          className="mt-3 mt-md-2 mb-0 mb-md-2 order-4 order-md-4 order-xl-0"
                        >
                          <InputsRange
                            nameFrom="from"
                            nameTo="to"
                            valueFrom={cafeTitleCountFrom}
                            valueTo={cafeTitleCountTo}
                            placeholderFrom={`${t("Cafe Title (Start)")}...`}
                            placeholderTo={`${t("Cafe Title (End)")}...`}
                            labelFrom={`${t("Cafe Title (Start)")}`}
                            labelTo={`${t("Cafe Title (End)")}`}
                            isTypeNumber={true}
                            onChangeFrom={(val) =>
                              setCafeTitleCountFromSearch(val)
                            }
                            onChangeTo={(val) => setCafeTitleCountToSearch(val)}
                            onKeyDownFrom={(e) => {
                              if (e.key === "Enter") {
                                searchData();
                              }
                            }}
                            onKeyDownTo={(e) => {
                              if (e.key === "Enter") {
                                searchData();
                              }
                            }}
                          />
                        </Col>
                        <Col
                          sm={12}
                          md={6}
                          xl={3}
                          xxl={3}
                          className="mt-3 mt-md-2 mb-0 mb-md-2 order-4 order-md-4 order-xl-0"
                        >
                          <InputsRange
                            nameFrom="from"
                            nameTo="to"
                            valueFrom={cafeBodyCountFrom}
                            valueTo={cafeBodyCountTo}
                            placeholderFrom={`${t("Cafe Body (Start)")}...`}
                            placeholderTo={`${t("Cafe Body (End)")}...`}
                            labelFrom={`${t("Cafe Body (Start)")}`}
                            labelTo={`${t("Cafe Body (End)")}`}
                            isTypeNumber={true}
                            onChangeFrom={(val) =>
                              setCafeBodyCountFromSearch(val)
                            }
                            onChangeTo={(val) => setCafeBodyCountToSearch(val)}
                            onKeyDownFrom={(e) => {
                              if (e.key === "Enter") {
                                searchData();
                              }
                            }}
                            onKeyDownTo={(e) => {
                              if (e.key === "Enter") {
                                searchData();
                              }
                            }}
                          />
                        </Col>

                        <Col
                          sm={12}
                          md={6}
                          xl={3}
                          xxl={3}
                          className="mt-3 mt-md-2 mb-0 mb-md-2 order-2 order-md-2 order-xl-0"
                        >
                          <LabelWrapper
                            label={t("Keyword")}
                            isShow={!!keywordSearch}
                          >
                            <Input
                              type="text"
                              className="form-control search"
                              placeholder={`${t("Keyword")}...`}
                              value={keywordSearch}
                              onChange={(e) => setKeywordSearch(e.target.value)}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  searchData();
                                }
                              }}
                            />
                          </LabelWrapper>
                        </Col>

                        <Col
                          sm={12}
                          md={6}
                          xl={3}
                          xxl={3}
                          className="mt-3 mt-md-2 mb-0 mb-md-2 order-4 order-md-4 order-xl-0"
                        >
                          <InputsRange
                            nameFrom="from"
                            nameTo="to"
                            valueFrom={blogCountFrom}
                            valueTo={blogCountTo}
                            placeholderFrom={`${t("Blog Count (Start)")}...`}
                            placeholderTo={`${t("Blog Count (End)")}...`}
                            labelFrom={`${t("Blog Count (Start)")}`}
                            labelTo={`${t("Blog Count (End)")}`}
                            isTypeNumber={true}
                            onChangeFrom={(val) => setBlogCountFromSearch(val)}
                            onChangeTo={(val) => setBlogCountToSearch(val)}
                            onKeyDownFrom={(e) => {
                              if (e.key === "Enter") {
                                searchData();
                              }
                            }}
                            onKeyDownTo={(e) => {
                              if (e.key === "Enter") {
                                searchData();
                              }
                            }}
                          />
                        </Col>
                        <Col
                          sm={12}
                          md={6}
                          xl={3}
                          xxl={3}
                          className="mt-3 mt-md-2 mb-0 mb-md-2 order-4 order-md-4 order-xl-0"
                        >
                          <InputsRange
                            nameFrom="from"
                            nameTo="to"
                            valueFrom={blogTitleCountFrom}
                            valueTo={blogTitleCountTo}
                            placeholderFrom={`${t("Blog Title (Start)")}...`}
                            placeholderTo={`${t("Blog Title (End)")}...`}
                            labelFrom={`${t("Blog Title (Start)")}`}
                            labelTo={`${t("Blog Title (End)")}`}
                            isTypeNumber={true}
                            onChangeFrom={(val) =>
                              setBlogTitleCountFromSearch(val)
                            }
                            onChangeTo={(val) => setBlogTitleCountToSearch(val)}
                            onKeyDownFrom={(e) => {
                              if (e.key === "Enter") {
                                searchData();
                              }
                            }}
                            onKeyDownTo={(e) => {
                              if (e.key === "Enter") {
                                searchData();
                              }
                            }}
                          />
                        </Col>
                        <Col
                          sm={12}
                          md={6}
                          xl={3}
                          xxl={3}
                          className="mt-3 mt-md-2 mb-0 mb-md-2 order-4 order-md-4 order-xl-0"
                        >
                          <InputsRange
                            nameFrom="from"
                            nameTo="to"
                            valueFrom={blogBodyCountFrom}
                            valueTo={blogBodyCountTo}
                            placeholderFrom={`${t("Blog Body (Start)")}...`}
                            placeholderTo={`${t("Blog Body (End)")}...`}
                            labelFrom={`${t("Blog Body (Start)")}`}
                            labelTo={`${t("Blog Body (End)")}`}
                            isTypeNumber={true}
                            onChangeFrom={(val) =>
                              setBlogBodyCountFromSearch(val)
                            }
                            onChangeTo={(val) => setBlogBodyCountToSearch(val)}
                            onKeyDownFrom={(e) => {
                              if (e.key === "Enter") {
                                searchData();
                              }
                            }}
                            onKeyDownTo={(e) => {
                              if (e.key === "Enter") {
                                searchData();
                              }
                            }}
                          />
                        </Col>

                        <Col
                          sm={12}
                          md={6}
                          xl={3}
                          xxl={3}
                          className="mt-3 mt-md-2 mb-0 mb-md-2 order-3 order-md-3 order-xl-0"
                        >
                          <InputsRange
                            nameFrom="from"
                            nameTo="to"
                            valueFrom={searchDailyFrom}
                            valueTo={searchDailyTo}
                            placeholderFrom={`${t("Search Daily (Start)")}...`}
                            placeholderTo={`${t("Search Daily (End)")}...`}
                            labelFrom={`${t("Search Daily (Start)")}`}
                            labelTo={`${t("Search Daily (End)")}`}
                            isTypeNumber={true}
                            onChangeFrom={(val) => setSearchDailyFromSearch(val)}
                            onChangeTo={(val) => setSearchDailyToSearch(val)}
                            onKeyDownFrom={(e) => {
                              if (e.key === "Enter") {
                                searchData();
                              }
                            }}
                            onKeyDownTo={(e) => {
                              if (e.key === "Enter") {
                                searchData();
                              }
                            }}
                          />
                        </Col>

                        <Col
                          sm={12}
                          md={6}
                          xl={3}
                          xxl={3}
                          className="mt-3 mt-md-2 mb-0 mb-md-2 order-4 order-md-4 order-xl-0"
                        >
                          <InputsRange
                            nameFrom="from"
                            nameTo="to"
                            valueFrom={newsCountFrom}
                            valueTo={newsCountTo}
                            placeholderFrom={`${t("News Count (Start)")}...`}
                            placeholderTo={`${t("News Count (End)")}...`}
                            labelFrom={`${t("News Count (Start)")}`}
                            labelTo={`${t("News Count (End)")}`}
                            isTypeNumber={true}
                            onChangeFrom={(val) => setNewsCountFromSearch(val)}
                            onChangeTo={(val) => setNewsCountToSearch(val)}
                            onKeyDownFrom={(e) => {
                              if (e.key === "Enter") {
                                searchData();
                              }
                            }}
                            onKeyDownTo={(e) => {
                              if (e.key === "Enter") {
                                searchData();
                              }
                            }}
                          />
                        </Col>
                        <Col
                          sm={12}
                          md={6}
                          xl={3}
                          xxl={3}
                          className="mt-3 mt-md-2 mb-0 mb-md-2 order-4  order-md-4 order-xl-0"
                        >
                          <InputsRange
                            nameFrom="from"
                            nameTo="to"
                            valueFrom={newsTitleCountFrom}
                            valueTo={newsTitleCountTo}
                            placeholderFrom={`${t("News Title (Start)")}...`}
                            placeholderTo={`${t("News Title (End)")}...`}
                            labelFrom={`${t("News Title (Start)")}`}
                            labelTo={`${t("News Title (End)")}`}
                            isTypeNumber={true}
                            onChangeFrom={(val) =>
                              setNewsTitleCountFromSearch(val)
                            }
                            onChangeTo={(val) => setNewsTitleCountToSearch(val)}
                            onKeyDownFrom={(e) => {
                              if (e.key === "Enter") {
                                searchData();
                              }
                            }}
                            onKeyDownTo={(e) => {
                              if (e.key === "Enter") {
                                searchData();
                              }
                            }}
                          />
                        </Col>
                        <Col
                          sm={12}
                          md={6}
                          xl={3}
                          xxl={3}
                          className="mt-3 mt-md-2 mb-0 mb-md-2 order-4 order-md-4  order-xl-0"
                        >
                          <InputsRange
                            nameFrom="from"
                            nameTo="to"
                            valueFrom={newsBodyCountFrom}
                            valueTo={newsBodyCountTo}
                            placeholderFrom={`${t("News Body (Start)")}...`}
                            placeholderTo={`${t("News Body (End)")}...`}
                            labelFrom={`${t("News Body (Start)")}`}
                            labelTo={`${t("News Body (End)")}`}
                            isTypeNumber={true}
                            onChangeFrom={(val) =>
                              setNewsBodyCountFromSearch(val)
                            }
                            onChangeTo={(val) => setNewsBodyCountToSearch(val)}
                            onKeyDownFrom={(e) => {
                              if (e.key === "Enter") {
                                searchData();
                              }
                            }}
                            onKeyDownTo={(e) => {
                              if (e.key === "Enter") {
                                searchData();
                              }
                            }}
                          />
                        </Col>

                        <Col
                          sm={12}
                          md={12}
                          xl={12}
                          xxl={12}
                          className="hstack gap-1 justify-content-center justify-content-md-end order-md-5 order-xl-0 mt-3 mt-md-2"
                        >
                          <div>
                            <button
                              type="button"
                              className="btn btn-primary me-2 "
                              onClick={searchData}
                              disabled={isIndexLogLoading}
                            >
                              <i className="ri-search-line align-bottom me-1"></i>{" "}
                              {t("Button Search")}
                            </button>
                            <button
                              type="button"
                              className="btn btn-secondary fs-14"
                              onClick={resetData}
                            >
                              <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                              {t("Button Reset")}
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <CardBody className="pt-3">
                      <TableContainer
                        className="custom-header-css"
                        divClass="table-responsive table-card"
                        tableClass="align-middle table-bordered-dashed sticky-table-index-log"
                        theadClass="table-light text-muted"
                        columns={columns}
                        data={indexLogs?.list?.length ? indexLogs?.list : []}
                        customPageSize={query.limit}
                        customPageIndex={query.page - 1}
                        totalRecords={indexLogs?.pagination?.total}
                        customPageCount={Math.ceil(
                          Number(indexLogs?.pagination?.total) /
                          Number(indexLogs?.pagination?.limit)
                        )}
                        handleChangePage={handleChangePage}
                        manualSorting={true}
                        sorting={{
                          sort_by: query.sort_by,
                          order_by: query.order_by,
                        }}
                        handleChangeSorting={handleChangeSorting}
                        isLoading={isIndexLogLoading}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}
          <ToastContainer closeButton={false} limit={1} />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default IndexLog;
