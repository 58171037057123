import { KEYWORD_SOURCE_TYPE } from "helpers/constans";
import React from "react";
import { useTranslation } from "react-i18next";
import {
    Modal,
    ModalBody,
    ModalHeader
} from "reactstrap";
import InfoByType from "./InfoByType";

const ModalDetailKeyword = ({ isShow = false, keyword = {}, version, onClose }: any) => {
    const { t, i18n } = useTranslation();

    const onHide = () => {
        onClose && onClose();
    };

    const keyword_info: any = { ...keyword?.info, image_url: keyword?.image_url || keyword?.info?.image_url, ...(keyword?.info?.name ? {} : { name: keyword?.keyword }) };
    const keyword_type = keyword?.type;
    const info_extract: any = keyword?.info_extract;

    return (
        <>
            <Modal
                isOpen={!!isShow}
                centered={true}
                scrollable={false}
                toggle={onHide}
                size="lg"
            >
                <ModalHeader toggle={onHide}>
                    {t('Keyword Description')}
                </ModalHeader>
                <ModalBody className="pt-3" style={{ minHeight: "450px" }}>
                    <InfoByType info={{ id: keyword?.id, keyword_search_raw: keyword?.keyword_search_raw, ...keyword_info, ...info_extract }} source_type={keyword_type} version={version} />
                </ModalBody>
            </Modal>
        </>
    )
}

export default React.memo(ModalDetailKeyword);