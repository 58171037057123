import React, { useRef } from "react";
import { UncontrolledTooltip } from 'reactstrap';

const TooltipHtmlCustom = ({
    html_dom = <></>,
    id = '',
    children,
    className = '',
    style = {}
}: any) => {
    const ref = useRef(null);

    return (
        <React.Fragment>
            <div ref={ref} className={className} style={style}>
                {children}
            </div>
            <UncontrolledTooltip
                target={ref}
                placement='left'
                fade={false}
                style={{
                    backgroundColor: '#eaeaea',
                    opacity: 1,  // Ensure the tooltip is fully opaque
                    color: '#000', // Text color for readability
                    border: '1px solid #ccc', // Optional border for visibility
                    padding: '10px', // Optional padding for appearance
                    fontSize: '14px', // Adjust the font size as needed
                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Subtle shadow for contrast
                    pointerEvents: 'auto', // Allow interactions with the tooltip,
                    zIndex: 99,
                }}
                className="custom-tooltip"
            >
                {html_dom}
            </UncontrolledTooltip>
        </React.Fragment>
    );
};

export default TooltipHtmlCustom;
