
import { KEYWORD_SOURCE_TYPE } from "helpers/constans";
import React from "react";
import Actor from "./Actor";
import BallPlayer from "./BallPlayer";
import BallPlayerVersion2 from "./BallPlayerVersion2";
import BoardCaster from "./BoardCaster";
import BrandCar from "./BrandCar";
import Game from "./Game";
import GameVersion2 from "./GameVersion2";
import GolfPlayer from "./GolfPlayer";
import Instagram from "./Instagram";
import LOL from "./LOL";
import ModelCar from "./ModelCar";
import NaverBlog from "./NaverBlog";
import Singer from "./Singer";
import SoccerPlayer from "./SoccerPlayer";
import Youtuber from "./Youtuber";
import SoccerPlayerVersion2 from "./SoccerPlayerVersion2";
import BasketBallPlayer from "./BasketBallPlayer";
import VolleyBallPlayer from "./VolleyBallPlayer";

const InfoByType = ({ info = null, source_type = 0, version = 1 }: any) => {

    const renderContent = (keyword_info: any) => {
        switch (source_type) {
            case KEYWORD_SOURCE_TYPE.ACTOR:
                return <Actor keyword_info={keyword_info} />;
            case KEYWORD_SOURCE_TYPE.BASE_BALL_PLAYER:
                return (version > 1) ? <BallPlayerVersion2 keyword_info={keyword_info} /> : <BallPlayer keyword_info={keyword_info} />;
            case KEYWORD_SOURCE_TYPE.GOLF_PLAYER:
                return <GolfPlayer keyword_info={keyword_info} />;
            case KEYWORD_SOURCE_TYPE.SINGER:
                return <Singer keyword_info={keyword_info} />;
            case KEYWORD_SOURCE_TYPE.SOCCER_PLAYER:
                return (version > 1) ? <SoccerPlayerVersion2 keyword_info={keyword_info} /> : <SoccerPlayer keyword_info={keyword_info} />;
            case KEYWORD_SOURCE_TYPE.YOUTUBER:
                return <Youtuber keyword_info={keyword_info} />;
            case KEYWORD_SOURCE_TYPE.GAME:
                return (version > 1) ? <GameVersion2 keyword_info={keyword_info} /> : <Game keyword_info={keyword_info} />;
            case KEYWORD_SOURCE_TYPE.LOL_CHAMPION:
                return <LOL keyword_info={keyword_info} />;
            case KEYWORD_SOURCE_TYPE.BOARD_CASTER:
                return <BoardCaster keyword_info={keyword_info} />;
            case KEYWORD_SOURCE_TYPE.INSTAGRAM:
                return <Instagram keyword_info={keyword_info} />;
            case KEYWORD_SOURCE_TYPE.NAVER_BLOG:
                return <NaverBlog keyword_info={keyword_info} />;
            case KEYWORD_SOURCE_TYPE.BRAND_CAR:
                return <BrandCar keyword_info={keyword_info} />;
            case KEYWORD_SOURCE_TYPE.MODEL_CAR:
                return <ModelCar keyword_info={keyword_info} />;
            case KEYWORD_SOURCE_TYPE.BASKETBALL_PLAYER:
                return <BasketBallPlayer keyword_info={keyword_info} />;
            case KEYWORD_SOURCE_TYPE.VOLLEYBALL_PLAYER:
                return <VolleyBallPlayer keyword_info={keyword_info} />;
            default:
                return <></>;
        }
    };

    return (
        <>
            <div>{renderContent(info)}</div>
        </>
    )
}

export default React.memo(InfoByType);