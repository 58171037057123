import { ICategory } from 'api/types/_category';
import { IIndex } from 'api/types/_master';
import { ITrendIndexConfig } from 'api/types/_trendindex';
import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_NORMAL } from 'common/toast';
import BreadCrumb from 'components/Common/BreadCrumb';
import CopyWrapper from 'components/Common/CopyWrapper';
import LabelWrapper from 'components/Common/LabelWrapper';
import ModalConfirm from 'components/Common/ModalConfirm';
import TableContainer from 'components/Common/TableContainer';
import TooltipCustom from 'components/Common/TooltipCustom';
import { useRole } from 'components/Hooks/UserHooks';
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import { STATUS_TREND_INDEX_OPTIONS } from 'helpers/constans';
import { isHavePermissionRole, ROLES_FOR_APP } from 'helpers/role';
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CountUp from 'react-countup';
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import 'react-quill/dist/quill.snow.css';
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, CardHeader, Col, Container, Input, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params";
import { deleteTrendIndex, getFrequencies, getIndexes, getTrendIndexConfigs as onGetTrendIndexConfigs } from "../../../store/thunks";
import Form from './Form';

const SORT_BY_DEFAULT = 'id';
const ORDER_BY_DEFAULT = 'ASC';

registerLocale("en", en);
registerLocale("ko", ko);

const TrendIndexSetting = () => {
  const { t, i18n } = useTranslation();
  const { userPermissions } = useRole();
  const navigate = useNavigate();

  const STATUS_TREND_INDEX_OPTIONS_LANG = STATUS_TREND_INDEX_OPTIONS?.map(
    (item: any) => ({ value: item?.value, label: t(item?.label) })
  );

  const [query, setQuery]: any = useQueryParams({
    time_request: withDefault(NumberParam, 0),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 30),
    sort_by: withDefault(StringParam, SORT_BY_DEFAULT),
    order_by: withDefault(StringParam, ORDER_BY_DEFAULT),
    name: withDefault(StringParam, ''),
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const dispatch: any = useDispatch();
  const selectLayoutState = (state: any) => state.TrendIndex;

  const TrendIndexProperties = createSelector(
    selectLayoutState,
    (state) => ({
      trendIndexConfigs: state.trendIndexConfigs,
      isTrendIndexConfigSuccess: state.isTrendIndexConfigSuccess,
      isTrendIndexConfigLoading: state.isTrendIndexConfigLoading,
      error: state.error,
    })
  );

  // Inside your component
  const { trendIndexConfigs, error, isTrendIndexConfigLoading } = useSelector(TrendIndexProperties);

  const [listIndex, setListIndex] = useState<IIndex[]>([]);

  const [nameSearch, setNameSearch] = useState<string>(query?.name || "");

  const [detail, setDetail] = useState<ITrendIndexConfig | null>(null);

  const [idDelete, setIdDelete] = useState<number | null>(null);
  const [isConfirmLoading, setIsConfirmLoading] = useState<boolean>(false);

  useEffect(() => {
    dispatch(onGetTrendIndexConfigs(query));
  }, [JSON.stringify(query)]);

  const searchData = () => {
    setQuery({
      ...query,
      page: 1,
      name: nameSearch || '',
      time_request: + new Date()
    });
  };

  const resetData = () => {
    setQuery({
      ...query,
      name: '',
      sort_by: SORT_BY_DEFAULT,
      order_by: ORDER_BY_DEFAULT,
      time_request: + new Date(),
      page: 1,
    }, "push");
    setNameSearch("");
  };

  // Column
  const columns = useMemo(
    () => [
      {
        Header: t('Index'),
        accessor: "name",
        filterable: false,
        sortable: false,
        thClass: 'text-start',
        thWidth: 150,
        Cell: (cell: any) => (
          <div style={{ minWidth: '130px' }} className="text-start">
            <CopyWrapper contentCopy={cell?.value} >
              {cell?.value}
            </CopyWrapper>
          </div>
        ),
      },
      // {
      //   Header: t('Index'),
      //   accessor: "index_type",
      //   filterable: false,
      //   sortable: false,
      //   thClass: 'text-center',
      //   Cell: (cell: any) => {
      //     const ind = listIndex?.find((ii) => (String(ii?.value) === String(cell?.value)));
      //     return (
      //       <>
      //         <div style={{ minWidth: '140px' }} className="link text-center">
      //           {i18n?.language === 'en' ? ind?.name : ind?.name_korea}
      //         </div>
      //       </>
      //     )
      //   },
      // },
      {
        Header: t('Base Weight'),
        accessor: "ratio",
        filterable: false,
        sortable: false,
        thClass: 'text-center',
        Cell: (cell: any) => (
          <div style={{ minWidth: '80px' }} className="text-center">
            {cell?.value} %
          </div>
        ),
      },
      {
        Header: t('Description'),
        accessor: "description",
        filterable: false,
        sortable: false,
        thClass: 'text-start',
        Cell: (cell: any) => (
          <div style={{ minWidth: '130px' }} className="text-start">
            {cell?.value}
          </div>
        ),
      },
      {
        Header: t('Created at'),
        accessor: "created_at",
        filterable: true,
        sortable: false,
        thClass: 'text-end',
        thWidth: 150,
        Cell: (cell: any) => {
          const arrDate = String(cell?.value || '').split(' ');
          return (
            <div className="text-end" style={{ minWidth: '100px' }}>
              <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
            </div>
          )
        },
      },
      {
        Header: t('Updated at'),
        accessor: "updated_at",
        filterable: true,
        sortable: false,
        thClass: 'text-end',
        thWidth: 150,
        Cell: (cell: any) => {
          const arrDate = String(cell?.value || '').split(' ');
          return (
            <div className="text-end" style={{ minWidth: '100px' }}>
              <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
            </div>
          )
        },
      }, {
        Header: t('Action'),
        accessor: "",
        thClass: 'text-center',
        thWidth: 100,
        thComponent: () => (
          isHavePermissionRole(ROLES_FOR_APP.TREND_INDEX_UPDATE, userPermissions) ? t('Action') : <></>
        ),
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <div className="text-center" style={{ minWidth: '80px' }}>
              <ul className="list-inline justify-content-center hstack gap-2 mb-0">
                {isHavePermissionRole(ROLES_FOR_APP.TREND_INDEX_UPDATE, userPermissions) && (<TooltipCustom
                  title={t('Button Update')}
                  id={`update-cp-${item?.id}`}
                >
                  <li className="list-inline-item edit " title="Edit">
                    <Link className="btn btn-sm btn-soft-primary d-inline-block edit-item-btn" to="#"
                      onClick={(e) => {
                        e.preventDefault();
                        setDetail((_prev) => item);
                      }}>
                      <i className="ri-pencil-fill"></i>
                    </Link>
                  </li>
                </TooltipCustom>)}
              </ul>
            </div>
          );
        },
      }
    ],
    [i18n?.language, userPermissions, JSON.stringify(listIndex), JSON.stringify(query)]
  );

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 })
  }, []);

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return ({ ..._prev, ...sortBy });
    });
  }, []);

  // Begin::Delete
  const handleConfirmDelete = (item: ICategory) => {
    setIdDelete((_prev) => item?.id);
  };

  const handleActionDelete = async () => {
    if (!idDelete) { return; };
    try {
      setIsConfirmLoading((_prev) => true);
      const response: any = await deleteTrendIndex(idDelete);
      if (response?.code === 200) {
        dispatch(onGetTrendIndexConfigs(query));
        setIsConfirmLoading((_prev) => false);
        setIdDelete((_prev) => null);
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
      } else {
        setIsConfirmLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsConfirmLoading((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const onCloseConfirmClick = () => {
    setIdDelete((_prev) => null);
  }
  // End::Delete

  const handleRefresh = () => {
    setDetail((_prev) => null);
    resetData();
  }

  useEffect(() => {
    const handleCallAllOption = async () => {
      try {
        const [res1, res2]: any = await Promise.all([getIndexes(), getFrequencies()]);
        setListIndex((_prev) => res1?.data || []);
      } catch (error: any) {
        return error;
      }
    };
    handleCallAllOption();
  }, []);


  useEffect(() => {
    document.title = `${t('Trend Index Settings')} - ${t('Trend Index Management')} | Rankify`;
    document.body.classList.remove('vertical-sidebar-enable');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  const sumWeight = trendIndexConfigs?.list?.reduce((sum: number, item: any) => {
    return (sum + Number(item?.ratio));
  }, 0);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t('Trend Index Settings')} pageTitle={t('Trend Index Management')} />
          {isHavePermissionRole(ROLES_FOR_APP.TREND_INDEX_MANAGEMENT, userPermissions) && (
            <Row>
              <Col sm={12}>
                <Card id="customerList" style={{ boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)' }} className="ribbon-box right">
                  {(trendIndexConfigs?.list?.length > 0 && sumWeight !== 100) && <div className="ribbon round-shape" style={{ background: 'rgb(248 40 90)', fontWeight: 300, fontSize: '0.8rem', top: '11px', maxWidth: '65dvw' }}>{t('The sum of the weights for the selected indices must be 100%')}</div>}
                  <CardHeader className="border-0">
                    <Row className="g-4 align-items-center">
                      <div className="col-sm">
                        <div>
                          <h5 className="card-title mb-0">
                            <span className="me-2">{t('Total')}:</span>
                            <CountUp
                              start={0}
                              end={trendIndexConfigs?.pagination?.total || 0}
                              duration={1}
                              className="text-primary"
                            />
                          </h5>
                        </div>
                      </div>
                    </Row>
                  </CardHeader>
                  <div className=" border border-dashed border-end-0 border-start-0 card-body mt-0 pb-4">
                    <Row className="g-4 align-items-center mt-0">
                      <Col sm={12} md={4} xl={3} xxl={2} className='mt-3 mt-md-2'>
                        <LabelWrapper label={t('Index')} isShow={!!nameSearch}>
                          <Input
                            type="text"
                            className="form-control search"
                            placeholder={`${t('Index')}...`}
                            value={nameSearch}
                            onChange={(e) => setNameSearch(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                searchData();
                              }
                            }}
                          />
                        </LabelWrapper>
                      </Col>
                      <Col sm={12} md={8} xl={9} xxl={10} className="hstack gap-1 justify-content-center justify-content-md-between mt-3 mt-md-2">
                        <div>
                          <button
                            type="button"
                            className="btn btn-primary me-2 "
                            onClick={searchData}
                            disabled={isTrendIndexConfigLoading}
                          >
                            <i className="ri-search-line align-bottom me-1"></i>{" "}
                            {t('Button Search')}
                          </button>
                          <button
                            type="button"
                            className="btn btn-secondary fs-14"
                            onClick={resetData}
                          >
                            <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                            {t('Button Reset')}
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-3">
                    <TableContainer
                      className="custom-header-css"
                      divClass="table-responsive table-card"
                      tableClass="align-middle table-bordered-dashed"
                      theadClass="table-light text-muted"
                      columns={columns}
                      data={trendIndexConfigs?.list?.length ? trendIndexConfigs?.list : []}
                      customPageSize={query.limit}
                      customPageIndex={query.page - 1}
                      totalRecords={trendIndexConfigs?.pagination?.total}
                      customPageCount={Math.ceil(Number(trendIndexConfigs?.pagination?.total) / Number(trendIndexConfigs?.pagination?.limit))}
                      handleChangePage={handleChangePage}
                      manualSorting={true}
                      sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                      handleChangeSorting={handleChangeSorting}
                      isLoading={isTrendIndexConfigLoading}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
          <ToastContainer closeButton={false} limit={1} />
        </Container>
        <Modal
          isOpen={detail !== null} centered={true} size="md"
        >
          <ModalHeader toggle={() => setDetail((_prev) => null)}>
            {!!detail?.id ? (detail?.is_preview ? t('Button Detail') : t('Button Update')) : t('Button Create')}
          </ModalHeader>
          <ModalBody className="pt-4">
            <Form detail={detail} indexType={Number(detail?.index_type)} triggerRefresh={handleRefresh} handleClose={() => setDetail((_prev) => null)} />
          </ModalBody>
        </Modal>
        <ModalConfirm
          header={t('Button Delete')}
          isOpen={idDelete !== null}
          isLoading={isConfirmLoading}
          onClose={onCloseConfirmClick}
          onConfirm={handleActionDelete}
        />
      </div>
    </React.Fragment >
  );
};

export default TrendIndexSetting;