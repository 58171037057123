import TableContainer from "components/Common/TableContainer";
import { useRole } from "components/Hooks/UserHooks";
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import { isHavePermissionRole, ROLES_FOR_APP } from "helpers/role";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CountUp from "react-countup";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { createSelector } from "reselect";
import { getKeywordTrendNews as onGetKeywordTrendNews } from "../../../store/thunks";


registerLocale("en", en);
registerLocale("ko", ko);


const ModalNewsOfKeywordTrend = ({ keywordTrend }: any) => {
    const { t, i18n } = useTranslation();
    const { userPermissions } = useRole();
    const navigate = useNavigate();

    const [query, setQuery]: any = useState({
        id: keywordTrend?.keyword_id
    });

    const dispatch: any = useDispatch();
    const selectLayoutState = (state: any) => state.Keyword;

    const KeywordProperties = createSelector(selectLayoutState, (state) => ({
        keywordTrendNews: state.keywordTrendNews,
        isKeywordTrendNewsSuccess: state.isKeywordTrendNewsSuccess,
        isKeywordTrendNewsLoading: state.isKeywordTrendNewsLoading,
        error: state.error,
    }));

    // Inside your component
    const { keywordTrendNews, error, isKeywordTrendNewsLoading } = useSelector(KeywordProperties);

    useEffect(() => {
        dispatch(onGetKeywordTrendNews(query));
    }, [dispatch, query]);

    // Column
    const columns = useMemo(
        () => [
            {
                Header: t(''),
                accessor: "stt",
                filterable: false,
                sortable: false,
                thWidth: 70,
                thClass: 'align-middle text-start',
                Cell: (cell: any) => {
                    return (<>
                        <div className="text-start" style={{ minWidth: '50px' }}>{(cell?.cell?.row?.index + 1)}</div>
                    </>)
                },
            },
            {
                Header: t("Keyword"),
                accessor: "keyword",
                filterable: false,
                sortable: false,
                thWidth: 100,
                thClass: "align-middle",
                Cell: (cell: any) => {
                    const item: any = cell?.row?.original;
                    return (
                        <div style={{ minWidth: "100px" }}>
                            {item?.keyword || ''}
                        </div>
                    );
                },
            },
            {
                Header: t("Date"),
                accessor: "date",
                filterable: false,
                sortable: false,
                thWidth: 90,
                thClass: "align-middle",
                Cell: (cell: any) => {
                    const item: any = cell?.row?.original;
                    return (
                        <div style={{ minWidth: "90px" }}>
                            {item?.date || ''}
                        </div>
                    );
                },
            },
            {
                Header: t('Article Title'),
                accessor: "title",
                filterable: false,
                sortable: false,
                thWidth: 200,
                thClass: 'text-start align-middle',
                Cell: (cell: any) => {
                    const item: any = cell?.row?.original;
                    return (
                        <div className="text-start" style={{ minWidth: '200px', maxHeight: '200px', overflowY: 'auto' }}>
                            <Link target="_blank" className="text-primary text-decoration-underline" to={item?.url || '-'}>
                                {item?.url}
                            </Link>
                            <br />
                            <div className="mt-2">
                                <Link target="_blank" className="text-dark" to={item?.url || '-'}>
                                    {item?.title}
                                </Link>
                            </div>
                        </div>
                    )
                },
            },
            {
                Header: t("Article Content"),
                accessor: "body",
                filterable: false,
                sortable: false,
                thClass: "text-start align-middle",
                Cell: (cell: any) => (
                    <div className="text-start" style={{ minWidth: '200px', maxWidth: '1200px', maxHeight: '200px', overflowY: 'auto' }}>
                        {cell?.value}
                    </div>
                ),
            },
        ],
        [
            i18n?.language,
            userPermissions,
            JSON.stringify(query),
        ]
    );

    const handleChangePage = useCallback((page: any) => {
        setQuery((_prev: any) => {
            return { ..._prev, page: page + 1 };
        });
    }, []);

    const handleChangeSorting = useCallback((sortBy: any) => {
        setQuery((_prev: any) => {
            return { ..._prev, ...sortBy };
        });
    }, []);

    return (
        <React.Fragment>
            <div>
                <div>
                    {isHavePermissionRole(
                        ROLES_FOR_APP.RANKING_MONITORING,
                        userPermissions
                    ) && (
                            <Row>
                                <Col sm={12}>
                                    <Card
                                        id="customerList"
                                        style={{ boxShadow: "0px 3px 4px 0px rgba(0, 0, 0, 0.03)" }}
                                    >
                                        <CardHeader className="border-0">
                                            <Row className="g-4 align-items-center">
                                                <div className="col-sm">
                                                    <div>
                                                        <h5 className="card-title mb-0">
                                                            <span className="me-2">{t("Total")}:</span>
                                                            <CountUp
                                                                start={0}
                                                                end={isKeywordTrendNewsLoading ? 0 : (keywordTrendNews?.length || 0)}
                                                                duration={1}
                                                                className="text-primary"
                                                            />
                                                        </h5>
                                                    </div>
                                                </div>
                                            </Row>
                                        </CardHeader>
                                        <CardBody className="pt-3">
                                            <TableContainer
                                                className="custom-header-css"
                                                divClass="table-responsive table-card"
                                                tableClass="align-middle table-bordered-dashed"
                                                theadClass="table-light text-muted"
                                                columns={columns}
                                                data={keywordTrendNews?.length ? keywordTrendNews : []}
                                                customPageSize={query.limit}
                                                customPageIndex={query.page - 1}
                                                totalRecords={keywordTrendNews?.pagination?.total}
                                                customPageCount={Math.ceil(
                                                    Number(keywordTrendNews?.pagination?.total) /
                                                    Number(keywordTrendNews?.pagination?.limit)
                                                )}
                                                handleChangePage={handleChangePage}
                                                manualSorting={true}
                                                sorting={{
                                                    sort_by: query.sort_by,
                                                    order_by: query.order_by,
                                                }}
                                                handleChangeSorting={handleChangeSorting}
                                                isLoading={isKeywordTrendNewsLoading}
                                                isShowPagination={false}
                                            />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        )}
                    <ToastContainer closeButton={false} limit={1} />
                </div>
            </div>
        </React.Fragment>
    );
};

export default ModalNewsOfKeywordTrend;
