import { IMedia } from "api/types/_media";
import { IParticipationTemplate } from "api/types/_participation";
import { Option } from "api/types/_public";
import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_NORMAL } from "common/toast";
import CopyWrapper from "components/Common/CopyWrapper";
import DropdownStatus from "components/Common/DropdownStatus";
import LabelWrapper from "components/Common/LabelWrapper";
import ModalConfirm from "components/Common/ModalConfirm";
import TableContainer from "components/Common/TableContainer";
import TooltipCustom from "components/Common/TooltipCustom";
import { useRole } from "components/Hooks/UserHooks";
import { MEMBER_TYPE, STATUS_PARTICIPATION_TEMPLATE_OPTIONS, TYPE_PARTICIPATION_TEMPLATE_OPTIONS } from "helpers/constans";
import { formatNumberWithCommas } from "helpers/format";
import { isHavePermissionRole, ROLES_FOR_APP } from "helpers/role";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CountUp from "react-countup";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Card, CardBody, CardHeader, Col, Container, Input, Modal, ModalBody, ModalHeader, Offcanvas, OffcanvasBody, OffcanvasHeader, Row, } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import { deleteParticipationTemplate, getParticipationTemplates as onGetParticipationTemplates } from "../../../store/thunks";
import FormTemplate from "../FormTemplate";

const SORT_BY_DEFAULT = 'id';
const ORDER_BY_DEFAULT = 'DESC';
const MAX_WIDTH_SCREEN = 1800;
const ParticipationTemplateManagement = () => {
    const { t, i18n } = useTranslation();
    const { userPermissions } = useRole();
    const navigate = useNavigate();

    const STATUS_PARTICIPATION_TEMPLATE_OPTIONS_LANG = STATUS_PARTICIPATION_TEMPLATE_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));
    const TYPE_PARTICIPATION_TEMPLATE_OPTIONS_LANG = TYPE_PARTICIPATION_TEMPLATE_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

    const [query, setQuery]: any = useQueryParams({
        time_request: withDefault(NumberParam, 0),
        page: withDefault(NumberParam, 1),
        limit: withDefault(NumberParam, 50),
        sort_by: withDefault(StringParam, SORT_BY_DEFAULT),
        order_by: withDefault(StringParam, ORDER_BY_DEFAULT),
        name: withDefault(StringParam, ''),
        type: withDefault(StringParam, ''),
    });

    const dispatch: any = useDispatch();

    const selectLayoutState = (state: any) => state.Participation;

    const ParticipationProperties = createSelector(
        selectLayoutState,
        (state) => ({
            participationTemplates: state.participationTemplates,
            isParticipationTemplateSuccess: state.isParticipationTemplateSuccess,
            isParticipationTemplateLoading: state.isParticipationTemplateLoading,
            error: state.error,
        })
    );

    const { participationTemplates, isParticipationTemplateLoading } = useSelector(ParticipationProperties);

    const [nameSearch, setNameSearch] = useState<string>(query?.name || "");

    const [statusSearch, setStatusSearch] = useState<Option | null>(STATUS_PARTICIPATION_TEMPLATE_OPTIONS_LANG?.filter((item) => String(item?.value) === String(query?.is_active))[0]);

    const [typeSearch, setTypeSearch] = useState<Option | null>(TYPE_PARTICIPATION_TEMPLATE_OPTIONS_LANG?.filter((item) => String(item?.value) === String(query?.type))[0]);

    const [templateDetail, setTemplateDetail] = useState<IParticipationTemplate | null>(null);

    const [idDelete, setIdDelete] = useState<number | null>(null);
    const [isConfirmLoading, setIsConfirmLoading] = useState<boolean>(false);

    const [windowSize, setWindowSize] = useState<{ width: number, height: number }>({ width: 0, height: 0 });

    useEffect(() => {
        dispatch(onGetParticipationTemplates(query));
    }, [JSON.stringify(query)]);

    const searchData = () => {
        setQuery({
            ...query,
            page: 1,
            name: nameSearch || '',
            is_active: statusSearch?.value || '',
            type: typeSearch?.value || '',
            member_type: MEMBER_TYPE.REGULAR,
            time_request: + new Date()
        });
    };

    const resetData = () => {
        setQuery({
            name: '',
            is_active: '',
            type: '',
            member_type: MEMBER_TYPE.REGULAR,
            sort_by: SORT_BY_DEFAULT,
            order_by: ORDER_BY_DEFAULT,
            time_request: + new Date(),
            page: 1,
        }, "push");
        setNameSearch("");
        setStatusSearch(null);
        setTypeSearch(null);
    };


    const handleRefresh = (isClose: boolean = true) => {
        if (isClose) { setTemplateDetail((_prev) => null); }
        resetData();
    }

    // Column
    const columns = useMemo(
        () => [
            {
                Header: t('ID'),
                accessor: "id",
                filterable: true,
                sortable: true,
                thWidth: 60,
                Cell: (cell: any) => (
                    <>
                        <div style={{ minWidth: '40px' }}>{cell?.value}</div>
                    </>
                ),
            },
            {
                Header: t('Template Name'),
                accessor: "name",
                filterable: false,
                sortable: false,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="d-flex align-items-center" style={{ minWidth: '130px' }}>
                                <CopyWrapper contentCopy={cell?.value} >
                                    {cell?.value}
                                </CopyWrapper>
                            </div>
                        </>
                    )
                },
            },
            {
                Header: t('Participation Type'),
                accessor: "type",
                filterable: false,
                sortable: false,
                thWidth: 100,
                thClass: 'text-center',
                Cell: (cell: any) => {
                    return (
                        <div className="text-center" style={{ minWidth: '80px' }}>
                            <span className={`rounded-pill badge bg-${['danger', 'primary', 'success'][Number(cell?.value)] || 'secondary'}`}>{TYPE_PARTICIPATION_TEMPLATE_OPTIONS_LANG?.find((item: any) => String(item?.value) === String(cell?.value))?.label}</span>
                        </div>
                    )
                },
            },
            {
                Header: t('Number of Answers'),
                accessor: "count_answers",
                filterable: false,
                sortable: false,
                thClass: 'text-center',
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-center" style={{ minWidth: '100px' }}>
                                {formatNumberWithCommas(cell?.value)}
                            </div>
                        </>
                    )
                },
            },
            {
                Header: t('Duplicate Answer Count'),
                accessor: "submission_limit",
                filterable: false,
                sortable: false,
                thClass: 'text-center',
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-center" style={{ minWidth: '100px' }}>
                                {formatNumberWithCommas(cell?.value)}
                            </div>
                        </>
                    )
                },
            },
            {
                Header: t('Participation Limit (1 Day)'),
                accessor: "daily_limit_used",
                filterable: false,
                sortable: false,
                thClass: 'text-center',
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-center" style={{ minWidth: '100px' }}>
                                {formatNumberWithCommas(cell?.value)}
                            </div>
                        </>
                    )
                },
            },
            // {
            //     Header: t('Status'),
            //     accessor: "status",
            //     filterable: false,
            //     sortable: false,
            //     thWidth: 100,
            //     thClass: 'text-center',
            //     Cell: (cell: any) => {
            //         return (
            //             <div className="text-center" style={{ minWidth: '80px' }}>
            //                 <span className={`rounded-pill badge bg-${['danger', 'success'][cell?.value] || 'secondary'}`}>{STATUS_PARTICIPATION_TEMPLATE_OPTIONS_LANG?.find((item: any) => String(item?.value) === String(cell?.value))?.label}</span>
            //             </div>
            //         )
            //     },
            // },
            {
                Header: t('Created at'),
                accessor: "created_at",
                filterable: true,
                sortable: false,
                thWidth: 150,
                thClass: 'text-end',
                Cell: (cell: any) => {
                    const arrDate = String(cell?.value || '').split(' ');
                    return (
                        <div className="text-end" style={{ minWidth: '120px' }}>
                            <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
                        </div>
                    )
                },
            },

            {
                Header: t('Updated at'),
                accessor: "updated_at",
                filterable: true,
                sortable: false,
                thWidth: 150,
                thClass: 'text-end',
                Cell: (cell: any) => {
                    const arrDate = String(cell?.value || '').split(' ');
                    return (
                        <div className="text-end" style={{ minWidth: '120px' }}>
                            <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
                        </div>
                    )
                },
            },
            {
                Header: t('Action'),
                thClass: 'text-center',
                thWidth: 150,
                Cell: (cell: any) => {
                    const item: any = cell?.row?.original;
                    return (
                        <div className="text-center" style={{ minWidth: '120px' }}>
                            <ul className="list-inline justify-content-center hstack gap-2 mb-0">
                                <TooltipCustom
                                    title={t('Button Detail Participation Template')}
                                    id={`detail-cp-${item?.id}`}
                                >
                                    <li className="list-inline-item edit " title="Edit">
                                        <Link className="btn btn-sm btn-soft-secondary d-inline-block edit-item-btn" to="#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setTemplateDetail((_prev) => ({ ...item, is_preview: true }));
                                            }}>
                                            <i className="ri-eye-fill"></i>
                                        </Link>
                                    </li>
                                </TooltipCustom>
                                {isHavePermissionRole(ROLES_FOR_APP.PARTICIPATION_TEMPLATE_UPDATE, userPermissions) && (<TooltipCustom
                                    title={t('Button Update Participation Template')}
                                    id={`update-cp-${item?.id}`}
                                >
                                    <li className="list-inline-item edit " title="Edit">
                                        <Link className="btn btn-sm btn-soft-primary d-inline-block edit-item-btn" to="#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setTemplateDetail((_prev) => item);
                                            }}>
                                            <i className="ri-pencil-fill"></i>
                                        </Link>
                                    </li>
                                </TooltipCustom>)}
                                {isHavePermissionRole(ROLES_FOR_APP.PARTICIPATION_TEMPLATE_DELETE, userPermissions) && (<TooltipCustom
                                    title={t('Button Delete Participation Template')}
                                    id={`detail-cp-${item?.id}`}
                                >
                                    <li className="list-inline-item" title="Remove">
                                        <Link className="btn btn-sm btn-soft-danger d-inline-block edit-item-btn" to="#"
                                            onClick={(e) => { e.preventDefault(); handleConfirmDelete(item) }}
                                        >
                                            <i className="ri-delete-bin-5-fill"></i>
                                        </Link>
                                    </li>
                                </TooltipCustom>)}
                            </ul>
                        </div>
                    );
                },
            },
        ],
        [i18n?.language, userPermissions, JSON.stringify(query), STATUS_PARTICIPATION_TEMPLATE_OPTIONS_LANG]
    );

    const handleChangePage = useCallback((page: any) => {
        setQuery({ page: page + 1 })
    }, []);

    const handleChangeSorting = useCallback((sortBy: any) => {
        setQuery((_prev: any) => {
            return ({ ..._prev, ...sortBy });
        });
    }, []);

    // Begin::Delete
    const handleConfirmDelete = (item: IMedia) => {
        setIdDelete((_prev) => item?.id);
    };

    const handleActionDelete = async () => {
        if (!idDelete) { return; };
        try {
            setIsConfirmLoading((_prev) => true);
            const response: any = await deleteParticipationTemplate(idDelete);
            if (response?.code === 200) {
                dispatch(onGetParticipationTemplates(query));
                setIsConfirmLoading((_prev) => false);
                setIdDelete((_prev) => null);
                toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
            } else {
                setIsConfirmLoading((_prev) => false);
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsConfirmLoading((_prev) => false);
            toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };

    const onCloseConfirmClick = () => {
        setIdDelete((_prev) => null);
    }
    // End::Delete

    useEffect(() => {
        document.title = `${t("Management Participation Templates")} - ${t("Participation Management")} | Rankify`;
        document.body.classList.remove("vertical-sidebar-enable");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n?.language]);

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={t('Management Participation Templates')} pageTitle={t('Participation Management')} />
                    {isHavePermissionRole(ROLES_FOR_APP.PARTICIPATION_TEMPLATE_MANAGEMENT, userPermissions) && (
                        <Row>
                            <Col sm={12} md={12}>
                                <Card id="customerList" style={{ boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)' }}>
                                    <CardHeader className="border-0">
                                        <Row className="g-4 align-items-center">
                                            <div className="col-6 col-sm">
                                                <div>
                                                    <h5 className="card-title mb-0">
                                                        <span className="me-2">{t('Total')}:</span>
                                                        <CountUp
                                                            start={0}
                                                            end={participationTemplates?.pagination?.total || 0}
                                                            duration={1}
                                                            className="text-primary"
                                                        />
                                                    </h5>
                                                </div>
                                            </div>
                                            {isHavePermissionRole(ROLES_FOR_APP.PARTICIPATION_TEMPLATE_CREATE, userPermissions) && (<div className="col-6 text-end col-sm-auto">
                                                <div>
                                                    <button
                                                        type="button"
                                                        className="btn btn-success add-btn rounded-pill"
                                                        id="create-btn"
                                                        onClick={() => setTemplateDetail((_prev) => ({} as IParticipationTemplate))}
                                                    >
                                                        <i className="ri-add-line align-bottom me-1"></i>
                                                        {t('Button Create Participation Template')}
                                                    </button>
                                                </div>
                                            </div>)}
                                        </Row>
                                    </CardHeader>
                                    <div className=" border border-dashed border-end-0 border-start-0 card-body mt-0 pb-4">
                                        <Row className="g-4 align-items-center mt-0">
                                            <Col sm={6} md={4} xl={3} xxl={3} className='mt-3 mt-md-2'>
                                                <LabelWrapper label={t('Template Name')} isShow={!!nameSearch}>
                                                    <Input
                                                        type="text"
                                                        className="form-control search"
                                                        placeholder={`${t('Template Name')}...`}
                                                        value={nameSearch}
                                                        onChange={(e) => setNameSearch(e.target.value)}
                                                        onKeyDown={(e) => {
                                                            if (e.key === "Enter") {
                                                                searchData();
                                                            }
                                                        }}
                                                    />
                                                </LabelWrapper>
                                            </Col>
                                            <Col sm={12} md={4} xl={2} xxl={2} className='mt-3 mt-md-2'>
                                                <LabelWrapper label={t('Participation Type')} isShow={!!typeSearch?.value}>
                                                    <DropdownStatus
                                                        name="type"
                                                        dataList={TYPE_PARTICIPATION_TEMPLATE_OPTIONS_LANG || []}
                                                        placeholder={`${t("Participation Type")}...`}
                                                        className="dropdown-status-rounded"
                                                        classNamePrefix="name-prefix"
                                                        initialValue={typeSearch || null}
                                                        onChangeSelect={(e: any) => setTypeSearch(e)}
                                                        isHasOptionAll={true}
                                                        optionAll={{ label: t('All Type Template'), value: '' }}
                                                        colors={['danger', 'primary', 'success']}
                                                    />
                                                </LabelWrapper>
                                            </Col>

                                            {/* <Col sm={12} md={4} xl={2} xxl={2} className='mt-3 mt-md-2'>
                                                <LabelWrapper label={t('Status')} isShow={!!statusSearch?.value}>
                                                    <DropdownStatus
                                                        name="status"
                                                        dataList={STATUS_PARTICIPATION_TEMPLATE_OPTIONS_LANG || []}
                                                        placeholder={`${t("Status")}...`}
                                                        className="dropdown-status-rounded"
                                                        classNamePrefix="name-prefix"
                                                        initialValue={statusSearch || null}
                                                        onChangeSelect={(e: any) => setStatusSearch(e)}
                                                        isHasOptionAll={true}
                                                        optionAll={{ label: t('All Status'), value: '' }}
                                                        colors={['danger', 'success']}
                                                    />
                                                </LabelWrapper>
                                            </Col> */}

                                            <Col sm={12} md={4} xl={7} xxl={7} className="hstack gap-1 justify-content-center justify-content-sm-between justify-content-md-between mt-3 mt-md-2">
                                                <div>
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary me-2 "
                                                        onClick={searchData}
                                                        disabled={isParticipationTemplateLoading}
                                                    >
                                                        <i className="ri-search-line align-bottom me-1"></i>{" "}
                                                        {t('Button Search')}
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-secondary fs-14"
                                                        onClick={resetData}
                                                    >
                                                        <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                                                        {t('Button Reset')}
                                                    </button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <CardBody className="pt-3">
                                        <TableContainer
                                            className="custom-header-css"
                                            divClass="table-responsive table-card"
                                            tableClass="align-middle table-bordered-dashed"
                                            theadClass="table-light text-muted"
                                            columns={columns}
                                            data={participationTemplates?.list?.length ? participationTemplates?.list : []}
                                            customPageSize={query.limit}
                                            customPageIndex={query.page - 1}
                                            totalRecords={participationTemplates?.pagination?.total}
                                            customPageCount={Math.ceil(Number(participationTemplates?.pagination?.total) / Number(participationTemplates?.pagination?.limit))}
                                            handleChangePage={handleChangePage}
                                            manualSorting={true}
                                            sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                                            handleChangeSorting={handleChangeSorting}
                                            isLoading={isParticipationTemplateLoading}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                            {((windowSize?.width >= MAX_WIDTH_SCREEN)) && (
                                <Offcanvas
                                    fade
                                    scrollable={false}
                                    direction="end"
                                    isOpen={(templateDetail !== null)}
                                    id="offcanvasExample"
                                    className='off-canvas-lg off-canvas-lg-700'
                                >
                                    <OffcanvasHeader className="bg-light" toggle={() => setTemplateDetail((_prev) => null)}>
                                        <h5 className="fs-16 mb-1">{!!templateDetail?.id ? (!!templateDetail?.is_preview ? t('Button Detail Participation Template') : t('Button Update Participation Template')) : t('Button Create Participation Template')}</h5>
                                    </OffcanvasHeader>
                                    <OffcanvasBody className="color-scrollbar-os">
                                        <FormTemplate detail={templateDetail} triggerRefresh={handleRefresh} handleClose={() => setTemplateDetail((_prev) => null)} />
                                    </OffcanvasBody>
                                </Offcanvas>
                            )}
                            {(windowSize?.width < MAX_WIDTH_SCREEN) && (<Modal
                                isOpen={templateDetail !== null}
                                centered={true}
                                size="md"
                                toggle={() => setTemplateDetail((_prev) => null)}
                                keyboard={true}
                            >
                                <ModalHeader toggle={() => setTemplateDetail((_prev) => null)}>
                                    {!!templateDetail?.id ? (!!templateDetail?.is_preview ? t('Button Detail Participation Template') : t('Button Update Participation Template')) : t('Button Create Participation Template')}
                                </ModalHeader>
                                <ModalBody className="">
                                    <FormTemplate detail={templateDetail} triggerRefresh={handleRefresh} handleClose={() => setTemplateDetail((_prev) => null)} />
                                </ModalBody>
                            </Modal>)}
                        </Row>
                    )}
                    <ToastContainer closeButton={false} limit={1} />
                </Container>
            </div>
            <ModalConfirm
                header={t('Button Delete Participation Template')}
                isOpen={idDelete !== null}
                isLoading={isConfirmLoading}
                onClose={onCloseConfirmClick}
                onConfirm={handleActionDelete}
            />
        </React.Fragment>
    );
};

export default ParticipationTemplateManagement;