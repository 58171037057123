import { createAsyncThunk } from "@reduxjs/toolkit";
import keywordApi from 'api/keywordApi';
import { ENUM_STATUS_KEYWORD_RANKING } from "helpers/constans";
import { formatQueryParams } from "helpers/format";

export const getKeywords = createAsyncThunk("Keywords", async (params: any = {}) => {
  try {
    const response = await keywordApi.keywords(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});

export const getKeywordsMonitor = createAsyncThunk("Keywords Monitor",
  async (params: any = {}) => {
    try {
      const response = await keywordApi.keywordsMonitor(formatQueryParams(params)); // { signal } as any
      return response;
    } catch (error) {
      return error;
    }
  });


export const getRankKeywordSinger = createAsyncThunk("Rank Keywords Singer",
  async (params: any = {}) => {
    try {
      const response = await keywordApi.keywordsSearchNews(formatQueryParams(params)); // { signal } as any
      return response;
    } catch (error) {
      return error;
    }
  });

export const getRankKeywordSport = createAsyncThunk("Rank Keywords Sport",
  async (params: any = {}) => {
    try {
      if (!!params?.golf_league_code) {
        const response = await keywordApi.rankKeywordGolfs(formatQueryParams(params));
        return response;
      }
      if (!!params?.is_soccer) {
        const clone = structuredClone(params);
        delete clone.is_soccer;
        const response = await keywordApi.rankKeywordSoccers(formatQueryParams(clone));
        return response;
      }

      if (!!params?.baseball_player_type) {
        const response = await keywordApi.rankKeywordBaseballs(formatQueryParams(params));
        return response;
      }

      if (!!params?.basketball_league_code) {
        const response = await keywordApi.rankKeywordBasketballs(formatQueryParams(params));
        return response;
      }

      if (!!params?.volleyball_league_code) {
        const response = await keywordApi.rankKeywordVolleyballs(formatQueryParams(params));
        return response;
      }

      const response = await keywordApi.rankKeywordSports(formatQueryParams(params));
      return response;
    } catch (error) {
      return error;
    }
  });


export const getRankKeywordCar = createAsyncThunk("Rank Keywords Car",
  async (params: any = {}) => {
    try {
      const response = await keywordApi.rankKeywordCars(formatQueryParams(params)); // { signal } as any
      return response;
    } catch (error) {
      return error;
    }
  });


export const getRankKeywordGame = createAsyncThunk("Rank Keywords Game",
  async (params: any = {}) => {
    try {
      const response = await keywordApi.rankKeywordGames(formatQueryParams(params)); // { signal } as any
      return response;
    } catch (error) {
      return error;
    }
  });

export const getStockKeywords = createAsyncThunk("Stock Keywords", async (params: any = {}) => {
  try {
    const response = await keywordApi.stockKeywords(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});


export const getKeywordsByCategory = createAsyncThunk("Keywords By Category", async (params: any = {}) => {
  try {
    const categoryId = params?.category_id;
    const paramsClone = structuredClone(params);
    delete paramsClone.category_id;
    const response = await keywordApi.keywordsByCategory(categoryId, formatQueryParams(paramsClone))
    return response;
  } catch (error) {
    return error;
  }
});

export const getCollecting = createAsyncThunk("Keywords Collecting", async (params: any = {}) => {
  try {
    const response = await keywordApi.collecting(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});

export const getNewsSearchingKeyword = createAsyncThunk("News Searching Keyword", async (params: any = {}) => {
  try {
    const response = await keywordApi.newsSearchingKeyword(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});

export const getKeywordTrends = createAsyncThunk("Keywords Trend",
  async (params: any = {}) => {
    try {
      const response = await keywordApi.keywordTrends(formatQueryParams(params));
      return response;
    } catch (error) {
      return error;
    }
  });


export const getKeywordTrendNews = createAsyncThunk("Keywords Trend News",
  async (params: any = {}) => {
    try {
      const response = await keywordApi.keywordTrendNews(formatQueryParams(params));
      return response;
    } catch (error) {
      return error;
    }
  });

export const getKeywordSummariesStatus = async (params: any = {}) => {
  try {
    const response = await keywordApi.getKeywordSummariesStatus(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
};

export const getRankingMonitorSummaries = async (params: any = {}) => {
  try {
    const response = await keywordApi.getRankingMonitorSummaries(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
};

export const getQueryInfoApprove = async (params: any = {}) => {
  try {
    const response = await keywordApi.getQueryInfoApprove(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
};


export const getKeyword = async (id: number) => {
  try {
    const response = await keywordApi.getKeyword(id)
    return response;
  } catch (error) {
    return error;
  }
};

export const postKeyword = async (data: any = {}) => {
  try {
    const response = await keywordApi.postKeyword(data)
    return response;
  } catch (error) {
    return error;
  }
};

export const putKeyword = async (id: number, data: any = {}) => {
  try {
    const response = await keywordApi.putKeyword(id, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteKeyword = async (id: number, data: any = {}) => {
  try {
    const response = await keywordApi.deleteKeyword(id, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteKeywordOfCategories = async (id: number, data: any = {}) => {
  try {
    const response = await keywordApi.deleteKeywordOfCategories(id, data);
    return response;
  } catch (error) {
    return error;
  }
};


export const downloadExcelKeywords = async (params: any = {}) => {
  try {
    const response = await keywordApi.downloadExcelKeywords(formatQueryParams(params));
    return response;
  } catch (error) {
    return error;
  }
};

export const changeStatusKeyword = async (data: any = {}) => {
  try {
    // add keyword to activate
    if (String(data?.status) === String(ENUM_STATUS_KEYWORD_RANKING.ACTIVATED)) {
      const response = await keywordApi.postKeywordActive({ keyword_ids: data?.keyword_ids, frequency_type: data?.frequency_type, date: data?.date });
      return response;
    }

    // add keyword to exclude
    if (String(data?.status) === String(ENUM_STATUS_KEYWORD_RANKING.EXCLUDED)) {
      const response = await keywordApi.postKeywordExclude({ keyword_ids: data?.keyword_ids });
      return response;
    }
    // cancel exclude
    if (String(data?.status) === String(ENUM_STATUS_KEYWORD_RANKING.CANCEL_EXCLUDED)) {
      const response = await keywordApi.deleteKeywordExclude({ keyword_ids: data?.keyword_ids });
      return response;
    }

    // add keyword to hidden
    if (String(data?.status) === String(ENUM_STATUS_KEYWORD_RANKING.HIDDEN)) {
      const response = await keywordApi.postKeywordHide({ keyword_ids: data?.keyword_ids, frequency_type: data?.frequency_type, date: data?.date });
      return response;
    }
    // cancel hidden
    if (String(data?.status) === String(ENUM_STATUS_KEYWORD_RANKING.CANCEL_HIDDEN)) {
      const response = await keywordApi.deleteKeywordHide({ keyword_ids: data?.keyword_ids, frequency_type: data?.frequency_type, date: data?.date });
      return response;
    }

    // // confirm exclude
    // const response = await keywordApi.putKeywordExclude({ keyword_id: data?.id, category_id: data?.category_id });
    // return response;

  } catch (error) {
    return error;
  }

};

export const changeStatusKeywordRank = async (data: any = {}) => {
  try {

    // add keyword to exclude
    if (String(data?.status) === String(ENUM_STATUS_KEYWORD_RANKING.EXCLUDED)) {
      const response = await keywordApi.postKeywordRankExclude({ keyword_ids: data?.keyword_ids });
      return response;
    }
    // add keyword to  activate 
    if (String(data?.status) === String(ENUM_STATUS_KEYWORD_RANKING.ACTIVATED)) {
      const response = await keywordApi.postKeywordRankActivate({ keyword_ids: data?.keyword_ids, category_id: Number(data?.category_id) });
      return response;
    }

    // add keyword to  hide
    if (String(data?.status) === String(ENUM_STATUS_KEYWORD_RANKING.HIDDEN)) {
      const response = await keywordApi.postKeywordRankHide({ keyword_ids: data?.keyword_ids, category_id: Number(data?.category_id) });
      return response;
    }

  } catch (error) {
    return error;
  }

};


export const addConfirmKeyword = async (data: any = {}) => {
  try {
    const response = await keywordApi.addConfirmKeyword(data)
    return response;
  } catch (error) {
    return error;
  }
};

export const cancelConfirmKeyword = async (data: any = {}) => {
  try {
    const response = await keywordApi.cancelConfirmKeyword(data);
    return response;
  } catch (error) {
    return error;
  }
};

export const putInfoSearchKeyword = async (id: number, data: any = {}) => {
  try {
    const response = await keywordApi.putInfoSearchKeyword(id, data);
    return response;
  } catch (error) {
    return error;
  }
};


export const updateKeywordRaw = async (data: any = {}) => {
  try {
    const response = await keywordApi.postKeywordRaw(data)
    return response;
  } catch (error) {
    return error;
  }
};

export const getRankKeyword = async (id: number) => {
  try {
    const response = await keywordApi.getRankKeyword(id)
    return response;
  } catch (error) {
    return error;
  }
};

export const updateRankKeywordRaw = async (id: number | string, data: any = {}) => {
  try {
    const response = await keywordApi.putRankKeywordRaw(id, data)
    return response;
  } catch (error) {
    return error;
  }
};

export const updateNationalityRankKeywordRaw = async (id: number | string, data: any = {}) => {
  try {
    const response = await keywordApi.putNationalityRankKeywordRaw(id, data)
    return response;
  } catch (error) {
    return error;
  }
};


export const getQueryInfoApproveRankKeyword = async (params: any = {}) => {
  try {
    const response = await keywordApi.getQueryInfoApproveRankKeyword(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
};

export const addConfirmRankKeyword = async (data: any = {}) => {
  try {
    const response = await keywordApi.addConfirmRankKeyword(data)
    return response;
  } catch (error) {
    return error;
  }
};

export const putInfoExtractKeyword = async (id: number, data: any = {}) => {
  try {
    const response = await keywordApi.putInfoExtractKeyword(id, data);
    return response;
  } catch (error) {
    return error;
  }
};


export const postGemma = async (data: any = {}) => {
  try {
    const response = await keywordApi.postGemma(data)
    return response;
  } catch (error) {
    return error;
  }
};


export const putInfoGroupName = async (code: number, data: any = {}) => {
  try {
    const response = await keywordApi.putInfoGroupName(code, data);
    return response;
  } catch (error) {
    return error;
  }
};