import React from "react";
import { useTranslation } from "react-i18next";
import NoImage from '../../../../assets/images/no-photo-available.png';
import InfoExtract from "./InfoExtract";
import LoadingSkeleton from "./LoadingSkeleton";
import InfoSearchKeyword from "./InfoSearchKeyword";

const ModelCar = ({ keyword_info = {}, triggerRefresh }: any) => {
    const { t, i18n } = useTranslation();
    return (
        <>
            <div className="d-flex flex-w flex-wrap mb-3">
                <div className="d-flex flex-column gap-3 w-100">
                    <>
                        <div className="text-center" style={{ width: '100%', height: 'auto', maxHeight: '300px' }}>
                            <img alt={keyword_info?.name || ''} loading="lazy" src={keyword_info?.image_url || keyword_info?.image || NoImage}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'contain',
                                    objectPosition: 'center',
                                    maxWidth: '350px'
                                    // backgroundColor: '#eaeaea'
                                }}
                            />
                        </div>
                        <div className="w-100">
                            <div>
                                <h3 className="mb-1">
                                    {!keyword_info?.name ? <LoadingSkeleton /> : keyword_info?.name || ''}
                                </h3>
                            </div>
                            <table className="table table table-bordered mb-0">
                                <tbody>
                                    <tr>
                                        <td className="text-muted p-2 bg-secondary-100" width="130px">{t('Brand Name')}</td>
                                        <td className="p-2 text-start">{!keyword_info?.name ? <LoadingSkeleton /> : String(keyword_info?.brand_name || '-')}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-muted p-2 bg-secondary-100" width="130px">{t('Vehicle Type')}</td>
                                        <td className="p-2 text-start">{!keyword_info?.name ? <LoadingSkeleton /> : String(keyword_info?.car_type || '-')}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-muted p-2 bg-secondary-100" width="130px">{t('Fuel Type')}</td>
                                        <td className="p-2 text-start">{!keyword_info?.name ? <LoadingSkeleton /> : keyword_info?.fuel_type || '-'}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-muted p-2 bg-secondary-100" width="130px">{t('Engine Displacement')}</td>
                                        <td className="p-2 text-start">{!keyword_info?.name ? <LoadingSkeleton /> : keyword_info?.engine_displacement || '-'}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-muted p-2 bg-secondary-100" width="130px">{t('Fuel Efficiency')}</td>
                                        <td className="p-2 text-start">{!keyword_info?.name ? <LoadingSkeleton /> : keyword_info?.fuel_efficiency || '-'}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-muted p-2 bg-secondary-100" width="130px">{t('Total Driving Range')}</td>
                                        <td className="p-2 text-start">{!keyword_info?.name ? <LoadingSkeleton /> : keyword_info?.total_driving_range || '-'}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-muted p-2 bg-secondary-100" width="130px">{t('Composite Fuel Efficiency')}</td>
                                        <td className="p-2 text-start">{!keyword_info?.name ? <LoadingSkeleton /> : keyword_info?.composite_fuel_efficiency || '-'}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-muted p-2 bg-secondary-100" width="130px">{t('Battery Capacity')}</td>
                                        <td className="p-2 text-start">{!keyword_info?.name ? <LoadingSkeleton /> : keyword_info?.battery_capacity || '-'}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-muted p-2 bg-secondary-100" width="130px">{t('Battery Manufacturer')}</td>
                                        <td className="p-2 text-start">{!keyword_info?.name ? <LoadingSkeleton /> : keyword_info?.battery_manufacturer || '-'}</td>
                                    </tr>
                                    <InfoSearchKeyword
                                        triggerRefresh={triggerRefresh}
                                        keyword_id={keyword_info?.id}
                                        search_keyword={keyword_info?.search_keyword || ''}
                                        classTd1="text-muted p-2 bg-secondary-100 align-middle"
                                        classTd2="p-2 text-start"
                                    />
                                </tbody>
                            </table>
                        </div>
                    </>
                </div>
            </div>
        </>
    )
}

export default React.memo(ModelCar);